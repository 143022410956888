import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";
import { message } from "antd";

// Action to Get Otp::
export const getOtpRequest = createAsyncThunk(
  "getOtpRequest",
  async (userInputData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiBaseUrl}/otp-send-singup`,
        userInputData
      );

      if (response.data.status === "ok") {
        // Display success message
        message.success(response.data.message);
        return response.data;
      } else {
        // If there's some error in response but no error code
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      // Handle network or unexpected errors
      const errorMessage =
        error.response?.data?.message ||
        "Failed to send OTP. Please try again.";
      message.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// Action to verify Otp::
export const verifyOtpRequestSignup = createAsyncThunk(
  "verifyOtpRequestSignup",
  async (userOtp, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${ApiBaseUrl}/otp-verify-singup`, userOtp);
      if (response.data.error) {
        // Display error message using Ant Design's message component
        message.error(response.data.error.message);
        return rejectWithValue(response.data.error.message);
      }
      if (response.data?.mobile) {
        localStorage.setItem('user_mobile', response.data.mobile);
      }
      return response.data;
    } catch (error) {
      // Handle any other errors (like network issues, etc.)
      const errorMessage =
        error.response?.data?.error?.message ||
        "Something went wrong. Please try again.";
      message.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// Action to submit user details::
export const submitUserDetails = createAsyncThunk(
  "submitUserDetails",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${ApiBaseUrl}/otp-verify-singup`, userData);
      if (response.data.error) {
        // Display error message using Ant Design's message component
        message.error(response.data.error.message);
        return rejectWithValue(response.data.error.message);
      }
      return response.data;
    } catch (error) {
      // Handle any other errors (like network issues, etc.)
      const errorMessage =
        error.response?.data?.error?.message ||
        "Something went wrong. Please try again.";
      message.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// Action to logOut user::
export const logOut = createAsyncThunk("logOut", () => {
  localStorage.removeItem("isUserLoggedIn");
});

const registerSlice = createSlice({
  name: "register",
  initialState: {
    otpSending: false,
    otpInputDisabled: true,
    otpSent: false,
    secondStep: false,
    otpVerifying: false,
    otpVerified: false,
    isAuthenticated: false,
    userData: null,
    otpError: null,
    verificationError: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getOtpRequest.pending, (state) => {
      state.otpSending = true;
    });
    builder.addCase(getOtpRequest.fulfilled, (state, action) => {
      state.otpSent = true;
      state.otpInputDisabled = false;
    });
    builder.addCase(getOtpRequest.rejected, (state, action) => {
      state.otpSending = false;
      state.error = action.payload;
    });
    builder.addCase(verifyOtpRequestSignup.pending, (state) => {
      state.otpVerifying = true;
    });
    builder.addCase(verifyOtpRequestSignup.fulfilled, (state, action) => {
      state.otpVerified = true;
      state.isAuthenticated = true;
      state.secondStep = true;
      state.userData = action.payload;
      
    });
    builder.addCase(verifyOtpRequestSignup.rejected, (state, action) => {
      state.otpVerifying = false;
      state.error = action.payload;
    });
  },
});

export default registerSlice.reducer;