import React, { useEffect, useState } from "react";
import "./cancelledBooking.scss";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
const CancelledBooking = () => {
  const [myBooking, setMybooking] = useState([]);
  const PARENT_ID = localStorage.getItem("PARENT_ID");
  useEffect(() => {
    mybookingapi();
  }, []);

  const mybookingapi = () => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios
      .get(`${ApiBaseUrl}/host/${PARENT_ID}/CanceledOrders`, config)
      .then((response) => {
        setMybooking(response.data.orders);
      })
      .catch((error) => {
       
        if (error.response && error.response.data && error.response.data.error) {
   
          alert(error.response.data.error);
        } else {
      
          alert("An error occurred while fetching your bookings. Please try again.");
        }
      });
  };
  
  return (
    <>
      <section>
        <div className="mybooking_host">
          <div className="mybooking-heading">
            <h4>Cancelled Bookings</h4>
          </div>
        </div>
        <div className="checkout_flex2_host">
          <div className="best-host-data">
            {myBooking.length === 0 ? (
              <p>No Cancelled Booking Found</p>
            ) : myBooking && Array.isArray(myBooking) ? (
              myBooking.map((item) => {
                return (
                  <div className="best-host-inner" key={item.id}>
                    <div className="best_host_flex1">
                      <img
                        src={`${ApiBaseUrl}${item.parent.profile_pic}`}
                        alt=""
                      />
                    </div>
                    <div className="best_host_flex2">
                      <h6>{item?.parent?.name}</h6>
                      <p>{item?.parent?.mobile}</p>
                      <p>
                        {item?.parent?.address1}
                        {item?.parent?.city}
                      </p>
                      <div className="timings">
                        <div className="start">
                          <p>
                            Pickup Date: <span>{item.start_date}</span>
                          </p>
                          <p>
                            Pickup Time: <span>{item.check_in_time}</span>
                          </p>
                        </div>
                        <div className="end">
                          <p>
                            Drop-off Date: <span>{item.end_date}</span>
                          </p>
                          <p>
                            Drop-off Time: <span>{item.check_out_time}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CancelledBooking;
