import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function PetHostingGuidelines() {
  return (
    <>
      <Header/>
      <div style={{padding: "140px 110px"}}>
    <h2>Woofily Pet Hosting Guidelines</h2>
    <p>Your go-to guide for providing a safe, fun, and welcoming experience for every pet! Follow these hosting tips to ensure every pet in your care feels right at home, and pet parents feel confident choosing you as their trusted host.</p>

    <h3>Why Pet Hosting Guidelines Matter</h3>
    <p>At Woofily, we believe in creating safe and positive experiences for both pets and pet parents. As a pet host, you play a key role in ensuring every pet feels comfortable, cared for, and loved. Following our guidelines will help you maintain high standards of care and create an environment where pets can thrive during their stay.</p>

    <h3>General Hosting Guidelines</h3>

    <h4>1. Provide a Safe and Clean Environment</h4>
    <p>● Ensure your home is pet-friendly and free from hazards like exposed wires, harmful plants, or small objects that pets could swallow.</p>
    <p>● Make sure there’s enough space for pets to move around comfortably, play, and rest.</p>
    <p>● Clean up before and after each pet’s stay to keep a hygienic environment.</p>
    <p>● Provide fresh water at all times and ensure food bowls are cleaned regularly.</p>

    <h4>2. Communication is Key</h4>
    <p>● Stay in touch with pet parents before, during, and after the booking. Ask questions about the pet’s behavior, likes/dislikes, routines, and any special needs.</p>
    <p>● Send daily updates, photos, and videos to reassure pet parents that their furry friend is in good hands.</p>
    <p>● Be upfront and honest about your availability, hosting conditions, and any limitations you may have.</p>

    <h4>3. Respect the Pet’s Routine</h4>
    <p>● Pets thrive on routine, so try to stick to their usual schedule as much as possible. This includes feeding times, walks, playtime, and any other daily activities they are used to.</p>
    <p>● Ask the pet parent for a detailed care routine, including any specific instructions regarding food, medication, or special requirements.</p>

    <h4>4. Offer a Cozy and Comfortable Space</h4>
    <p>● Provide a designated sleeping area that’s cozy and comfortable, with bedding or a soft surface that pets can relax on.</p>
    <p>● If possible, create a calm, quiet space where pets can retreat if they need a break from activity or noise.</p>

    <h3>Care and Safety Guidelines</h3>

    <h4>1. Monitor Pets Closely</h4>
    <p>● Keep a close eye on pets, especially during the first few hours of their stay, as they get used to their new environment.</p>
    <p>● Make sure doors, gates, and windows are secure to prevent any pet from escaping.</p>
    <p>● Never leave pets unattended for long periods, and always be aware of their needs.</p>

    <h4>2. Understand and Manage Pet Behavior</h4>
    <p>● Every pet is different, and some may be shy, anxious, or energetic. Take time to understand the pet’s personality and adjust your approach accordingly.</p>
    <p>● If you’re hosting multiple pets, ensure they get along and manage introductions carefully.</p>
    <p>● Avoid putting pets in situations where they may feel threatened or overwhelmed, and be prepared to provide comfort if needed.</p>

    <h4>3. Handle Emergency Situations</h4>
    <p>● Always have a plan in case of emergencies. Know the location of the nearest vet, and keep emergency contact numbers for the pet parent handy.</p>
    <p>● If a pet shows signs of illness or injury, contact the pet parent immediately and seek veterinary care if needed.</p>
    <p>● Maintain a basic first-aid kit for pets in your home with items like bandages, antiseptic, and emergency numbers.</p>

    <h4>4. Follow Special Care Instructions</h4>
    <p>● For pets with special needs, such as medication or dietary restrictions, follow all instructions provided by the pet parent closely.</p>
    <p>● If the pet requires medication, ask the pet parent to show you how to administer it properly and confirm dosage and timing.</p>

    <h3>Hosting Best Practices</h3>

    <h4>1. Positive Pet Interactions</h4>
    <p>● Use positive reinforcement techniques like treats and praise to encourage good behavior in pets.</p>
    <p>● Avoid harsh discipline or punishment. Instead, redirect pets’ attention if they’re engaging in undesirable behavior.</p>
    <p>● Take time to bond with each pet, whether through play, walks, or simple affection.</p>

    <h4>2. Respect Pet Parents' Preferences</h4>
    <p>● Each pet parent has different preferences and expectations for their pet’s care. Be respectful of their instructions and keep them updated throughout the pet’s stay.</p>
    <p>● If a pet parent has a preferred method for feeding, walking, or engaging with their pet, do your best to accommodate their requests.</p>

    <h4>3. Managing Multiple Pets</h4>
    <p>● If hosting more than one pet, ensure each animal has enough space to feel comfortable and avoid territorial behavior.</p>
    <p>● Supervise all interactions between pets to ensure they get along and prevent any conflicts.</p>
    <p>● Consider offering separate feeding areas to avoid any food-related issues between pets.</p>

    <h3>Your Responsibilities as a Woofily Host</h3>

    <h4>1. Be Professional and Dependable</h4>
    <p>● Woofily hosts are trusted to provide a high level of care, so always be reliable and responsive.</p>
    <p>● Make sure you are available and present when you’ve accepted a booking, and be clear with pet parents about your availability.</p>
    <p>● Stick to your commitments, and if any unforeseen issues arise, communicate with the pet parent as soon as possible.</p>

    <h4>2. Respect Woofily’s Terms and Conditions</h4>
    <p>● Follow Woofily’s policies for booking, cancellations, and safety to maintain the integrity of our community.</p>
    <p>● Respect the privacy and preferences of pet parents and always act in accordance with Woofily’s guidelines for professionalism and care.</p>

    <h4>3. Keep Learning and Growing</h4>
    <p>● Hosting pets is an ongoing learning experience. Take the time to learn about different breeds, behaviors, and care techniques to continuously improve your hosting skills.</p>
    <p>● Woofily offers resources, training, and support to help you become the best pet host possible. Take advantage of these tools to grow and thrive as a host.</p>

    <h3>Need Help? We’re Here for You!</h3>
    <p>Have questions or need assistance? Woofily’s support team is available 24/7 to help you with any hosting-related issues. Whether you’re dealing with a tricky situation or just need some advice, we’re here to guide you every step of the way.</p>

    <h3>Join Woofily Today!</h3>
    <p>Ready to become a trusted host and start welcoming pets into your home? Sign up today and let Woofily help you provide the best care for every pet you host!</p>
</div>

      <Footer/>
    </>
  )
}

export default PetHostingGuidelines
