import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function AboutWoofily() {
  return (
    <>
      <Header/>
      <div style={{padding: "140px 110px"}}>
    <h1>Woofily: A Home Away from Home for Every Pet</h1><br/>
    <p>It all started with a simple idea—what if pet parents could find loving, trusted homes for their furry family members whenever they needed? Whether it was for a weekend getaway, an unexpected business trip, or just a day of pampering for their pet, the dream was to create a space where pets could feel safe, loved, and cared for in a home setting.</p>
    <p>Woofily was born from that dream—a platform where pets aren’t just boarded; they’re welcomed into homes filled with love and joy. It started when a group of passionate pet lovers realized that while kennels and other pet care options provide valuable services, some pet parents wanted an alternative that felt more like a home-away-from-home.</p>
    <p>That’s when the team created Woofily: a community of pet parents and pet hosts who treat each other’s pets like their own.</p>

    <h3>Building a Community of Trust and Love 🐾</h3>
    <p>From day one, Woofily has been built on trust. Every pet host is carefully verified, every pet parent is welcomed with open arms, and every pet is treated like family. Woofily isn’t just a marketplace—it’s a community where pets’ well-being comes first.</p>
    <p>The platform makes it easy for pet parents to find the perfect match for their pets. Whether you’re looking for someone to care for your dog for a few hours or a home where your cat can stay while you’re on vacation, Woofily’s verified hosts have you covered. Every host goes through a detailed vetting process to ensure they meet Woofily’s high standards of care and love.</p>

    <h3>Always Innovating for Your Furry Family 🐶🐱</h3>
    <p>Woofily is always evolving, introducing new features to help both pet parents and hosts. Recently, Woofily introduced a vaccination tracking feature that allows pet parents to add vaccination certificates, dates, and dosages directly to their pet’s profile. Woofily sends out reminders, ensuring that pet parents stay on top of their pet’s health, without the worry of missing important dates.</p>
    <p>And with quicker login times, Woofily has made accessing the platform even faster and more convenient, so pet parents can spend more time with their fur babies and less time on the app.</p>

    <h3>A Future Full of Woofily Adventures</h3>
    <p>Today, Woofily is a vibrant community of pet lovers, but this is only the beginning. The team behind Woofily is constantly working on new ways to improve the platform and make it even more engaging and useful. From potential partnerships with vets and groomers to new features that help pet parents manage their pet’s lives more efficiently, Woofily continues to grow every day.</p>
    <p>At its core, Woofily is about providing peace of mind to pet parents by offering trusted, loving homes when they can’t be there. It’s about community, trust, and love—values that guide every decision.</p>
    <p>So, the next time you’re planning a trip or need a helping hand with your furry friend, think of Woofily. Because here, your pet isn’t just cared for—they’re part of the family.</p>
</div>

      <Footer/>
    </>
  )
}

export default AboutWoofily
