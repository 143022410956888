import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '700px',
};

function GetDirections() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hostLat = parseFloat(parseFloat(searchParams.get('hostLat')).toFixed(5));
  const hostLng = parseFloat(parseFloat(searchParams.get('hostLng')).toFixed(5));
  const hostCoordinates = {
    lat: hostLat,
    lng: hostLng,
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyChkWbqyZyOJaRNuT9NDmpo5gW1yVTwPxk', 
  });

  const [userCoordinates, setUserCoordinates] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState(null); // State to hold the distance
  const [zoom, setZoom] = useState(4); // Default zoom level

  const mapRef = useRef(null); // Reference for the map instance

  // Get user's current location using the Geolocation API
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  // Request directions between user and host
  useEffect(() => {
    if (userCoordinates && hostCoordinates) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: userCoordinates,
          destination: hostCoordinates,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === 'OK') {
            setDirectionsResponse(result);
            const route = result.routes[0].legs[0];
            setDistance(route.distance.text); // Set distance between user and host
          } else {
            console.error(`Directions request failed due to ${status}`);
          }
        }
      );
    }
  }, [userCoordinates, hostCoordinates]);

  // Handle zoom change and update the zoom state
  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={userCoordinates || hostCoordinates}
        zoom={zoom}
        onLoad={(map) => (mapRef.current = map)} // Set the map instance reference
        onZoomChanged={handleZoomChanged} // Update zoom when the user zooms in/out
      >
        {/* Custom Marker for user coordinates */}
        {userCoordinates && (
          <Marker position={userCoordinates} label={{ text: "You", color: "black" }} />
        )}

        {/* Custom Marker for host coordinates */}
        <Marker position={hostCoordinates} label={{ text: "Host", color: "black" }} />

        {/* DirectionsRenderer to display the route */}
        {directionsResponse && (
          <DirectionsRenderer
            directions={directionsResponse}
            options={{
              suppressMarkers: true, // Disable the default A/B markers
            }}
          />
        )}
      </GoogleMap>

      {/* Display the distance */}
      {distance && (
        <div style={{ marginTop: '20px' }}>
          <strong>Distance:</strong> {distance}
        </div>
      )}
    </div>
  ) : (
    <div>Loading Map...</div>
  );
}

export default React.memo(GetDirections);
