import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const AutoComplete = (props) => {
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const HandleSelect = async (value) => {
    const results = await geocodeByAddress(value);

    const ll = await getLatLng(results[0]);
    // console.log(ll);
    setAddress(value);
    setCoordinates(ll);
    props.updateCoord(ll)
    props.updateEditVal(value)
    props.closeModal();

  };

  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={HandleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Start typing to search your location",
                className: "location-search-input",
              })}
              style={{
                width: "100%",
                padding: "0.6rem 0.5rem",
                borderRadius: "10px",
                border: '2px solid #feca03',
                outline: 'none',
                marginBottom: '1rem'
              }}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fecc033b",
                      cursor: "pointer",
                      borderBottom: "1px solid #feca03",
                      padding: "1rem 0.5rem",
                    }
                  : {
                      backgroundColor: "#FFF",
                      cursor: "pointer",
                      borderBottom: "1px solid #feca03",
                      padding: "1rem 0.5rem",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default AutoComplete;
