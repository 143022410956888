import React from "react";
import "./howItWorksForPetParent.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { useNavigate } from "react-router-dom";

const HowItWorksForPetParent = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <section className="how-it-works-for-pet-parent-wrapper">
        <div className="first-section-wrapper">
          <div className="info-container">
            <div className="background-wrapper">
              <img
                src="/assets/images/footer-pages/pet-parent/dog-on-ground.png"
                alt=""
              />
            </div>
            <div className="info-text-wrapper">
              <h1>How It Works for Pet Parents</h1>
              <p>
                Woofily makes it a walk in the park to find loving care for your
                furry family member. Whether you’re planning a getaway or just
                need a helping paw for the day, we’ve got you covered with
                trusted, verified hosts. Here’s how you can find the perfect
                stay for your pet!
              </p>
            </div>
          </div>
          <div className="create-your-profile-container flex-center">
            <div className="info-container flex-center">
              <h1>Sign Up and Create Your Pet’s Profile</h1>
              <p>
                Start by creating your Woofily account—it’s as easy as a wag of
                a tail! Share a bit about yourself and your furry friend,
                including their name, breed, age, and any quirks or special
                needs. This helps our hosts get to know your pet better and
                provide the paw-fect care.
              </p>
            </div>
            <div className="media-container flex-center">
              <img
                src="/assets/images/footer-pages/pet-parent/mobile-pic.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="second-section-wrapper flex-center">
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-parent/man-and-dog.png"
              alt=""
            />
            <button
              onClick={() => {
                navigate("/");
              }}
              className="book-now-button"
            >
              Book Now
            </button>
          </div>
          <div className="info-container">
            <h1>Sniff Out the Perfect Host</h1>
            <p>
              Once your profile is ready, it’s time to search for the ideal host
              who will treat your pet like royalty. With Woofily’s simple search
              tools, you can filter hosts by location, availability, and the
              type of care they offer—whether it’s a weekend stay or a daily
              doggy daycare.
            </p>
            <h3>Browse Paw-some Profiles</h3>
            <p>
              Check out host profiles to see their experience, services, and
              reviews from other pet parents. You’ll also find pictures of their
              pet-friendly homes, so you know your fur baby is in for a
              tail-wagging good time!
            </p>
            <h3>See Photos</h3>
            <p>
              Get a sneak peek at the cozy, safe space your pet will be staying
              in. Hosts love showing off their pet paradise!
            </p>
          </div>
        </div>
        <div className="third-section-wrapper flex-center">
          <div className="info-container">
            <h1>Book with Peace of Mind</h1>
            <p>
              Found a host you and your pet love? Great! Send them a booking
              request and share all the details your host needs to know, like
              your pet’s favorite belly rub spot, meal preferences, and any
              special care instructions.
            </p>
            <div className="sub-info-container flex-center">
              <div className="icon-container flex-center">
                <img
                  src="/assets/images/footer-pages/pet-parent/payment-icon.png"
                  alt=""
                />
              </div>
              <div className="sub-info">
                <h3>Secure Payments</h3>
                <p>
                  All payments are handled securely through Woofily—no need to
                  fetch your wallet every time! Your payment is only released
                  after your pet’s stay, so you can feel confident that
                  everything is in paw-fect order.
                </p>
              </div>
            </div>
          </div>
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-parent/laptop-girl.png"
              alt=""
            />
          </div>
        </div>
        <div className="fourth-section-wrapper flex-center">
          <div className="media-container flex-center">
            {/* <img
              src="/assets/images/footer-pages/pet-parent/laptop-girl.png"
              alt=""
            /> */}
          </div>
          <div className="info-container">
            <h1>Drop-Off and</h1>
            <h1>Tail-Wagging Updates</h1>
            <p>
              Once your booking is confirmed, you can arrange to drop off your
              pet. Some hosts may even offer pick-up services for extra
              convenience. While you’re away, you’ll get regular updates,
              photos, and videos showing just how much fun your furry friend is
              having.
            </p>
            <div className="sub-info-container flex-center">
              <div className="icon-container flex-center">
                <img
                  src="/assets/images/footer-pages/pet-parent/clock-icon.png"
                  alt=""
                />
              </div>
              <div className="sub-info">
                <h3>Real-Time Updates</h3>
                <p>
                  No need to worry while you’re away! Your host will keep you
                  updated with plenty of adorable photos and videos so you can
                  see those happy tails and playful paws in action.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fifth-section-wrapper flex-center">
          <div className="info-container">
            <h1>Pick-Up and</h1>
            <h1>Paw-some Reviews</h1>
            <p>
              When it’s time for pick-up, you’ll reunite with your pet, who’s
              sure to have had a paws-itively great time. After the stay, don’t
              forget to leave a review for your host, helping other pet parents
              find the best care for their fur babies.
            </p>
            <div className="sub-info-container flex-center">
              <div className="icon-container flex-center">
                <img
                  src="/assets/images/footer-pages/pet-parent/clock-icon.png"
                  alt=""
                />
              </div>
              <div className="sub-info">
                <h3>Rate Your Experience</h3>
                <p>
                  Did your host go above and beyond? Leave a review and share
                  your experience! Your feedback helps keep our community full
                  of happy tails and happy parents.
                </p>
              </div>
            </div>
          </div>
          <div className="media-container flex-center">
            {/* <img
              src="/assets/images/footer-pages/pet-parent/laptop-girl.png"
              alt=""
            /> */}
          </div>
        </div>
        <div className="sixth-section-wrapper flex-center">
          <div className="heading-wrapper">
            <h1>
              Why Woofily?
              <img
                src="/assets/images/footer-pages/pet-parent/paws-icon.png"
                alt=""
              />
            </h1>
            <p>
              Woofily is more than just a pet-sitting platform—it’s a trusted
              community of animal lovers who treat your pets like family. Here’s
              why pet parents choose Woofily
            </p>
          </div>
          <div className="contents-wrapper flex-center">
            <div className="left-side-contents-wrapper flex-center">
              <div className="content flex-center">
                <div className="icon-container">
                  <img
                    src="/assets/images/footer-pages/pet-parent/verified-icon.png"
                    alt=""
                  />
                </div>
                <div className="info-container">
                  <h3>Verified Hosts</h3>
                  <p>
                    All Woofily hosts are carefully vetted with background
                    checks and home verifications, so you can trust your pet
                    will be in safe paws.
                  </p>
                </div>
              </div>
              <div className="content flex-center">
                <div className="icon-container">
                  <img
                    src="/assets/images/footer-pages/pet-parent/transparent-icon.png"
                    alt=""
                  />
                </div>
                <div className="info-container">
                  <h3>Transparent Profiles</h3>
                  <p>
                    Check out host profiles with detailed information about
                    their experience and services, so you can make an informed
                    decision before booking.
                  </p>
                </div>
              </div>
            </div>
            <div className="right-side-contents-wrapper flex-center">
              <div className="content flex-center">
                <div className="icon-container">
                  <img
                    src="/assets/images/footer-pages/pet-parent/tailored-icon.png"
                    alt=""
                  />
                </div>
                <div className="info-container">
                  <h3>Tailored Care</h3>
                  <p>
                    Whether your pet needs extra belly rubs, medication, or a
                    strict meal plan, Woofily hosts provide personalized care to
                    make every stay a paws-itively perfect one.
                  </p>
                </div>
              </div>
              <div className="content flex-center">
                <div className="icon-container">
                  <img
                    src="/assets/images/footer-pages/pet-parent/real-time-communication-icon.png"
                    alt=""
                  />
                </div>
                <div className="info-container">
                  <h3>Real-Time Communication</h3>
                  <p>
                    Stay in the loop with updates, photos, and messages from
                    your host, so you always know your fur baby is doing great!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seventh-section-wrapper">
          <div className="seventh-section-container flex-center">
            <div className="info-container">
              <h1>Woofily Support</h1>
              <p>
                Got a question or need help? Woofily’s support team is available
                24/7, ready to lend a helping paw! Whether you’re booking your
                first stay or need help with a special request, we’re here to
                make things smooth and stress-free.
              </p>
            </div>
            <div className="media-container">
              <img
                src="/assets/images/footer-pages/pet-parent/woofily-support.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HowItWorksForPetParent;
