// import React, { useState } from "react";
// import "./hostDocumentForm.scss";
// import Header from "../../components/header/Header";
// import Footer from "../../components/footer/Footer";

// const HostDocumentForm = () => {
//   const [activeTab, setActiveTab] = useState(0);

//   // States for Individual and Business forms
//   const [individualData, setIndividualData] = useState({
//     pancardNumber: "",
//     pancardFile: null,
//     pancardPreview: null,
//     aadharNumber: "",
//     aadharFrontFile: null,
//     aadharFrontPreview: null,
//     aadharBackFile: null,
//     aadharBackPreview: null,
//     bankAccount: "",
//     ifscCode: "",
//     cancelledChequeNumber: "",
//     cancelledChequeFile: null,
//     cancelledChequePreview: null,
//   });

//   const [businessData, setBusinessData] = useState({
//     businessType: "",
//     pancardNumber: "",
//     pancardFile: null,
//     pancardPreview: null,
//     gstNumber: "",
//     certificateNumber: "",
//     certificateFile: null,
//     certificatePreview: null,
//     addressProofFile: null,
//     addressProofPreview: null,
//     bankAccount: "",
//     ifscCode: "",
//     cancelledChequeNumber: "",
//     cancelledChequeFile: null,
//     cancelledChequePreview: null,
//   });

//   // Tab change handler
//   const handleTabChange = (newValue) => {
//     setActiveTab(newValue);
//   };

//   // Generic input change handler
//   const handleInputChange = (e, isBusiness = false) => {
//     const { name, value } = e.target;
//     const setState = isBusiness ? setBusinessData : setIndividualData;
//     setState((prev) => ({ ...prev, [name]: value }));
//   };

//   // Generic file change handler
//   const handleFileChange = (e, isBusiness = false) => {
//     const { name, files } = e.target;
//     const file = files[0];
//     const setState = isBusiness ? setBusinessData : setIndividualData;

//     if (file) {
//       const preview = URL.createObjectURL(file);
//       setState((prev) => ({
//         ...prev,
//         [name]: file,
//         [`${name}Preview`]: preview,
//       }));
//     }
//   };

//   const handleSubmit = (e, isBusiness = false) => {
//     e.preventDefault();
//     const data = isBusiness ? businessData : individualData;
//     console.log("Form Submitted: ", data);
//   };

//   return (
//     <div className="host-document-container">
//       <Header />
//       <div className="content">
//         <div className="tabs-container">
//           <button
//             className={`tab-button ${activeTab === 0 ? "active" : ""}`}
//             onClick={() => handleTabChange(0)}
//           >
//             Individual
//           </button>
//           <button
//             className={`tab-button ${activeTab === 1 ? "active" : ""}`}
//             onClick={() => handleTabChange(1)}
//           >
//             Business
//           </button>
//         </div>

//         {/* Individual Form */}
//         {activeTab === 0 && (
//           <form onSubmit={(e) => handleSubmit(e, false)} className="form">
//             {/* Add individual-specific fields */}
//             <label>
//               PAN Card Number
//               <input
//                 type="text"
//                 name="pancardNumber"
//                 value={individualData.pancardNumber}
//                 onChange={(e) => handleInputChange(e, false)}
//                 required
//               />
//             </label>
//             {/* Upload fields */}
//             <label>
//               Upload PAN Card
//               <input
//                 type="file"
//                 name="pancardFile"
//                 onChange={(e) => handleFileChange(e, false)}
//                 accept="image/*"
//               />
//             </label>
//             {individualData.pancardPreview && (
//               <img
//                 src={individualData.pancardPreview}
//                 alt="PAN Card Preview"
//                 className="document-preview"
//               />
//             )}
//             {/* Other fields for Individual */}
//             <button type="submit" className="submit-button">
//               Submit
//             </button>
//           </form>
//         )}

//         {/* Business Form */}
//         {activeTab === 1 && (
//           <form onSubmit={(e) => handleSubmit(e, true)} className="form">
//             <label>
//               Type of Business
//               <select
//                 name="businessType"
//                 value={businessData.businessType}
//                 onChange={(e) => handleInputChange(e, true)}
//                 required
//               >
//                 <option value="" disabled>
//                   Select Business Type
//                 </option>
//                 <option value="Sole Proprietorship">Sole Proprietorship</option>
//                 <option value="Partnership">Partnership</option>
//                 <option value="Private Limited">Private Limited</option>
//                 <option value="Public Limited">Public Limited</option>
//               </select>
//             </label>
//             {/* Other fields for Business */}
//             <button type="submit" className="submit-button">
//               Submit
//             </button>
//           </form>
//         )}
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default HostDocumentForm;

import React, { useState } from "react";
import "./hostDocumentForm.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const HostDocumentForm = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [individualData, setIndividualData] = useState({
    pancardNumber: "",
    pancardFile: null,
    pancardPreview: null,
    aadharNumber: "",
    aadharFrontFile: null,
    aadharFrontPreview: null,
    aadharBackFile: null,
    aadharBackPreview: null,
    bankAccount: "",
    ifscCode: "",
    cancelledChequeNumber: "",
    cancelledChequeFile: null,
    cancelledChequePreview: null,
  });

  const [businessData, setBusinessData] = useState({
    businessType: "",
    pancardNumber: "",
    pancardFile: null,
    pancardPreview: null,
    gstNumber: "",
    certificateNumber: "",
    certificateFile: null,
    certificatePreview: null,
    addressProofFile: null,
    addressProofPreview: null,
    bankAccount: "",
    ifscCode: "",
    cancelledChequeNumber: "",
    cancelledChequeFile: null,
    cancelledChequePreview: null,
  });

  const handleTabChange = (newValue) => setActiveTab(newValue);

  const handleInputChange = (e, isBusiness = false) => {
    const { name, value } = e.target;
    if (isBusiness) {
      setBusinessData((prev) => ({ ...prev, [name]: value }));
    } else {
      setIndividualData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFileChange = (e, isBusiness = false) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const preview = URL.createObjectURL(file);
      if (isBusiness) {
        setBusinessData((prev) => ({
          ...prev,
          [name]: file,
          [`${name}Preview`]: preview,
        }));
      } else {
        setIndividualData((prev) => ({
          ...prev,
          [name]: file,
          [`${name}Preview`]: preview,
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeTab === 0) {
      console.log("Submitting Individual Data:", individualData);
    } else {
      console.log("Submitting Business Data:", businessData);
    }
  };

  return (
    <div className="host-document-container">
      <Header />
      <div className="content">
        <div className="tabs-container">
          <button
            className={`tab-button ${activeTab === 0 ? "active" : ""}`}
            onClick={() => handleTabChange(0)}
          >
            Individual
          </button>
          <button
            className={`tab-button ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabChange(1)}
          >
            Business
          </button>
        </div>

        {activeTab === 0 && (
          <form onSubmit={handleSubmit} className="form">
            <label>
              PAN Card Number
              <input
                type="text"
                name="pancardNumber"
                value={individualData.pancardNumber}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Upload PAN Card
              <input
                type="file"
                name="pancardFile"
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
            {individualData.pancardPreview && (
              <img
                src={individualData.pancardPreview}
                alt="PAN Card Preview"
                className="document-preview"
              />
            )}

            <label>
              Aadhar Card Number
              <input
                type="text"
                name="aadharNumber"
                value={individualData.aadharNumber}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Upload Aadhar Front
              <input
                type="file"
                name="aadharFrontFile"
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
            {individualData.aadharFrontPreview && (
              <img
                src={individualData.aadharFrontPreview}
                alt="Aadhar Front Preview"
                className="document-preview"
              />
            )}
            <label>
              Upload Aadhar Back
              <input
                type="file"
                name="aadharBackFile"
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
            {individualData.aadharBackPreview && (
              <img
                src={individualData.aadharBackPreview}
                alt="Aadhar Back Preview"
                className="document-preview"
              />
            )}

            <label>
              Bank Account Number
              <input
                type="text"
                name="bankAccount"
                value={individualData.bankAccount}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              IFSC Code
              <input
                type="text"
                name="ifscCode"
                value={individualData.ifscCode}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Cancelled Cheque Number
              <input
                type="text"
                name="cancelledChequeNumber"
                value={individualData.cancelledChequeNumber}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Upload Cancelled Cheque
              <input
                type="file"
                name="cancelledChequeFile"
                onChange={handleFileChange}
                accept="image/*"
              />
            </label>
            {individualData.cancelledChequePreview && (
              <img
                src={individualData.cancelledChequePreview}
                alt="Cancelled Cheque Preview"
                className="document-preview"
              />
            )}

            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        )}

{activeTab === 1 && (
  <form onSubmit={handleSubmit} className="form">
    <label>
      Type of Business
      <select
        name="businessType"
        value={businessData.businessType}
        onChange={(e) => handleInputChange(e, true)}
        required
      >
        <option value="" disabled>
          Select Business Type
        </option>
        <option value="Sole Proprietorship">Sole Proprietorship</option>
        <option value="Partnership">Partnership</option>
        <option value="Private Limited">Private Limited</option>
        <option value="Public Limited">Public Limited</option>
      </select>
    </label>

    <label>
      Business PAN Card Number
      <input
        type="text"
        name="pancardNumber"
        value={businessData.pancardNumber}
        onChange={(e) => handleInputChange(e, true)}
        required
      />
    </label>
    <label>
      Upload Business PAN Card
      <input
        type="file"
        name="pancardFile"
        onChange={(e) => handleFileChange(e, true)}
        accept="image/*"
      />
    </label>
    {businessData.pancardPreview && (
      <img
        src={businessData.pancardPreview}
        alt="Business PAN Card Preview"
        className="document-preview"
      />
    )}

    <label>
      GST Number
      <input
        type="text"
        name="gstNumber"
        value={businessData.gstNumber}
        onChange={(e) => handleInputChange(e, true)}
        required
      />
    </label>

    <label>
      Business Registration Certificate Number
      <input
        type="text"
        name="certificateNumber"
        value={businessData.certificateNumber}
        onChange={(e) => handleInputChange(e, true)}
        required
      />
    </label>
    <label>
      Upload Business Registration Certificate
      <input
        type="file"
        name="certificateFile"
        onChange={(e) => handleFileChange(e, true)}
        accept="image/*"
      />
    </label>
    {businessData.certificatePreview && (
      <img
        src={businessData.certificatePreview}
        alt="Registration Certificate Preview"
        className="document-preview"
      />
    )}

    <label>
      Upload Address Proof
      <input
        type="file"
        name="addressProofFile"
        onChange={(e) => handleFileChange(e, true)}
        accept="image/*"
      />
    </label>
    {businessData.addressProofPreview && (
      <img
        src={businessData.addressProofPreview}
        alt="Address Proof Preview"
        className="document-preview"
      />
    )}

    <label>
      Bank Account Number
      <input
        type="text"
        name="bankAccount"
        value={businessData.bankAccount}
        onChange={(e) => handleInputChange(e, true)}
        required
      />
    </label>
    <label>
      IFSC Code
      <input
        type="text"
        name="ifscCode"
        value={businessData.ifscCode}
        onChange={(e) => handleInputChange(e, true)}
        required
      />
    </label>
    <label>
      Cancelled Cheque Number
      <input
        type="text"
        name="cancelledChequeNumber"
        value={businessData.cancelledChequeNumber}
        onChange={(e) => handleInputChange(e, true)}
        required
      />
    </label>
    <label>
      Upload Cancelled Cheque
      <input
        type="file"
        name="cancelledChequeFile"
        onChange={(e) => handleFileChange(e, true)}
        accept="image/*"
      />
    </label>
    {businessData.cancelledChequePreview && (
      <img
        src={businessData.cancelledChequePreview}
        alt="Cancelled Cheque Preview"
        className="document-preview"
      />
    )}
    <button type="submit" className="submit-button">
      Submit
    </button>
  </form>
)}

      </div>
      <Footer />
    </div>
  );
};

export default HostDocumentForm;

