import React from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import DescriptionIcon from "@mui/icons-material/Description";
import './../hostDashboard.scss'

const SideBar = () => {
  return (
    <>
      <ul>
        <li>
          <Link to="/host/host-dashboard">
            <DashboardIcon />
            <h5>Dashboard</h5>
          </Link>
        </li>
        <li>
          <Link to="/host/host-availabilty">
            <EventAvailableIcon />
            <h5>Host Availability</h5>
          </Link>
        </li>
        <li>
          <Link to="/host/my-bookings">
            <AutoStoriesIcon />
            <h5>My Booking</h5>
          </Link>
        </li>
        {/* <li>
          <Link to="/host/create_invoice">
            <DescriptionIcon />
            <h5>Create Invoice</h5>
          </Link>
        </li> */}
      </ul>
    </>
  );
};

export default SideBar;
