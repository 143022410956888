import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";
const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
const PARENT_ID = localStorage.getItem("PARENT_ID");
const CONFIG = {
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
};

// Action to Fectch Pet Profile Data::
export const fetchPetProfileData = createAsyncThunk(
  "fetchParentProfileData",
  async (petId) => {
    const response = await axios.get(
      `${ApiBaseUrl}/parent/${PARENT_ID}/pet/${petId}`,
      CONFIG
    );
    return response.data;
  }
);

const petProfileSlice = createSlice({
  name: "petProfile",
  initialState: {
    isLoading: false,
    petProfileData: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPetProfileData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPetProfileData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.petProfileData = action.payload;
    });
    builder.addCase(fetchPetProfileData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default petProfileSlice.reducer;
