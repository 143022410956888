import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function FaqForParent() {
  return (
    <>
      <Header/>
      <div  style={{padding: "140px 110px"}}>
    <h1>Frequently Asked Questions for Pet Parents</h1>
    <br/>
    <h4>1. How do I find the right host for my pet?</h4>
    <p>You can easily find a trusted host by using Woofily’s search tool. Filter by location, services offered (boarding, daycare, grooming), availability, and reviews from other pet parents. Take a look at host profiles, photos of their home, and the services they provide to ensure the best fit for your pet’s needs.</p>

    <h4>2. How are Woofily hosts verified?</h4>
    <p>All Woofily hosts go through a strict verification process that includes background checks and home assessments. This ensures that every host meets our high standards of safety and care, giving you peace of mind when booking.</p>

    <h4>3. How do I book a host for my pet?</h4>
    <p>Once you’ve found the right host, simply click on their profile, select your dates, and send a booking request. You can communicate with the host to share details about your pet and finalize the stay. Once the host confirms, your booking is set!</p>

    <h4>4. What kind of services can I book for my pet?</h4>
    <p>Woofily hosts offer a range of services, including overnight boarding, daycare, walking, and even grooming. You can choose a host based on the specific needs of your pet and customize the care they receive during their stay.</p>

    <h4>5. How do payments work?</h4>
    <p>All payments are processed securely through Woofily. Once you book a host, the payment is held until your pet’s stay is complete. After the stay, the payment is released to the host, ensuring secure and hassle-free transactions.</p>

    <h4>6. Can I communicate with my host before and during the booking?</h4>
    <p>Absolutely! Woofily’s in-platform messaging allows you to communicate with your host before the booking to discuss your pet’s needs and preferences. During the stay, your host will send updates, photos, and videos so you can see how your pet is doing.</p>

    <h4>7. What happens if there’s an emergency while my pet is with the host?</h4>
    <p>In case of an emergency, your host will contact you immediately and take your pet to the nearest veterinarian. Woofily’s support team is available 24/7 to assist both you and the host in handling any urgent situations.</p>

    <h4>8. What if I need to cancel a booking?</h4>
    <p>If your plans change, you can cancel the booking through the Woofily platform. Be sure to check the host’s cancellation policy, which will determine whether you’re eligible for a full or partial refund. Our support team can assist with any cancellation questions.</p>

    <h4>9. Can I leave specific instructions for my pet’s care?</h4>
    <p>Yes! When you book a host, you can share detailed instructions about your pet’s routine, feeding schedule, medications, and any special care needs. Hosts will follow your guidelines to ensure your pet’s stay is comfortable and stress-free.</p>

    <h4>10. Will I receive updates on my pet during their stay?</h4>
    <p>Yes! Your host will provide regular updates throughout your pet’s stay, including photos and videos. You’ll be able to see how your pet is settling in and having fun, giving you peace of mind while you’re apart.</p>

    <h4>11. What should I do if I’m not happy with the host’s care?</h4>
    <p>If you have any concerns about your pet’s stay, Woofily’s support team is here to help. You can report any issues, and we will work with both you and the host to resolve them quickly and fairly.</p>

    <h4>12. Can I leave a review for my host?</h4>
    <p>Yes! After your pet’s stay, you can leave a review on the host’s profile. Your feedback helps other pet parents make informed decisions and helps Woofily maintain a high standard of care across our community.</p>

    <h4>13. What happens if my pet damages the host’s property?</h4>
    <p>In the unlikely event that your pet damages the host’s property, the host may reach out to resolve the issue. Woofily’s support team is also available to mediate and assist with finding a solution.</p>

    <h4>14. How do I know my pet is safe with the host?</h4>
    <p>Woofily’s verification process ensures that all hosts are qualified, trusted, and committed to providing safe, loving care. With thorough background checks, home assessments, and reviews from other pet parents, you can trust that your pet will be in good hands.</p>

    <h4>15. Can I request a meet-and-greet with the host before booking?</h4>
    <p>Some hosts offer meet-and-greet sessions before the official booking to ensure your pet is comfortable with them. If you’re interested in this, you can communicate with the host to arrange a meeting before confirming the stay.</p>

    <h4>Have more questions?</h4>
    <p>If you need further assistance, our support team is here for you 24/7. Reach out via live chat, email, or explore our Help Center for more answers!</p>
</div>

      <Footer/>
    </>
  )
}

export default FaqForParent
