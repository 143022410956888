import React, { useEffect, useRef, useState } from "react";
import "./clientProfile.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { FaStar } from "react-icons/fa";
import axios from "axios";
import { ApiBaseUrl } from "../../../../../BaseUrl";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHostPetsData,
  fetchHostProfileData,
} from "../../../../../redux/slices/profile/HostProfileSlice";
import { toast } from "react-toastify";
import { Image } from "antd";

const ClientProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, hostProfileData, hostPetsData, error } = useSelector(
    (state) => state.hostProfileData
  );
  const coverPicRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [progressBarWidth, setProgressBarWidth] = useState(100);
  const [closeModal, setCloseModal] = useState("");
  const [allPost, setAllPost] = useState(8);

  const handleCoverPicUpload = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      try {
        var PARENT_ID = localStorage.getItem("PARENT_ID");
        const formData = new FormData();
        formData.append("cover_pic", file);
        const accessToken = localStorage.getItem("ACCESS_TOKEN");
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.put(
          `${ApiBaseUrl}/host/${PARENT_ID}`,
          formData,
          config
        );
        dispatch(fetchHostProfileData());
        // window.location.reload();
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleButtonClickProfile = () => {
    document.getElementById("fileInputProfile").click();
  };

  const [selectedImageProfile, setSelectedImageProfile] = useState(null);

  const handleFileUploadProfile = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImageProfile(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      try {
        var PARENT_ID = localStorage.getItem("PARENT_ID");
        const formData = new FormData();
        formData.append("profile_pic", file);
        const accessToken = localStorage.getItem("ACCESS_TOKEN");
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const response = await axios.put(
          `${ApiBaseUrl}/host/${PARENT_ID}`,
          formData,
          config
        );
        dispatch(fetchHostProfileData());
        // window.location.reload();
      } catch (error) {
        // toast.error("Error uploading image: Please select a different image");
      }
    }
  };

  const calculateAge = (dobString) => {
    const dob = new Date(dobString);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  // Function to Get Parent's Pets Data (from previous code)----------

  const [ParentPetData, setParentPetData] = useState([]);
  useEffect(() => {
    dispatch(fetchHostProfileData());
    dispatch(fetchHostPetsData());
    GetParentPosts();
    // calculateCompletionPercentage(parentProfileData)
  }, [dispatch]);

  const requiredFields = [
    "about",
    "address1",
    "address2",
    "city",
    "cover_pic",
    "dob",
    "email",
    "gender",
    "mobile",
    "name",
    "pincode",
    "profession",
    "profile_pic",
    "role",
    "state",
    "username",
  ];

  const isFieldFilled = (value) => {
    if (Array.isArray(value)) {
      return value.length > 0; // Empty array is considered not filled
    }
    return value !== null && value !== undefined && value !== "";
  };

  // Calculate progress
  const calculateProgress = (data) => {
    if (!data) return 0; // Return 0 if data is null or undefined

    const filledFields = requiredFields.filter((field) =>
      isFieldFilled(data[field])
    ).length;
    const totalFields = requiredFields.length;

    return Math.round((filledFields / totalFields) * 100);
  };
  const progress = calculateProgress(hostProfileData);

  // L O G I C - T O - A D D - P O S T
  const [post, setPost] = useState([]);
  const [picture, setPicture] = useState([]);
  const [sizeError, setSizeError] = useState("");
  const [postData, setPostData] = useState({
    caption: "",
  });

  const GetParentPosts = async () => {
    const PARENT_ID = localStorage.getItem("PARENT_ID");
    const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    };
    try {
      const response = await axios.get(
        `${ApiBaseUrl}/parent/${PARENT_ID}/post`,
        config
      );
      setPost(response.data);
    } catch (error) {
      console.error("Error fetching parent posts:", error);
      // Optionally, handle the error or provide feedback to the user
    }
  };

  const handleImgChange = (e) => {
    setPicture({ image: e.target.files[0] });
  };

  const handleSubmitPost = (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const PARENT_ID = localStorage.getItem("PARENT_ID");

    if (!accessToken || !PARENT_ID) {
      console.error("Missing access token or parent ID.");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const formData = new FormData();
    formData.append("image", picture.image);
    formData.append("caption", postData.caption);

    axios
      .post(`${ApiBaseUrl}/parent/${PARENT_ID}/post`, formData, config)
      .then((result) => {
        navigate("/parent-profile");
        setCloseModal("modal");
        GetParentPosts();
        document.getElementById("postInputVal").value = "";
        setPicture([]);
        window.location.reload();
      })
      .catch((error) => {
        setSizeError("Image size should be less than 10mb.");
        console.error("Error submitting post:", error);
        // Optionally, handle the error by showing a message to the user
      });
  };

  const SeeAllPost = (postLength) => {
    if (allPost === 8) {
      setAllPost(postLength);
    } else {
      setAllPost(8);
    }
  };

  if (isLoading) {
    return <h1>Loading</h1>;
  }
  return (
    <>
      <Header />
      <section className="client-profile-sections-wrapper">
        <section className="first-section-cover-pic-wrapper">
          <div className="parent-cover-pic-container">
            <img src={`${ApiBaseUrl}${hostProfileData?.cover_pic}`} alt="" />
            <div className="edit_cover_pic">
              <input
                ref={coverPicRef}
                type="file"
                accept="image/*"
                id="fileInput"
                name="cover_pic"
                onChange={handleCoverPicUpload}
                style={{ display: "none" }}
              />
              <BorderColorIcon
                onClick={() => {
                  coverPicRef.current.click();
                }}
                className="profile_cover_icon"
              />
            </div>
          </div>
          <div className="parent-profile-pic-container">
            <img src={`${ApiBaseUrl}${hostProfileData?.profile_pic}`} alt="" />
            <input
              type="file"
              accept="image/*"
              id="fileInputProfile"
              name="profile_pic"
              onChange={handleFileUploadProfile}
              style={{ display: "none" }}
            />
            <div onClick={handleButtonClickProfile} className="edit_image_text">
              Edit Image
            </div>
          </div>
        </section>
        <section className="second-section-content-sections-wrapper flex-center">
          <div className="parent-details-wrapper">
            <div className="parent-name-location-container">
              <h1 className="parent-name">{hostProfileData?.name}</h1>
              {/* <p className="parent-id">1234567890</p> */}
              <p className="parent-location">
                Lives in {`${hostProfileData?.city}/${hostProfileData?.state}`}
              </p>
            </div>

            <div className="profile_complete_progressbar">
              <div className="progress">
                <div
                  className="progress_bar"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${progress}%` }}
                >
                  {progress}%
                </div>
              </div>
              <div></div>
            </div>

            <div className="suggestion_root_flex1_button">
              <div className="suggestion_button">
                {" "}
                <Link to={`/update-profile/${hostProfileData?.id}`}>
                  <button>
                    {progress !== 100 ? "Complete Profile" : "Edit Profile"}
                  </button>
                </Link>{" "}
              </div>
              {/* <div className="suggestion_button">
                {" "}
                <Link to={`/`}>
                  <button>Edit Profile</button>
                </Link>{" "}
              </div> */}
              {/* <div className="suggestion_button">
                {" "}
                <Link to={`/`}>
                  <button>My Addresses</button>
                </Link>{" "}
              </div> */}
              <div className="suggestion_button">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Add Post
                </button>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog model_body">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title"
                          style={{
                            fontFamily: "RecoletaRegular",
                            boxShadow: "none",
                          }}
                          id="exampleModalLabel"
                        >
                          Add Post
                        </h5>
                      </div>
                      <div className="modal-body ">
                        <div className="mt-3">
                          <input
                            type="file"
                            required
                            style={{
                              fontFamily: "RecoletaRegular",
                              boxShadow: "none",
                            }}
                            onChange={handleImgChange}
                            name="image"
                            id="postInputVal"
                          />
                        </div>
                        {sizeError !== "" ? (
                          <div className="text-danger">{sizeError}</div>
                        ) : null}
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          style={{ background: "#FECA03", color: "#000" }}
                          type="submit"
                          onClick={handleSubmitPost}
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Save Post
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="about-parent-container">
              <h1 className="about-heading">About</h1>
              <p className="about-text">{hostProfileData?.about}</p>
              <p className="parent-gender">
                <span>Gender: </span> {hostProfileData?.gender}
              </p>
              <p className="parent-age">
                <span>Age: </span>
                {hostProfileData?.dob
                  ? calculateAge(hostProfileData?.dob)
                  : null}
              </p>
              <p className="parent-profession">
                <span>Profession: </span>
                {hostProfileData?.profession}
              </p>
            </div>

            <div className="parent-photos-wrapper">
              
            </div>
          </div>
          <div className="parent-sevice-details-wrapper">
            <div className="parent_pets_wrapper">
              <h3>My Pets</h3>
              <div className="parent_pets">
                {hostPetsData?.slice(0, 4).map((item, key) => {
                  return (
                    <>
                      <div className="parent_pet_img_sec">
                        <Link to={`/pet/${item?.id}`}>
                          <img
                            src={`${ApiBaseUrl}${item?.pet_profile_pic}`}
                            alt=""
                          />
                        </Link>
                        <div className="parent_pet_img_sec_name">
                          {/* {item?.name} */}
                          Hello
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="add_pet_btn">
                  <Link to={`/add-pet`}>
                    <img
                      src="assets/images/add-pet-form/add-pet-btn-icon.png"
                      alt=""
                    />{" "}
                    <AddCircleIcon className="additon_button" />
                  </Link>
                </div>
              </div>
              <div className="see_more_pets mt-3"></div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default ClientProfile;
