import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = (props) => {
  const { cmp } = props;
  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("PARENT_ID");

    if (!token) {
      navigate("/login");
    }
  }, []);

  return <>{cmp}</>;
};

export default Protected;