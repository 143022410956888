import React, { useEffect } from "react";
import "./hostDashboard.scss";
import Dashboard from "./HostDashboard";
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SideBar from "./sidebar/SideBar";
import Header from "../components/header/Header";

const HostDashboard = () => {
  const navigate = useNavigate();
  const CLIENT_ID = localStorage.getItem("PARENT_ID");
  useEffect(() => {
    if (!CLIENT_ID) {
      navigate("/");
    }
  }, [CLIENT_ID]);

  return (
    <>
      {/* <HeaderNav /> */}
      {/* <HostHeader /> */}
      <Header />
      <div className="host_bar">
        <div className="host_container">
          <div className="host_sidebar">
            <div className="host_sidebar_inner">
              <SideBar />
            </div>
          </div>
          <div className="host_outlet">
            <div className="host_outlet_innr">
              <Outlet />
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostDashboard;
