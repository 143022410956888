import React, { useEffect, useState } from "react";
import "./addPetFirstStep.scss";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";

import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { ApiBaseUrl } from "../../../../../BaseUrl";

const UpdatePetFirstStep = ({
  petformvalues,
  setPetformValues,
  nameError,
  adoptionDataError,
  genderError,
  petTypeError,
  petNatureError,
  breedError,
  petweightError,
  petMealTypeError,
  mealTimeError,
  petwalkError,
  adoptDate,
  setAdoptionDate,
  setAdoptionDateError,
  petFriendlyError,
  setPetFriendlyError,
  petNeuteredError,
  setPetNeuteredError,
  kidsFriendlyError,
  setKidsFriendlyError,
  pottyTrainedError,
  setPottyTrainedError,
}) => {
  const [petType, setPetType] = useState("DOG");
  const validateName = () => {
    return petformvalues.name.trim() !== "";
  };
  const validateAdoptionDate = () => {
    return adoptDate !== "";
  };
  const validateGender = () => {
    return petformvalues.gender.trim() !== "";
  };
  const validatePetType = () => {
    return petformvalues.pet_type.trim() !== "";
  };
  const validatePetNature = () => {
    return petformvalues.pet_nature.trim() !== "";
  };
  const validatePetWeight = () => {
    return petformvalues.pet_weight.trim() !== "";
  };
  const validateMealType = () => {
    return petformvalues.pet_meal_type.trim() !== "";
  };
  const validateBreed = () => {
    return petformvalues.pet_bread.trim() !== "";
  };
  const validatemealTime = () => {
    return petformvalues.pet_mealtime_afternoon.trim() !== "";
  };
  const validateWalk = () => {
    return petformvalues.pet_walk_evening.trim() !== "";
  };

  const [value, setValue] = useState(0);
  const [weightvalue, setWeightValue] = useState(0);
  const [natureValue, setNatureValue] = useState("");

  const handleWeigthChange = (newWeight) => {
    setWeightValue(newWeight);
    const weightlabel = getWeightLabel(newWeight);
    setPetformValues({ ...petformvalues, pet_weight: weightlabel });
  };

  const handlePetNatureChange = (newNature) => {
    setNatureValue(newNature);
    const natureLabel = getPetNatureLabel(newNature);
    setPetformValues({ ...petformvalues, pet_nature: natureLabel });
  };

  const getPetNatureImage = () => {
    if (natureValue >= 0 && natureValue < 20) {
      return "/assets/images/add-pet-form/pet-nature/Loving.png";
    }
    if (natureValue >= 20 && natureValue < 40) {
      return "/assets/images/add-pet-form/pet-nature/Happy.png";
    }
    if (natureValue >= 40 && natureValue < 60) {
      return "/assets/images/add-pet-form/pet-nature/Lazy.png";
    }
    if (natureValue >= 60 && natureValue < 80) {
      return "/assets/images/add-pet-form/pet-nature/Naughty.png";
    } else {
      return "/assets/images/add-pet-form/pet-nature/Angry.png";
    }
  };

  const getPetNatureLabel = () => {
    if (natureValue >= 0 && natureValue < 20) {
      return "LOVING";
    } else if (natureValue >= 20 && natureValue < 40) {
      return "HAPPY";
    } else if (natureValue >= 40 && natureValue < 60) {
      return "LAZY";
    } else if (natureValue >= 60 && natureValue < 80) {
      return "NAUGHTY";
    } else if (natureValue >= 80 && natureValue <= 100) {
      return "ANGRY";
    }
  };

  const getWightImage = () => {
    if (weightvalue >= 0 && weightvalue < 20) {
      return petType === "CAT"
        ? "/assets/images/add-pet-form/pet-sizes/Cat/Kitten.png"
        : "/assets/images/add-pet-form/pet-sizes/Dog/Puppy.png";
    }
    if (weightvalue >= 20 && weightvalue < 40) {
      return petType === "CAT"
        ? "/assets/images/add-pet-form/pet-sizes/Cat/Adolscent.png"
        : "/assets/images/add-pet-form/pet-sizes/Dog/Adolscent.png";
    }
    if (weightvalue >= 40 && weightvalue < 60) {
      return petType === "CAT"
        ? "/assets/images/add-pet-form/pet-sizes/Cat/Adult.png"
        : "/assets/images/add-pet-form/pet-sizes/Dog/Adult.png";
    }
    if (weightvalue >= 60 && weightvalue < 80) {
      return petType === "CAT"
        ? "/assets/images/add-pet-form/pet-sizes/Cat/Senior.png"
        : "/assets/images/add-pet-form/pet-sizes/Dog/Senior.png";
    } else {
      return petType === "CAT"
        ? "/assets/images/add-pet-form/pet-sizes/Cat/Senior.png"
        : "/assets/images/add-pet-form/pet-sizes/Dog/Senior.png";
    }
  };
  const getWeightLabel = () => {
    if (weightvalue >= 0 && weightvalue < 20) {
      return petType === "CAT" ? "Kitten" : "Puppy";
    } else if (weightvalue >= 20 && weightvalue < 40) {
      return "Adolescent";
    } else if (weightvalue >= 40 && weightvalue < 60) {
      return "Adult";
    } else if (weightvalue >= 60 && weightvalue < 80) {
      return "Senior";
    } else if (weightvalue >= 80 && weightvalue <= 100) {
      return "Senior";
    }
  };

  const handlePetChange = (e) => {
    setPetformValues({ ...petformvalues, [e.target.name]: e.target.value });
  };

  const handleGenderSelection = (choice) => {
    setPetformValues({ ...petformvalues, gender: choice });
  };
  const handlePettypeSelection = (choice) => {
    setPetformValues({ ...petformvalues, pet_type: choice });
  };
  const handleVegSelection = (choice) => {
    setPetformValues({ ...petformvalues, pet_meal_type: choice });
  };
  const handlePetNeteredSelection = (choice) => {
    setPetformValues({ ...petformvalues, pet_neutered: choice });
  };
  const handleDogFriendlySelection = (choice) => {
    setPetformValues({ ...petformvalues, pet_friendly: choice });
  };
  const handleKidsFriendlySelection = (choice) => {
    setPetformValues({ ...petformvalues, pet_friendly_Kids: choice });
  };
  const handlePottySelection = (choice) => {
    setPetformValues({ ...petformvalues, pet_pottytrained: choice });
  };

  const [optionsVal, setOptionsVal] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const accessToken = localStorage.getItem("ACCESS_TOKEN");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${ApiBaseUrl}/bread/pet_type/${petType}/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const apiOptions = response.data.map((item) => ({
          name: item.bread,
          value: item.id,
        }));
        setOptionsVal(apiOptions);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchData();
  }, [petType, accessToken]);

  const handleChangeBreed = (value) => {
    setSelectedValue(value);
    setPetformValues({ ...petformvalues, pet_bread: value });
  };

  return (
    <>
      <div className="petform">
        <div className="petform_container">
          <div className="flex1">
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Choose your pet </label>
              </div>

              <div className="button_field_pet">
                <button
                  className={`bt ${
                    petformvalues.pet_type === "DOG" ? "selected-male" : ""
                  }`}
                  onClick={() => {
                    handlePettypeSelection("DOG");
                    setPetType("DOG");
                  }}
                >
                  <div>
                    <img
                      src="/assets/images/add-pet-form/pet-type-dog.png"
                      width="35px"
                      alt=""
                    />
                  </div>
                  <div className="pet_profile_name">Dog</div>
                </button>
                <button
                  className={`bt ${
                    petformvalues.pet_type === "CAT" ? "selected-female" : ""
                  }`}
                  onClick={() => {
                    handlePettypeSelection("CAT");
                    setPetType("CAT");
                  }}
                >
                  <div>
                    <img
                      src="/assets/images/add-pet-form/pet-type-cat.png"
                      width="35px"
                      alt=""
                    />
                  </div>
                  <div className="pet_profile_name">Cat</div>
                </button>
              </div>
              <div className="text-danger ps-3 pt-0 ">
                {" "}
                {petTypeError && !validatePetType() && (
                  <div className="error-message">{petTypeError}</div>
                )}
              </div>
            </div>

            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Pet Name</label>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Pet name"
                  onChange={handlePetChange}
                  name="name"
                  value={petformvalues.name}
                  id=""
                />
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {nameError && !validateName() && (
                  <div className="error-message">{nameError}</div>
                )}
              </div>
            </div>
          </div>

          <div className="flex1">
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Gender</label>
              </div>
              <div className="button_filed">
                <button
                  className={`bt ${
                    petformvalues.gender === "MALE" ? "selected-male" : ""
                  }`}
                  onClick={() => handleGenderSelection("MALE")}
                >
                  {" "}
                  <div className="male_icon">
                    {" "}
                    <img
                      src="/assets/images/add-pet-form/gender-male.png"
                      alt=""
                    />
                  </div>{" "}
                  <div className="pet_profile_name">Male</div>{" "}
                </button>
                <button
                  className={`bt ${
                    petformvalues.gender === "FEMALE" ? "selected-female" : ""
                  }`}
                  onClick={() => handleGenderSelection("FEMALE")}
                >
                  {" "}
                  <div className="male_icon">
                    {" "}
                    <img
                      src="/assets/images/add-pet-form/gender-female.png"
                      alt=""
                    />
                  </div>{" "}
                  <div className="pet_profile_name">Female</div>{" "}
                </button>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {genderError && !validateGender() && (
                  <div className="error-message">{genderError}</div>
                )}
              </div>
            </div>

            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Birth Date</label>
              </div>

              <div className="datepicker">
                <Datepicker
                  selected={adoptDate}
                  onChange={(date) => {
                    setPetformValues({ ...petformvalues, adoption_date: date });
                    setAdoptionDate(date);
                    setAdoptionDateError("");
                  }}
                  maxDate={new Date()}
                  showYearDropdown
                  showMonthDropdown
                  placeholderText="Select Birth Date"
                />
                <CalendarMonthIcon />
              </div>
              <div className="text-danger ps-3 pt-0 ">
                {" "}
                {adoptionDataError && (
                  <div className="error-message">{adoptionDataError}</div>
                )}
              </div>
            </div>
          </div>

          <div className="flex1">
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Select Breed</label>
              </div>
              <div className="select_breed_type">
                <div className="select_breedimg">
                  <img
                    src="/assets/images/add-pet-form/pet-breed.png"
                    width="39px"
                    alt=""
                  />
                </div>
                <SelectSearch
                  options={optionsVal}
                  value={selectedValue}
                  name="language"
                  placeholder="Choose your pet breed"
                  search="true"
                  onChange={handleChangeBreed}
                />
                <div className="button_field_pet_more"></div>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {breedError && !validateBreed() && (
                  <div className="error-message">{breedError}</div>
                )}
              </div>
            </div>
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Select Meal Type</label>
              </div>
              <div className="mealPlan ">
                <div className="meap_plan_cont">
                  <div className="veg">
                    <div>
                      {" "}
                      <button onClick={() => handleVegSelection("VEGETARIAN")}>
                        <img
                          src="/assets/images/add-pet-form/meal-type-veg.png"
                          width="90px"
                          height="40px"
                          alt=""
                        />
                      </button>{" "}
                    </div>
                    <div>
                      {" "}
                      <label
                        className={`bt ${
                          petformvalues.pet_meal_type === "VEGETARIAN"
                            ? "selected-veg"
                            : ""
                        }`}
                        htmlFor=""
                      >
                        Veg
                      </label>{" "}
                    </div>
                  </div>
                  <div className="veg">
                    <div>
                      {" "}
                      <button
                        onClick={() => handleVegSelection("NON-VEGETARIAN")}
                      >
                        <img
                          src="/assets/images/add-pet-form/meal-type-non-veg.png"
                          width="90px"
                          height="40px"
                          alt=""
                        />
                      </button>{" "}
                    </div>
                    <div>
                      {" "}
                      <label
                        className={`bt ${
                          petformvalues.pet_meal_type === "NON-VEGETARIAN"
                            ? "selected-veg"
                            : ""
                        }`}
                        htmlFor=""
                      >
                        Non-Veg
                      </label>
                    </div>
                  </div>
                  <div className="veg">
                    <div>
                      {" "}
                      <button onClick={() => handleVegSelection("BOTH")}>
                        <img
                          src="/assets/images/add-pet-form/meal-type-both.png"
                          width="90px"
                          height="40px"
                          alt=""
                        />
                      </button>
                    </div>
                    <div>
                      {" "}
                      <label
                        className={`bt ${
                          petformvalues.pet_meal_type === "BOTH"
                            ? "selected-veg"
                            : ""
                        }`}
                        htmlFor=""
                      >
                        Both
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {petMealTypeError && !validateMealType() && (
                  <div className="error-message">{petMealTypeError}</div>
                )}
              </div>
            </div>
          </div>

          <div className="flex1" style={{ width: "100%" }}>
            <div className="flex1container">
              <div className="labelform">
                <label
                  htmlFor=""
                  style={{ marginLeft: "12px", marginBottom: "0" }}
                >
                  Pet Stage
                </label>
              </div>
              <div className="seek-bar-containers">
                <div className="form-container">
                  <div className="seek-bar-container">
                    <Slider
                      min={0}
                      max={100}
                      value={weightvalue}
                      name="pet_weight"
                      onChange={handleWeigthChange}
                      trackStyle={{ height: 8 }}
                      railStyle={{ backgroundColor: "#FECA03", height: 8 }}
                      handleStyle={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "white",
                        boxShadow: "none",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div className="seeker_button">
                    <div className="seeker_button_continaer">
                      <div className="image-container">
                        <img
                          src={getWightImage()}
                          width="40px"
                          height="35px"
                          alt="Displayed"
                        />
                      </div>

                      <div className="seek-bar-value"> {getWeightLabel()}</div>
                    </div>
                  </div>
                </div>
                <div className="text-danger ps-3 pt-2 ">
                  {" "}
                  {petweightError && !validatePetWeight() && (
                    <div className="error-message">{petweightError}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex1container">
              <div className="labelform">
                <label
                  htmlFor=""
                  style={{ marginLeft: "12px", marginBottom: "0" }}
                >
                  Pet Nature
                </label>
              </div>
              <div className="seek-bar-containers">
                <div className="form-container">
                  <div className="seek-bar-container">
                    <Slider
                      min={0}
                      max={100}
                      value={natureValue}
                      name="pet_nature"
                      onChange={handlePetNatureChange}
                      trackStyle={{ height: 8 }}
                      railStyle={{ backgroundColor: "#FECA03", height: 8 }}
                      handleStyle={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "white",
                        boxShadow: "none",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div className="seeker_button">
                    <div className="seeker_button_continaer">
                      <div className="image-container">
                        <img
                          src={getPetNatureImage()}
                          width="45px"
                          height="40px"
                          alt="Displayed"
                        />
                      </div>

                      <div className="seek-bar-value">
                        {" "}
                        {getPetNatureLabel()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-danger ps-3 pt-2 ">
                  {" "}
                  {petNatureError && !validatePetNature() && (
                    <div className="error-message">{petNatureError}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex1">
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">
                  What time does your pet go for walk ? *
                </label>
              </div>
              <div className="pet_go_for_walk">
                <div className="pet_walk_select">
                  <select
                    onChange={handlePetChange}
                    name="pet_walk_morning"
                    id=""
                  >
                    <option value="">Morning</option>
                    <option value="4AM">4AM</option>
                    <option value="5AM">5AM</option>
                    <option value="6AM">6AM</option>
                    <option value="7AM">7AM</option>
                    <option value="8AM">8AM</option>
                    <option value="9AM">9AM</option>
                    <option value="10AM">10AM</option>
                    <option value="11AM">11AM</option>
                  </select>
                </div>

                <div className="pet_walk_select">
                  <select
                    onChange={handlePetChange}
                    name="pet_walk_evening"
                    id=""
                  >
                    <option value="">Evening</option>

                    <option value="4PM">4PM</option>
                    <option value="5PM">5PM</option>
                    <option value="6PM">6PM</option>
                    <option value="7PM">7PM</option>
                    <option value="8PM">8PM</option>
                    <option value="9PM">9PM</option>
                    <option value="10PM">10PM</option>
                    <option value="11PM">11PM</option>
                  </select>
                </div>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {petwalkError && !validateWalk() && (
                  <div className="error-message">{petwalkError}</div>
                )}
              </div>
            </div>
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Select the time for meal ? *</label>
              </div>
              <div className="pet_go_for_walk">
                <div className="pet_walk_select">
                  <select
                    value={petformvalues.pet_mealtime_morning}
                    onChange={handlePetChange}
                    name="pet_mealtime_morning"
                    id=""
                  >
                    <option value="">Morning</option>
                    <option value="4AM">4AM</option>
                    <option value="5AM">5AM</option>
                    <option value="6AM">6AM</option>
                    <option value="7AM">7AM</option>
                    <option value="8AM">8AM</option>
                    <option value="9AM">9AM</option>
                    <option value="10AM">10AM</option>
                    <option value="11AM">11AM</option>
                  </select>
                </div>
                <div className="pet_walk_select">
                  <select
                    value={petformvalues.pet_mealtime_afternoon}
                    onChange={handlePetChange}
                    name="pet_mealtime_afternoon"
                    id=""
                  >
                    <option value="">Afternoon</option>

                    <option value="12PM">12PM</option>
                    <option value="1PM">1PM</option>
                    <option value="2PM">2PM</option>
                    <option value="3PM">3PM</option>
                  </select>
                </div>
                <div className="pet_walk_select">
                  <select
                    value={petformvalues.pet_mealtime_evening}
                    onChange={handlePetChange}
                    name="pet_mealtime_evening"
                    id=""
                  >
                    <option value="">Evening</option>

                    <option value="4PM">4PM</option>
                    <option value="5PM">5PM</option>
                    <option value="6PM">6PM</option>
                    <option value="7PM">7PM</option>
                    <option value="8PM">8PM</option>
                    <option value="9PM">9PM</option>
                    <option value="10PM">10PM</option>
                    <option value="11PM">11PM</option>
                  </select>
                </div>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {mealTimeError && !validatemealTime() && (
                  <div className="error-message">{mealTimeError}</div>
                )}
              </div>
            </div>
          </div>

          <div className="flex1">
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Is your Pet Neutered? *</label>
              </div>
              <div className="button_field_yes">
                <button
                  className={`bt ${
                    petformvalues.pet_neutered === true ? "selected-yes" : ""
                  }`}
                  onClick={() => {
                    handlePetNeteredSelection(true);
                    setPetNeuteredError("");
                  }}
                >
                  {" "}
                  <div className="pet_profile_name">Yes</div>{" "}
                </button>
                <button
                  className={`bt ${
                    petformvalues.pet_neutered === false ? "selected-no" : ""
                  }`}
                  onClick={() => {
                    handlePetNeteredSelection(false);
                    setPetNeuteredError("");
                  }}
                >
                  {" "}
                  <div className="pet_profile_name">No</div>{" "}
                </button>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {petNeuteredError && (
                  <div className="error-message">{petNeuteredError}</div>
                )}
              </div>
            </div>
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">
                  Is your pet friendly with other pets? *
                </label>
              </div>
              <div className="button_field_yes">
                <button
                  className={`bt ${
                    petformvalues.pet_friendly === true ? "selected-yes" : ""
                  }`}
                  onClick={() => {
                    handleDogFriendlySelection(true);
                    setPetFriendlyError("");
                  }}
                >
                  {" "}
                  <div className="pet_profile_name">Yes</div>{" "}
                </button>
                <button
                  className={`bt ${
                    petformvalues.pet_friendly === false ? "selected-no" : ""
                  }`}
                  onClick={() => {
                    handleDogFriendlySelection(false);
                    setPetFriendlyError("");
                  }}
                >
                  {" "}
                  <div className="pet_profile_name">No</div>{" "}
                </button>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {petFriendlyError && (
                  <div className="error-message">{petFriendlyError}</div>
                )}
              </div>
            </div>
          </div>

          <div className="flex1">
            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Is your pet friendly with kids? *</label>
              </div>
              <div className="button_field_yes">
                <button
                  className={`bt ${
                    petformvalues.pet_friendly_Kids === true
                      ? "selected-yes"
                      : ""
                  }`}
                  onClick={() => {
                    handleKidsFriendlySelection(true);
                    setKidsFriendlyError("");
                  }}
                >
                  {" "}
                  <div className="pet_profile_name">Yes</div>{" "}
                </button>
                <button
                  className={`bt ${
                    petformvalues.pet_friendly_Kids === false
                      ? "selected-no"
                      : ""
                  }`}
                  onClick={() => {
                    handleKidsFriendlySelection(false);
                    setKidsFriendlyError("");
                  }}
                >
                  {" "}
                  <div className="pet_profile_name">No</div>{" "}
                </button>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {kidsFriendlyError && (
                  <div className="error-message">{kidsFriendlyError}</div>
                )}
              </div>
            </div>

            <div className="flex1container">
              <div className="labelform">
                <label htmlFor="">Is your pet potty trained? *</label>
              </div>
              <div className="button_field_yes">
                <button
                  className={`bt ${
                    petformvalues.pet_pottytrained === true
                      ? "selected-yes"
                      : ""
                  }`}
                  onClick={() => {
                    handlePottySelection(true);
                    setPottyTrainedError("");
                  }}
                >
                  {" "}
                  <div className="pet_profile_name">Yes</div>{" "}
                </button>
                <button
                  className={`bt ${
                    petformvalues.pet_pottytrained === false
                      ? "selected-no"
                      : ""
                  }`}
                  onClick={() => {
                    handlePottySelection(false);
                    setPottyTrainedError("");
                  }}
                >
                  {" "}
                  <div className="pet_profile_name">No</div>{" "}
                </button>
              </div>
              <div className="text-danger ps-3  ">
                {" "}
                {pottyTrainedError && (
                  <div className="error-message">{pottyTrainedError}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePetFirstStep;
