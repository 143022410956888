import React from "react";
import "./woofilyCommitments.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";

const WoofilyCommitments = () => {
  return (
    <>
      <Header />
      <section className="woofily-commitments-wrapper">
        <div className="first-section-wrapper">
          <div className="image-overlay flex-center">
            <img
              src="/assets/images/footer-pages/woofily/woofily-commitments.png"
              alt=""
            />
          </div>
          <div className="info-wrapper">
            <h1 className="coffee-soda">
              Woofily's Commitments to Pets, Pet Parents, and Hosts: Upholding
              Compassion and Anti-Discrimination
            </h1>
            <p>
              At Woofily, we are committed to creating a community where every
              pet, pet parent, and host feels welcomed, valued, and respected.
              Our platform is built on a foundation of compassion, fairness, and
              respect for all. We stand firmly against discrimination and are
              dedicated to ensuring that every pet, regardless of breed or
              background, and every person, regardless of race, gender, or
              identity, is treated with care and equality. We align with the
              Prevention of Cruelty to Animals Act, 1960 and the Animal Welfare
              Board of India (AWBI) Guidelines to create a community that
              fosters kindness and protection for all.
            </p>
          </div>
        </div>
        <div className="second-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              For Pets: Compassionate Care Without Discrimination
              {/* <img
                src="/assets/images/footer-pages/pet-host/yellow-paws.png"
                alt=""
              /> */}
            </h1>
            <p>
              At Woofily, pets are at the heart of our mission. We believe that
              every pet, regardless of breed, size, or health condition,
              deserves love and care. Following the principles outlined in the
              Prevention of Cruelty to Animals Act, 1960, we ensure that all
              pets are treated humanely and with the compassion they deserve.
            </p>
          </div>
          <div className="needs-info-cards-wrapper flex-center">
            <div className="left-cards-container">
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-cuddle.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Breed-Neutral Care</h3>
                  <p>
                    Woofily adheres to the AWBI Guidelines, which emphasize that
                    discrimination based on breed is unacceptable. Every pet,
                    regardless of whether they are a dog, cat, or another
                    animal, receives equal care and attention. We encourage our
                    hosts to embrace diversity among pets and to offer the same
                    level of love and care to all.
                  </p>
                </div>
              </div>
            </div>
            <div className="right-cards-container">
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-schedule.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Safe and Comfortable Spaces</h3>
                  <p>
                    Our platform ensures that pets are placed in homes where
                    they will receive the proper care, safety, and comfort. We
                    work closely with hosts to ensure that all pets are treated
                    in accordance with the Prevention of Cruelty to Animals Act,
                    1960, ensuring that no pet is subjected to cruelty or
                    neglect.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="third-section-wrapper">
          <h1 className="coffee-soda">
            For Pet Parents: A Trusted and Inclusive Community
          </h1>
          <p className="sub-head">
            As a pet parent, your primary concern is the well-being of your
            furry family member. Woofily is dedicated to building a community
            that you can trust—one where discrimination has no place and where
            every pet parent is welcomed with open arms.
          </p>
          <div className="activities-wrapper flex-center">
            <div className="activity-card flex-center">
              <div className="media-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/new-experience.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="info-head">Equal Access for All</p>
                <p className="skill-content">
                  Following the AWBI Guidelines, Woofily ensures that no pet
                  parent faces discrimination based on race, gender, religion,
                  or any other factor. Every pet parent has the right to access
                  our services, and we stand against any bias that may prevent
                  them from doing so.
                </p>
              </div>
            </div>
            <div className="activity-card flex-center">
              <div className="media-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/playtime-fun.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="info-head">Transparency and Support</p>
                <p className="skill-content">
                  We offer clear and transparent communication between pet
                  parents and hosts, ensuring that you feel confident in the
                  care your pet is receiving. Woofily is committed to addressing
                  any issues related to discrimination or improper treatment
                  immediately, aligning with the values of the Prevention of
                  Cruelty to Animals Act, 1960.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fourth-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              For Pet Hosts: Empowering You to Provide Compassionate Care
              {/* <img
                src="/assets/images/footer-pages/pet-host/yellow-paws.png"
                alt=""
              /> */}
            </h1>
            <p>
              Woofily recognizes the dedication and hard work that pet hosts
              bring to our community. We are committed to supporting hosts by
              providing a platform that values their contributions and ensures
              that they are treated with respect. We also encourage hosts to
              follow the principles of fairness, kindness, and
              non-discrimination, as outlined in the AWBI Guidelines.
            </p>
          </div>
          <div className="needs-info-cards-wrapper flex-center">
            <div className="left-cards-container">
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-cuddle.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Inclusive Opportunities</h3>
                  <p>
                    Woofily ensures that every qualified host, regardless of
                    background, has the opportunity to care for pets. We believe
                    that diverse experiences among hosts enrich our community
                    and provide pet parents with a wide range of care options.
                    We also support hosts in creating an environment that aligns
                    with the compassionate care mandated by the Prevention of
                    Cruelty to Animals Act, 1960.
                  </p>
                </div>
              </div>
            </div>
            <div className="right-cards-container">
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-schedule.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Education and Resources</h3>
                  <p>
                    Woofily provides educational tools and resources to help
                    hosts care for pets according to best practices. We also
                    provide training on how to avoid discriminatory behavior and
                    ensure that all hosts are equipped to provide safe and
                    loving care to pets of all breeds and backgrounds.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fifth-section-wrapper">
          <div className="top-section-wrapper flex-center">
            <h1>
              Woofily’s Community Guidelines: Creating a Discrimination-Free
              Space Together
            </h1>
            <p>
              At Woofily, we believe in building a community that is inclusive,
              compassionate, and respectful. We are committed to upholding the
              principles of the Animal Welfare Board of India (AWBI) Guidelines
              and the Prevention of Cruelty to Animals Act, 1960 to ensure that
              pets, pet parents, and hosts are treated with the dignity they
              deserve.
            </p>
          </div>
          <div className="tummy-info-container">
            <div className="tummy-info">
              <img
                src="/assets/images/footer-pages/pet-parent/right-food-icon.png"
                alt=""
              />
              <h3 className="tummy-sub-heading">
                Zero Tolerance for Discrimination
              </h3>
              <p>
                Discrimination against pets, pet parents, or hosts based on
                breed, race, gender, religion, or any other characteristic is
                not tolerated on our platform. Woofily actively works to create
                an inclusive environment for all.
              </p>
            </div>
            <div className="tummy-info">
              <img
                src="/assets/images/footer-pages/pet-parent/food-portion-icon.png"
                alt=""
              />
              <h3 className="tummy-sub-heading">Compassionate Care for Pets</h3>
              <p>
                Following the Prevention of Cruelty to Animals Act, 1960,
                Woofily ensures that every pet is treated humanely and with
                care. We require our hosts to follow best practices in animal
                care and to provide a safe, comfortable space for every pet they
                host.
              </p>
            </div>
            <div className="tummy-info">
              <img
                src="/assets/images/footer-pages/pet-parent/avoid-human-food-icon.png"
                alt=""
              />
              <h3 className="tummy-sub-heading">
                Supportive and Inclusive Community
              </h3>
              <p>
                Woofily fosters a supportive community where everyone feels
                welcome. We encourage open communication, education, and
                resources to help our community members provide the best care
                and support for pets, pet parents, and hosts.
              </p>
            </div>
          </div>
          <div className="last-info">
            <p>
              Together, we can create a community where discrimination has no
              place, and where every paw, every parent, and every host is
              celebrated. Join Woofily in building a safe, welcoming, and
              compassionate space for all.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default WoofilyCommitments;
