import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import DescriptionIcon from "@mui/icons-material/Description";
import "./communitySidebar.scss";
import { TbPhotoFilled } from "react-icons/tb";
import { FaSquarePollHorizontal } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";

const HostSidebar = ({
  ChangeCategory,
  selectedCategory,
  setSelectedCategory,
}) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const GetCategories = async () => {
    try {
      const response = await axios.get(`${ApiBaseUrl}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetCategories();
  }, []);
  return (
    <>
      <ul className="sidebar_categories_wrapper">
        <li className="categories">Category</li>
        <li
          className={
            selectedCategory === null ? "category selected" : "category"
          }
          onClick={() => {
            document.location.reload();
          }}
        >
          {/* <img src="/image/communityIcons/photos.png" alt="" /> */}
          All Posts
        </li>
        {categories.map((category, index) => (
          <li
            className={
              selectedCategory === category.id
                ? "category selected"
                : "category"
            }
            key={index}
            onClick={() => ChangeCategory(category.id)}
          >
            {/* <img src="/image/communityIcons/photos.png" alt="" /> */}
            {category.category_name}
          </li>
        ))}
        {/* <li>
          <NavLink activeClassName="active" to="/community/polls">
            <img src="/image/communityIcons/poll.png" alt="" />
            <h5>Polls</h5>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/community/funds">
            <img src="/image/communityIcons/funds.png" alt="" />
            <h5>Funds</h5>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/community/adoption">
            <img src="/image/communityIcons/adoption.png" alt="" />
            <h5>Adoption</h5>
          </NavLink>
        </li> */}
      </ul>
    </>
  );
};

export default HostSidebar;
