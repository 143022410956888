import React, { useState, useEffect } from "react";
import "./emailVerification.scss";
import { Otptimer } from "otp-timer-ts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiBaseUrl } from "../../BaseUrl";
import { toast } from "react-toastify";


const EmailVerification = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const handleEmailVerification = async () => {
    try {
      const response = await axios.post(`${ApiBaseUrl}/email-verifiy`, { email });
      if (response.data.error) {
        toast.warning(response.data.error.message);
      } else {
      localStorage.setItem("PARENT_ID", response.data.client.id);
      navigate("/mobile-updation", { state: { email } });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error?.message || "An error occurred";
      toast.error(errorMessage);
      console.error("Error verifying email:", error);
    }
  };
  const handleImageClick = () => {
    navigate("/email-verification")
   };



return (
    <>
      <div className="login-container-wrapper flex-center">
        <div className="form-container-wrapper flex-center">
          <div className="login-heading-container">
            <h1 className="header-one">Email Verify</h1>
            {/* <p>
              Create your profile and start hosting your pet with our trusted
              hosts.
            </p> */}
          </div>
          <div className="login-form-container flex-center">
          <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
      />
      <button onClick={handleEmailVerification}>Verify Email</button>

         
          </div>
          
        
        </div>
        <div className="content-container-wrapper flex-center" onClick={handleImageClick}>
          <div className="main-img-wrapper">
            <img src="/assets/images/auth/notice-board.png" alt="" />
            <div className="paw-overlay flex-center">
              <img src="/assets/images/auth/yellow-paw.png" alt="" />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default EmailVerification;
