import React, { useState, useEffect, useRef } from "react";
import "./myLocation.scss";
import { ImLocation } from "react-icons/im";
import { FaCaretDown } from "react-icons/fa";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GoogleMap, Marker } from "@react-google-maps/api";
import AutoComplete from "./AutoComplete";
import { ApiBaseUrl } from "../../../../BaseUrl";

const MyLocation = () => {
  const ActualLocation = localStorage.getItem("actual_location");

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const updateCoordinates = (newCoordinates) => {
    setCoordinates(newCoordinates);
    setCenter({
      lat: newCoordinates.latitude,
      lng: newCoordinates.longitude,
    });
    localStorage.setItem("userCoordinates", JSON.stringify(newCoordinates));
  };

  const fetchCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          updateCoordinates({
            lat: latitude,
            lng: longitude,
          });
        },
        (error) => {
          toast.warning("Kindly allow location for better experience.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: true, // Show progress bar
            closeOnClick: true, // Close the toast when clicked
            pauseOnHover: true, // Pause the timer when hovered
            draggable: false, // Allow the toast to be draggable
          });
        }
      );
    } else {
      toast.error("Geolocation is not supported by your browser.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: true, // Show progress bar
        closeOnClick: true, // Close the toast when clicked
        pauseOnHover: true, // Pause the timer when hovered
        draggable: false, // Allow the toast to be draggable
      });
    }
  };

  useEffect(() => {
    const storedCoordinates = localStorage.getItem("userCoordinates");
    if (storedCoordinates) {
      setCoordinates(JSON.parse(storedCoordinates));
    } else {
      fetchCoordinates();
    }
  }, []);

  const [mainValue, setMainValue] = useState("Allow Location Access");
  const [editValue, setEditValue] = useState(
    localStorage.getItem("actual_location")
  );

  const [locationdd, setLocationDD] = useState({
    display: "none",
  });
  const [savedAddressShow, setSavedAddressShow] = useState({
    display: "block",
  });
  const [savedAddAddressShow, setSavedAddAddressShow] = useState({
    display: "flex",
  });

  const [arrowRotateStyle, setArrowRotateStyle] = useState({
    transform: "rotate(0deg)",
  });
  const [allAddressData, setAllAddressData] = useState([]);
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  // const [selectedOption, setSelectedOption] = useState(null);

  const handleLocationNameChange = (event) => {
    setEditValue(event.target.value);
  };

  const LocationDDControl = () => {
    if (locationdd.display === "none") {
      setLocationDD({
        display: "block",
      });
      setArrowRotateStyle({
        transform: "rotate(180deg)",
      });
    } else {
      setLocationDD({
        display: "none",
      });
      setArrowRotateStyle({
        transform: "rotate(0deg)",
      });
    }
  };

  const PARENT_ID = localStorage.getItem("otp_id");
  useEffect(() => {
    if (PARENT_ID) {
      setSavedAddressShow({
        display: "block",
      });
      setSavedAddAddressShow({
        display: "flex",
      });
    } else {
      setSavedAddressShow({
        display: "none",
      });
      setSavedAddAddressShow({
        display: "none",
      });
    }
  }, []);

  const GetAllAddresses = async () => {
    const PARENT_ID = localStorage.getItem("PARENT_ID");

    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      const response = await axios.get(
        `${ApiBaseUrl}/parent/${PARENT_ID}/location`,
        config
      );
      setAllAddressData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const UseSavedAddrss = (addressname) => {
    localStorage.setItem("actual_location", addressname);
    setEditValue(addressname);

    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${addressname}&key=AIzaSyChkWbqyZyOJaRNuT9NDmpo5gW1yVTwPxk`
      )
      .then((response) => {
        if (response.data.results[0].geometry.location.lat) {
          const latlng = {
            lat: response.data.results[0].geometry.location.lat,
            lng: response.data.results[0].geometry.location.lng,
          };

          updateCoordinates(latlng);
          LocationDDControl();
        }
      })
      .catch((error) => {
        console.error("Error fetching geolocation data:", error);
      });
    setTimeout(() => {
      document.location.reload();
    }, 500);
  };
  // --------------------------- P R E V I O U S  F U N C T I O N  T O  F E T C H  L O C A T I O N  B Y  D E F A U L T ----------------
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         axios
  //           .get(
  //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyChkWbqyZyOJaRNuT9NDmpo5gW1yVTwPxk`
  //           )
  //           .then((response) => {
  //             if (
  //               response.data.results &&
  //               response.data.results.length > 0 &&
  //               response.data.results[0].formatted_address
  //             ) {
  //               setMainValue(response.data.results[0].formatted_address);
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Error fetching geolocation data:", error);
  //           });
  //       },
  //       (error) => {
  //         console.warn("Kindly allow woofily to access your current location for your better experience!")
  //         toast.warning(
  //           "Kindly allow woofily to access your current location for your better experience!",
  //           {
  //             position: toast.POSITION.TOP_CENTER,
  //             autoClose: 3000, // Close the toast after 3 seconds
  //             hideProgressBar: true, // Show progress bar
  //             closeOnClick: true, // Close the toast when clicked
  //             pauseOnHover: true, // Pause the timer when hovered
  //             draggable: false, // Allow the toast to be draggable
  //           }
  //         );
  //       }
  //     );
  //   } else {
  //     toast.error("Geolocation is not supported by your browser.", {
  //       position: toast.POSITION.TOP_CENTER,
  //       autoClose: 3000, // Close the toast after 3 seconds
  //       hideProgressBar: true, // Show progress bar
  //       closeOnClick: true, // Close the toast when clicked
  //       pauseOnHover: true, // Pause the timer when hovered
  //       draggable: false, // Allow the toast to be draggable
  //     });
  //   }

  //   GetAllAddresses();
  // }, []);
  // --------------------------- P R E V I O U S  F U N C T I O N  T O  F E T C H  L O C A T I O N  B Y  D E F A U L T  E N D S ----------------

  const SetDefaultLocation = () => {
    const defaultCoord = {
      lat: 28.4594965,
      lng: 77.0266383,
    };
    // updateCoordinates(defaultCoord);

    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${defaultCoord.lat},${defaultCoord.lng}&key=AIzaSyChkWbqyZyOJaRNuT9NDmpo5gW1yVTwPxk`
      )
      .then((response) => {
        if (
          response.data.results &&
          response.data.results.length > 0 &&
          response.data.results[0].formatted_address
        ) {
          localStorage.setItem(
            "actual_location",
            response.data.results[0].address_components[3].short_name
          );
          setEditValue(response.data.results[0].formatted_address);
          localStorage.setItem("hasDefaultLocation", "true");
        }
      })
      .catch((error) => {
        console.error("Error fetching geolocation data:", error);
      });
  };

  useEffect(() => {
    const hasDefaultLocation = localStorage.getItem("hasDefaultLocation");
    if (!hasDefaultLocation) {
      SetDefaultLocation();
    }

    GetAllAddresses();
  }, []);

  const AddNewAddress = async () => {
    const userCoordinates = JSON.parse(localStorage.getItem("userCoordinates"));
    const allInputsData = {
      address_name: "Home",
      latitude: userCoordinates.lat,
      longitude: userCoordinates.lng,
      state: "",
      city: "",
      pincode: null,
      address: editValue,
    };

    const PARENT_ID = localStorage.getItem("PARENT_ID");

    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      const response = await axios.post(
        `${ApiBaseUrl}/parent/${PARENT_ID}/location`,
        allInputsData,
        config
      );

      localStorage.setItem("actual_location", editValue);
      setTimeout(() => {
        document.location.reload();
      }, 500);
    } catch (error) {
      console.error(error);
    }
    GetAllAddresses();
  };

  const FindHostsManually = async () => {
    localStorage.setItem("actual_location", editValue);
    setTimeout(() => {
      document.location.reload();
    }, 500);
  };

  const handleMarkerDragEnd = (event) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=AIzaSyChkWbqyZyOJaRNuT9NDmpo5gW1yVTwPxk`
      )
      .then((response) => {
        if (
          response.data.results &&
          response.data.results.length > 0 &&
          response.data.results[0].formatted_address
        ) {
          localStorage.setItem(
            "actual_location",
            response.data.results[0].formatted_address
          );
          setEditValue(response.data.results[0].formatted_address);

          const latlng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          updateCoordinates(latlng);
        }
      })
      .catch((error) => {
        console.error("Error fetching geolocation data:", error);
      });
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
  };

  const UseCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyChkWbqyZyOJaRNuT9NDmpo5gW1yVTwPxk`
          )
          .then((response) => {
            if (
              response.data.results &&
              response.data.results.length > 0 &&
              response.data.results[0].formatted_address
            ) {
              localStorage.setItem(
                "actual_location",
                response.data.results[0].formatted_address
              );
              // setTimeout(() => {
              //   document.location.reload();
              // }, 500);
              setEditValue(response.data.results[0].formatted_address);

              const latlng = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              updateCoordinates(latlng);
            }
          })
          .catch((error) => {
            console.error("Error fetching geolocation data:", error);
          });
      },
      (error) => {
        // alert("Kindly allow location access to use your current location.");
        toast.warning(
          "Kindly allow location access to use your current location.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: true, // Show progress bar
            closeOnClick: true, // Close the toast when clicked
            pauseOnHover: true, // Pause the timer when hovered
            draggable: false, // Allow the toast to be draggable
          }
        );
      }
    );
  };

  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);
  const locationRef = useRef(null);
  const openButtonRef = useRef(null);

  const LocationDDClose = () => {
    setLocationDD({
      display: "none",
    });
    setArrowRotateStyle({
      transform: "rotate(0deg)",
    });
  };

  const handleLocationClickOutside = (event) => {
    // Check if the click is outside the modal and not on the open button
    if (
      locationRef.current &&
      !locationRef.current.contains(event.target) &&
      openButtonRef.current &&
      !openButtonRef.current.contains(event.target)
    ) {
      LocationDDClose();
    }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleLocationClickOutside);
    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleLocationClickOutside);
    };
  }, []);

  console.log = console.warn = console.error = () => {};
  return (
    <>
      <ToastContainer />
      <div
        ref={openButtonRef}
        onClick={LocationDDControl}
        className="main_content_container"
      >
        <p>
          <ImLocation className="location_icon" />
        </p>
        <input
          disabled
          className="location_address"
          type="text"
          value={ActualLocation ? ActualLocation : mainValue}
        />
        <p>
          <FaCaretDown style={arrowRotateStyle} className="arrow_up_down" />
        </p>
      </div>

      <div
        ref={locationRef}
        className="dropdown_content_container"
        style={locationdd}
      >
        <div
          className="detect_current_location"
          onClick={() => {
            UseCurrentLocation();
            LocationDDControl();
          }}
        >
          <div className="icon_wrapper">
            <FaLocationCrosshairs />
          </div>
          <div className="content_wrapper">
            <p>Detect Current Location</p>
            <span>Using GPS</span>
          </div>
        </div>

        {/* Working on it. */}

        {PARENT_ID ? (
          <div
            className="detect_current_location add_address_btn"
            onClick={handleShow}
            // style={savedAddAddressShow}
          >
            <div className="icon_wrapper">
              <FaPlus />
            </div>
            <div className="content_wrapper">
              <p>Add Address</p>
            </div>
          </div>
        ) : (
          <div
            className="detect_current_location add_address_btn"
            onClick={handleShow}
            // style={savedAddAddressShow}
            style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
          >
            <div className="icon_wrapper" style={{ fontSize: "20px" }}>
              <FaLocationCrosshairs />
            </div>
            <div className="content_wrapper">
              <p>Use a Different Location</p>
            </div>
          </div>
        )}

        <Modal
          show={show}
          onHide={handleClose}
          className="add_address_input_wrapper"
        >
          <Modal.Header closeButton>
            {PARENT_ID ? (
              <Modal.Title>Add Address</Modal.Title>
            ) : (
              <Modal.Title>Select a Location</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <div className="current_location">
              <div className="gmap">
                <GoogleMap
                  center={coordinates}
                  zoom={15}
                  mapContainerStyle={{ width: "100%", height: "475px" }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={(map) => setMap(map)}
                >
                  <Marker
                    position={coordinates}
                    draggable
                    onDragEnd={handleMarkerDragEnd}
                  />
                </GoogleMap>
              </div>
              <div
                className="address_input"
                onClick={handleShowTwo}
                style={{
                  width: "100%",
                  height: "max-content",
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "0",
                  margin: "1rem 0 0 0",
                }}
              >
                <input
                  id="editvaltext"
                  disabled
                  className="location_address"
                  type="text"
                  value={editValue}
                  onChange={handleLocationNameChange}
                  onClick={handleShowTwo}
                  style={{
                    width: "85%",
                    marginRight: "1rem",
                    padding: "0.2rem 0.5rem",
                    userSelect: "none",
                    pointerEvents: "none",
                  }}
                />
                <button
                  onClick={handleShowTwo}
                  style={{
                    padding: "0.2rem 0.5rem",
                    backgroundColor: "#FECA05",
                    borderRadius: "5px",
                    border: "1px solid #FECA05",
                    outline: "none",
                  }}
                >
                  Change
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {PARENT_ID ? (
              <Button
                variant="warning"
                onClick={() => {
                  AddNewAddress();
                  LocationDDControl();
                  handleClose();
                }}
              >
                Confirm & Continue
              </Button>
            ) : (
              <Button
                variant="warning"
                onClick={() => {
                  FindHostsManually();
                  LocationDDControl();
                  handleClose();
                }}
              >
                Find Hosts
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          show={showTwo}
          onHide={handleCloseTwo}
          className="add_address_input_wrapper"
        >
          <Modal.Header closeButton>
            <Modal.Title>Search Location</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "83vh" }}>
            <AutoComplete
              closeModal={handleCloseTwo}
              updateCoord={updateCoordinates}
              updateEditVal={setEditValue}
            />
          </Modal.Body>
        </Modal>
        <div className="saved_addresses" style={savedAddressShow}>
          <h1 className="saved_address_heading">Saved Address:</h1>

          {allAddressData &&
            allAddressData.length > 0 &&
            allAddressData.map((address) => (
              <div
                onClick={() => {
                  UseSavedAddrss(address.address);
                }}
                key={address.id}
                className="address"
              >
                <h3 className="address_tag">{address.address_name}</h3>
                <p className="detailed_address">{address.address}</p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default MyLocation;
