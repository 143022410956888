
import React, { useState, useEffect } from "react";
import "./mobileUpdation.scss";
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { ApiBaseUrl } from "../../BaseUrl";
import { toast } from "react-toastify";

const MobileUpdation = () => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const parentId = localStorage.getItem("PARENT_ID");
  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate("/email-verification")
   };
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const email = location.state?.email || "";
  const handleSendOtp = async () => {
    try {
      const response = await axios.post(`${ApiBaseUrl}/otp-send-mobile`, { mobile });
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  
  const handleUpdateMobile = async () => {
    try {
        const response = await axios.post(
            `${ApiBaseUrl}/mobile-number-update/${parentId}`,
            { otp, mobile }
          );
        if (response.data.error) {
          toast.warning(response.data.error.message);
          } else {
          toast.success("mobile number updated successfully")
          localStorage.setItem("PARENT_ID", response.data.client.id);
          localStorage.setItem("ACCESS_TOKEN", response.data.Jwt.access);
          localStorage.setItem("ROLE", response.data.client.role);
          localStorage.setItem("PROFILE_PIC", response.data.client.profile_pic);
          localStorage.setItem("USER_NAME", response.data.client.name);
          localStorage.setItem("USER_EMAIL", response.data.client.email);
          navigate("/login");
        }
    } catch (error) {
      console.error("Error updating mobile number:", error);
    }
  };


return (
    <>
      <div className="login-container-wrapper flex-center">
        <div className="form-container-wrapper flex-center">
          <div className="login-heading-container">
            <h1 className="header-one">mobile Updation</h1>
            {/* <p>
              Create your profile and start hosting your pet with our trusted
              hosts.
            </p> */}
          </div>
          <div className="login-form-container flex-center">
          <div className="verification-container">
      <h2>Enter Mobile Number and OTP</h2>
      <input
        type="text"
        value={mobile}
        onChange={(e) => setMobile(e.target.value)}
        placeholder="Enter your mobile number"
      />
      <button onClick={handleSendOtp}>Send OTP</button>
      <input
        type="text"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        placeholder="Enter OTP"
      />
      <button onClick={handleUpdateMobile}>Update Mobile Number</button>
    </div>
      </div>
      </div>
        <div className="content-container-wrapper flex-center" onClick={handleImageClick}>
          <div className="main-img-wrapper">
            <img src="/assets/images/auth/notice-board.png" alt="" />
            <div className="paw-overlay flex-center">
              <img src="/assets/images/auth/yellow-paw.png" alt="" />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default MobileUpdation;
