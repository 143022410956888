import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";

// Action to Fectch List of Blog category::
export const fetchBlogCategory = createAsyncThunk("blogs/fetchBlogCategory", async () => {
  const response = await axios.get(`${ApiBaseUrl}/blogCategory`);
  return response.data;
});

const blogCategorySlice = createSlice({
  name: "blogCategory",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBlogCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBlogCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchBlogCategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default blogCategorySlice.reducer;
