import React from "react";
import "./becomeAVerifiedPetHost.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const BecomeAVerifiedPetHost = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <section className="become-a-verified-pet-host-wrapper">
        <div className="first-section-wrapper flex-center">
          <div className="info-container flex-center">
            <h1>
              How to Become a
              <img
                src="/assets/images/footer-pages/pet-host/orange-paws.png"
                alt=""
              />
            </h1>
            <h1>Verified Pet Host with Woofily?</h1>
            <p>
              Ready to start hosting pets with Woofily? Become a verified pet
              host and gain the trust of pet parents who are looking for safe,
              reliable care for their furry friends. Here’s how you can complete
              the verification process and start welcoming pets into your home!
            </p>
          </div>
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-host/host-pets.png"
              alt=""
            />
            <div
              onClick={() => {
                navigate("/become-host");
              }}
              className="cta-btn flex-center"
            >
              <div className="cta-icon flex-center">
                <FaPlus className="icon" />
              </div>
              <div className="cta-info">
                <h3>Become a Verified Host</h3>
                <p>Fill the form to become a host</p>
              </div>
            </div>
          </div>
        </div>
        <div className="second-section-wrapper flex-center">
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-host/cat-question.png"
              alt=""
            />
          </div>
          <div className="info-container flex-center">
            <h1>
              Why Verification Matters?
              <img
                src="/assets/images/footer-pages/pet-host/purple-paws.png"
                alt=""
              />
            </h1>
            <p>
              At Woofily, safety and trust are our top priorities. Pet parents
              want to know their pets are in good hands, and the verification
              process is designed to ensure that every host meets our high
              standards for care and safety. By becoming a verified host, you’ll
              boost your credibility, attract more bookings, and give pet
              parents the peace of mind they need when choosing a host for their
              pets.
            </p>
          </div>
        </div>
        <div className="third-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              Steps to Become a Verified Pet Host
              <img
                src="/assets/images/footer-pages/pet-host/red-paws.png"
                alt=""
              />
            </h1>
            {/* <p>
              Joining Woofily is easy! Here’s what you’ll need to become a
              trusted pet host
            </p> */}
          </div>
          <div className="needs-info-cards-wrapper flex-center">
            <div className="left-cards-container">
              <div className="needs-card card-one">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-pet-friendly-space.png"
                    alt=""
                  />
                  <h3>1. Create Your Woofily Host Profile</h3>
                </div>
                <p>
                  The first step to becoming a verified pet host is creating
                  your profile. It only takes a few minutes! Share a little bit
                  about yourself, your experience with pets, and the types of
                  animals you’re comfortable hosting. Don’t forget to mention
                  any special skills you have, like grooming or handling pets
                  with special needs. The more details you provide, the more
                  trust you’ll build with potential clients.
                </p>
              </div>
              <div className="needs-card card-two">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-availability.png"
                    alt=""
                  />
                  <h3>2. Complete the Host Application</h3>
                </div>
                <p>
                  Once your profile is set up, you’ll need to complete the host
                  application. This helps us gather important information about
                  your hosting environment and the services you offer. You’ll be
                  asked to provide details about your home setup, your
                  availability, and the kind of care you plan to provide for
                  pets.
                </p>
              </div>
              {/* <div className="needs-card card-three">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-reliability.png"
                    alt=""
                  />
                  <h3>Reliability and Responsibility</h3>
                </div>
                <p>
                  As a Woofily host, you’ll be trusted with someone’s beloved
                  pet, so being reliable and responsible is a must. Showing up
                  on time and following through on promised services will help
                  build strong relationships with pet parents.
                </p>
              </div> */}
            </div>
            <div className="middle-cards-container">
              <div className="needs-card card-four">
                <img
                  className="hanging-cat"
                  src="/assets/images/footer-pages/pet-host/hanging-cat.png"
                  alt=""
                />
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-love-pets.png"
                    alt=""
                  />
                  <h3>3. Background Check</h3>
                </div>
                <p>
                  For the safety of pets and their owners, we require every host
                  to complete a basic background check. This step ensures that
                  pet parents can trust the hosts they choose, knowing they’ve
                  been vetted for trustworthiness and reliability. Our
                  background check is quick, secure, and handled by a trusted
                  third-party provider.
                </p>
              </div>
              <div className="needs-card card-five">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-verification.png"
                    alt=""
                  />
                  <h3>4. Home Verification</h3>
                </div>
                <p>
                  Next, Woofily will verify your hosting environment. This step
                  ensures that the space you provide for pets is safe, clean,
                  and comfortable. Depending on your location, this may involve
                  submitting photos of your home or scheduling a quick virtual
                  home tour. We’ll check for pet-friendly spaces, secure areas
                  (like fenced-in yards), and a welcoming atmosphere for your
                  furry guests.
                </p>
              </div>
            </div>
            <div className="right-cards-container">
              <div className="needs-card card-six">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-commitment.png"
                    alt=""
                  />
                  <h3>5. Set Up Payment Information</h3>
                </div>
                <p>
                  To get paid for your hosting services, you’ll need to set up
                  your payment information on Woofily. We offer secure payment
                  options to ensure you receive timely and hassle-free payments
                  after each successful booking. Simply link your bank account
                  or preferred payment method, and you’ll be ready to start
                  earning!
                </p>
              </div>
              <div className="needs-card card-seven">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-clear-communication.png"
                    alt=""
                  />
                  <h3>6. Approval and Badge of Verification</h3>
                </div>
                <p>
                  Once you’ve completed the steps above, your profile will be
                  reviewed by our team. Upon approval, you’ll receive a badge of
                  verification on your Woofily profile, signaling to pet parents
                  that you’ve passed all the necessary checks and are ready to
                  safely host pets. Verified hosts tend to get more bookings, as
                  pet parents feel confident leaving their pets with someone
                  who’s been thoroughly vetted.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fourth-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              Why Become a Verified Host?
              <img
                src="/assets/images/footer-pages/pet-host/yellow-paws.png"
                alt=""
              />
            </h1>
            {/* <p>
      Joining Woofily is easy! Here’s what you’ll need to become a
      trusted pet host
    </p> */}
          </div>
          <div className="needs-info-cards-wrapper flex-center">
            <div className="left-cards-container">
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-cuddle.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Gain Pet Parents' Trust</h3>
                  <p>
                    Verified hosts are more likely to attract bookings because
                    pet parents feel safer knowing their host has been vetted
                    and approved.
                  </p>
                </div>
              </div>
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-cuddle.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Stand Out from the Crowd</h3>
                  <p>
                    Your verification badge helps your profile stand out,
                    showing potential clients that you’re serious about
                    providing top-quality care.
                  </p>
                </div>
              </div>
            </div>
            <div className="right-cards-container">
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-schedule.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>More Opportunities for Bookings</h3>
                  <p>
                    Verified hosts are prioritized in search results, meaning
                    you’ll be more visible to pet parents looking for care in
                    your area.
                  </p>
                </div>
              </div>
              <div className="needs-card flex-center">
                <img
                  src="/assets/images/footer-pages/pet-host/card-cuddle.png"
                  alt=""
                />
                <div className="card-heading">
                  <h3>Enjoy Full Support from Woofily</h3>
                  <p>
                    As a verified host, you’ll have access to 24/7 support,
                    resources, and tips to help you provide the best possible
                    care to your furry guests.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fifth-section-wrapper">
          <div className="more-advice-container flex-center">
            <div className="media-container">
              <img
                src="/assets/images/footer-pages/pet-parent/more-advice.png"
                alt=""
              />
            </div>
            <div className="info-wrapper">
              <h1 className="coffee-soda">Get Started Today!</h1>
              <p>
                Becoming a verified Woofily pet host is a simple,
                straightforward process designed to ensure safety, trust, and
                success for both hosts and pet parents. Ready to take the next
                step? Complete your verification and start hosting happy pets in
                no time!
              </p>
              <p className="bold">
              Start your verification process today and join Woofily’s trusted community of pet hosts!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BecomeAVerifiedPetHost;
