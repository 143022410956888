import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function HostTermAndConditions() {
  return (
    <>
      <Header/>
      <div  style={{padding: "140px 110px"}}>
    <h2>Woofily Host Terms & Conditions</h2>
    <p>Welcome to Woofily! As a trusted pet host, you play an important role in ensuring the safety, comfort, and well-being of the pets in your care. Please read these Host Terms & Conditions carefully to understand your rights and responsibilities. By hosting with Woofily, you agree to abide by these terms.</p>

    <h3>1. Agreement to the Terms</h3>
    <p>By signing up as a pet host on Woofily, you enter into a legally binding agreement with Woofily and agree to adhere to all platform rules, guidelines, and applicable laws. These Terms & Conditions govern your use of the platform, your relationship with Service Users (pet parents), and your responsibilities as a service provider.</p>

    <h3>2. Host Responsibilities</h3>
    <p>As a Woofily host, you agree to provide a safe, comfortable, and welcoming environment for pets in your care. You are expected to:</p>
    <p><strong>Provide Honest Information:</strong> Ensure that all details in your profile, including the services you offer and your availability, are accurate and up-to-date.</p>
    <p><strong>Maintain a Pet-Safe Environment:</strong> Your hosting space must be safe and free from hazards that could harm pets. This includes providing fresh water, clean bedding, and a secure area where pets can rest.</p>
    <p><strong>Follow Pet Parents’ Instructions:</strong> Adhere to the specific instructions provided by the pet parents, including feeding schedules, exercise routines, and medication requirements.</p>
    <p><strong>Supervise Pets:</strong> Never leave pets unattended for long periods and ensure they are supervised at all times during their stay.</p>
    <p><strong>Comply with Local Laws:</strong> As a host, you are responsible for complying with all local laws and regulations related to pet care, including any required permits or licenses.</p>

    <h3>3. Booking and Payment</h3>
    <p><strong>Booking Process:</strong> All bookings and payments must be made through the Woofily platform. Direct solicitations or acceptance of payments outside of the platform are strictly prohibited.</p>
    <p><strong>Cancellation Policy:</strong> Hosts are expected to adhere to the cancellation policies as outlined in their profile. Repeated cancellations or no-shows may result in penalties, including suspension from the platform.</p>
    <p><strong>Payment Distribution:</strong> Woofily processes payments after the completion of the pet’s stay. Hosts will receive their earnings through the payment method set up in their profile.</p>

    <h3>4. Verification and Safety</h3>
    <p>Woofily requires all hosts to complete a verification process to ensure trust and safety across the platform. This may include:</p>
    <p><strong>Background Checks:</strong> Woofily may conduct background checks to confirm your identity and ensure the safety of pets.</p>
    <p><strong>Home Verification:</strong> Woofily may request photos or conduct virtual visits to verify that your hosting environment is pet-safe.</p>
    <p><strong>Profile Reviews:</strong> Woofily reserves the right to review and approve host profiles before they are made available to pet parents.</p>

    <h3>5. Liability and Insurance</h3>
    <p>While Woofily provides a secure platform for booking services, hosts are responsible for maintaining appropriate liability coverage for any accidents or incidents that occur during a pet’s stay.</p>
    <p><strong>Damage and Injury:</strong> As a host, you are responsible for any damage to your property or injuries caused by pets in your care. Similarly, pet parents are responsible for damages caused by their pets.</p>
    <p><strong>Insurance:</strong> Woofily recommends that hosts maintain appropriate pet sitting or boarding insurance to cover any unforeseen events or liabilities.</p>

    <h3>6. Communication with Pet Parents</h3>
    <p>Hosts are required to maintain clear, timely communication with pet parents throughout the booking process. This includes:</p>
    <p><strong>Providing Updates:</strong> Send regular updates, including photos or videos of the pet, to reassure pet parents during their absence.</p>
    <p><strong>Responding to Inquiries:</strong> Respond to booking inquiries or messages from pet parents in a timely and professional manner.</p>
    <p><strong>Emergency Contact:</strong> In case of an emergency, contact the pet parent immediately and seek veterinary care if necessary.</p>

    <h3>7. Prohibited Activities</h3>
    <p>Hosts are prohibited from engaging in any of the following activities:</p>
    <p><strong>Soliciting Outside the Platform:</strong> All transactions must occur through Woofily. Soliciting bookings or accepting payments outside of the platform is not allowed.</p>
    <p><strong>Neglect or Mistreatment:</strong> Hosts must treat all pets with care and respect. Any reports of neglect, abuse, or mistreatment of pets may result in immediate removal from the platform.</p>
    <p><strong>Discriminatory Practices:</strong> Woofily does not tolerate discrimination of any kind. Hosts must provide equal treatment to all pets, regardless of breed, size, or special needs.</p>

    <h3>8. Termination of Service</h3>
    <p>Woofily reserves the right to suspend or terminate your host account if you violate these terms or engage in behavior that compromises the safety and trust of the Woofily community. Violations include, but are not limited to:</p>
    <p><strong>Breaching the terms of this agreement</strong></p>
    <p><strong>Engaging in fraudulent or unethical behavior</strong></p>
    <p><strong>Repeated cancellations or failure to communicate with pet parents</strong></p>

    <h3>9. Modifications to the Terms</h3>
    <p>Woofily may modify these Host Terms & Conditions at any time. You will be notified of any changes, and continued use of the platform after modifications implies your acceptance of the updated terms.</p>

    <h3>10. Contact Us</h3>
    <p>If you have any questions or concerns regarding these Host Terms & Conditions, please contact Woofily’s support team at <a href="mailto:host@woofily.com">host@woofily.com</a>.</p>

    <p><strong>Woofily Host Terms & Conditions | Pet Hosting Rules and Responsibilities</strong></p>
</div>

      <Footer/>
    </>
  )
}

export default HostTermAndConditions
