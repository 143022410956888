
import React from 'react'
import Footer from '../../../components/footer/Footer'
import Header from '../../../components/header/Header'
function FaqForHost() {
  return (
    <>
      <Header/>
      <div style={{padding: "140px 110px"}}>
    
  <h2>Frequently Asked Questions for Hosts</h2>
  <br/>
  <ol>
    <li>
      <strong>How do I become a Woofily pet host?​</strong>
      <p>To become a Woofily pet host, you’ll need to sign up, create a profile, complete the verification process, and set your availability. Once your profile is approved, you can start accepting bookings from pet parents in your area.</p>
    </li>
    <li>
      <strong>What is the verification process for hosts?​</strong>
      <p>The verification process ensures safety and trust across the platform. It includes completing your profile, submitting identification for background checks, and providing information about your hosting environment. Once verified, you’ll receive a badge on your profile that assures pet parents of your credibility.</p>
    </li>
    <li>
      <strong>What kind of pets can I host?​</strong>
      <p>You can choose the type of pets you’d like to host based on your experience and preferences. This may include dogs, cats, or even small animals like rabbits. Make sure to update your profile with the specific pets you're comfortable hosting.</p>
    </li>
    <li>
      <strong>How do I set my rates for hosting?​</strong>
      <p>As a host, you have the freedom to set your own rates based on the services you provide (e.g., boarding, daycare, walking). Woofily offers guidelines, but you can adjust your pricing based on your experience, location, and the type of care you offer.</p>
    </li>
    <li>
      <strong>How do I manage bookings and availability?​</strong>
      <p>You can manage your availability through your host dashboard on Woofily. Keep your calendar up to date to reflect when you’re available for bookings, and respond to booking inquiries in a timely manner to ensure smooth communication with pet parents.</p>
    </li>
    <li>
      <strong>How do I get paid?​</strong>
      <p>All payments are processed through the Woofily platform. After a pet’s stay is completed, payments are automatically deposited into your account using the payment method you’ve set up in your profile. Payments typically process within a few business days.</p>
    </li>
    <li>
      <strong>What should I do if a pet has special needs?​</strong>
      <p>Before accepting a booking, ask the pet parent about any special care instructions, including dietary needs, medications, or behavioral issues. Ensure you’re comfortable providing the necessary care, and clarify any questions with the pet parent before the stay begins.</p>
    </li>
    <li>
      <strong>How do I handle emergency situations?​</strong>
      <p>In case of emergencies, contact the pet parent immediately and follow their instructions. Keep the contact details of local veterinarians handy, and make sure to report any incidents to Woofily’s support team. Woofily provides 24/7 support for hosts dealing with emergencies.</p>
    </li>
    <li>
      <strong>Can I communicate with pet parents before the booking?​</strong>
      <p>Yes! Once you receive a booking request, you can communicate with the pet parent through the Woofily platform to ask questions, clarify details, and discuss any specific care instructions.</p>
    </li>
    <li>
      <strong>What if I need to cancel a booking?​</strong>
      <p>If you need to cancel a booking, it’s important to do so as soon as possible to give the pet parent time to make alternative arrangements. Repeated cancellations may impact your host rating and could result in penalties on your account.</p>
    </li>
    <li>
      <strong>What happens if a pet damages my property?​</strong>
      <p>If a pet causes damage to your property, it’s important to communicate with the pet parent immediately. Woofily recommends having a clear understanding of liability before the booking begins. For major incidents, you can contact Woofily’s support team for assistance in resolving the issue.</p>
    </li>
    <li>
      <strong>How can I improve my chances of getting more bookings?​</strong>
      <p>To attract more bookings, make sure your profile is complete, detailed, and up to date. High-quality photos of your home and any pet-friendly spaces, along with positive reviews from pet parents, will help increase your visibility. Responding to inquiries quickly and maintaining a high level of care will also improve your chances of getting more bookings.</p>
    </li>
    <li>
      <strong>How can I receive reviews from pet parents?​</strong>
      <p>After each completed booking, pet parents are encouraged to leave a review of their experience. To ensure positive reviews, provide excellent care, communicate clearly with pet parents, and follow through on all agreed services.</p>
    </li>
    <li>
      <strong>Are there any restrictions on hosting certain breeds?​</strong>
      <p>Woofily encourages inclusivity but also respects any breed restrictions or preferences hosts may have. If you are uncomfortable hosting certain breeds or pets, be sure to update your profile accordingly so pet parents are aware before making a booking.</p>
    </li>
    <li>
      <strong>What should I do if a pet has behavioral issues?​</strong>
      <p>If a pet exhibits problematic behavior during their stay (e.g., aggression or anxiety), contact the pet parent immediately to discuss the situation. Remain calm and follow any instructions provided by the pet parent to help the pet adjust. If the situation worsens, you can reach out to Woofily support for guidance.</p>
    </li>
  </ol>
</div>

      <Footer/>
    </>
  )
}

export default FaqForHost
