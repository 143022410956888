import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function PetParentSupport() {
  return (
    <>
      <Header/>
      <div  style={{padding: "140px 110px"}}>
    <h1>Pet Parent Support</h1><br/>
    <strong>At Woofily, we’re here to make sure both you and your pets have the best experience possible. Whether you’re preparing for your pet’s stay or have questions during the booking process, our Pet Parent Support team is here to lend a paw!</strong>

    <h3>1. Booking Support: Help Every Step of the Way</h3>
    <p>Booking a trusted host for your pet shouldn’t be complicated. Our support team is available to guide you through the process, ensuring a seamless experience from start to finish.</p>
    <ul>
        <li><strong>Profile Setup Assistance:</strong> Need help creating your pet’s profile? We’ll assist you in filling out important details, like medical history, routines, and preferences, to ensure hosts have all the info they need.</li>
        <li><strong>Finding the Perfect Host:</strong> If you’re having trouble choosing the right host, our team can help you filter hosts based on location, services, and reviews, making sure you find the perfect match for your pet’s needs.</li>
        <li><strong>Booking Modifications:</strong> Plans changed? No problem! We’re here to help with any booking adjustments, including changes to dates, times, or services requested.</li>
    </ul>

    <h3>2. Safety and Verification: Peace of Mind Guaranteed</h3>
    <p>We know how important it is to feel confident when leaving your pet in someone else’s care. That’s why Woofily takes safety seriously, ensuring all hosts are thoroughly verified and qualified to care for your furry friend.</p>
    <ul>
        <li><strong>Verified Hosts:</strong> Every host undergoes a comprehensive verification process, including background checks and home assessments, so you can book with confidence.</li>
        <li><strong>Secure Payments:</strong> All payments are securely processed through the Woofily platform, ensuring that transactions are safe and protected.</li>
        <li><strong>Privacy and Data Protection:</strong> Your personal information is kept private and secure in accordance with our Terms of Service, giving you peace of mind while using the platform.</li>
    </ul>

    <h3>3. Real-Time Communication: Stay Connected with Your Host</h3>
    <p>We know how much you love your pet, and being apart can be tough! That’s why Woofily ensures constant communication between you and your host during your pet’s stay.</p>
    <ul>
        <li><strong>In-Platform Messaging:</strong> Stay in touch with your host before, during, and after the stay using Woofily’s secure messaging system. Share special instructions, ask for updates, or just check in to see how your pet is doing.</li>
        <li><strong>Stay Updates:</strong> Your host will send regular photos and videos of your pet’s adventures, so you can see how much fun they’re having in real time.</li>
    </ul>

    <h3>4. Emergency Assistance: We’re Here for You</h3>
    <p>At Woofily, your pet’s safety is our top priority. If an emergency arises during your pet’s stay, our support team is ready to assist.</p>
    <ul>
        <li><strong>Vet Support:</strong> In case of an emergency, your host will contact you immediately and take your pet to the nearest vet. We recommend providing your vet’s contact details in advance for extra security.</li>
        <li><strong>24/7 Support:</strong> Our support team is available around the clock to address any emergencies or urgent concerns, ensuring that your pet receives immediate care when needed.</li>
    </ul>

    <h3>5. Issue Resolution: We’ve Got Your Back</h3>
    <p>We strive to ensure every pet’s stay is a happy one, but we know that sometimes things don’t go as planned. If you experience any issues during your booking, Woofily’s support team is here to help resolve them quickly and fairly.</p>
    <ul>
        <li><strong>Dispute Resolution:</strong> If you’re unsatisfied with your pet’s experience or have any concerns about your host, Woofily will step in to mediate and find a resolution that works for both parties.</li>
        <li><strong>Damage Claims:</strong> In the rare event that your pet damages a host’s property, or if your pet was injured during their stay, Woofily’s support team will assist you in resolving the issue and determining next steps.</li>
    </ul>

    <h3>6. Cancellation Policy: Flexibility When You Need It</h3>
    <p>We understand that life happens, and plans can change. If you need to cancel your booking, Woofily offers a flexible cancellation policy to accommodate your needs.</p>
    <ul>
        <li><strong>Cancellation Guidelines:</strong> If you need to cancel, simply follow the cancellation policy outlined during the booking process. Our team can assist if you need help understanding the terms or processing the cancellation.</li>
        <li><strong>Refunds:</strong> Depending on the timing of your cancellation, you may be eligible for a refund. Our support team will ensure you know the details and help process your refund if applicable.</li>
    </ul>

    <h3>7. Trusted Community: Built on Love and Care</h3>
    <p>Woofily isn’t just a pet care platform—it’s a community of pet lovers who share your passion for providing the best care possible. We’re here to support you every step of the way, from booking to pick-up, ensuring both you and your pet have a stress-free experience.</p>
    <ul>
        <li><strong>Read Reviews:</strong> Unsure about a host? Read reviews from other pet parents to gain insight into their experience and make an informed decision.</li>
        <li><strong>Leave a Review:</strong> After your pet’s stay, leave a review to help other pet parents find trusted hosts. Your feedback keeps our community strong!</li>
    </ul>

    <h3>8. Need Help? Contact Us!</h3>
    <p>If you have any questions, concerns, or just need a little extra assistance, Woofily’s Pet Parent Support team is always ready to help. Reach out to us 24/7 through:</p>
    <ul>
        <li><strong>Live Chat:</strong> For real-time assistance, connect with one of our support team members via live chat.</li>
        <li><strong>Email Support:</strong> Send us an email, and we’ll get back to you as soon as possible with a solution.</li>
        <li><strong>Help Center:</strong> Explore our Help Center for answers to common questions and tips on how to get the most out of Woofily.</li>
    </ul>

    <p><strong>Woofily Pet Parent Support: Always Here for You</strong></p>
    <p>No matter the question or concern, Woofily’s dedicated support team is here to ensure you and your pet have the best experience possible. We’re more than happy to help, so don’t hesitate to reach out whenever you need us!</p>
</div>

      <Footer/>
    </>
  )
}

export default PetParentSupport
