import ReactPrint from "react-to-print";
import { useRef, useState, useEffect } from "react";
import Barcode from "react-barcode";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Close } from "@mui/icons-material";
import "./invoice.scss";

function Invoice({orderData}) {
  const ref = useRef();
  const [openAirPopup, setAirPopup] = useState(false);
  const [Item, setItem] = useState("");
  const [Amount, setAmount] = useState(0);
  const [Discount, setDiscount] = useState();
  const [Total, setTotal] = useState(0);
  const [List, setList] = useState([]);
  const addData = () => {
    List.push({
      product: Item,
      amount: Amount,
    });
    setItem("");
    setAmount("");
    setAirPopup(false);
  };
  let sum = 0;
  List.forEach((amount) => {
    sum += parseInt(amount.amount);
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB'); 
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return `${formattedDate} ${formattedTime}`;
  }
  return (
    <>
      <div className="invoice-page-wrapper">
        <div ref={ref} className="main_container">
          <div className="heading_container">
            <div className="heading_container_left">
              <img src="/assets/images/app-download-page/woofily-logo.png" alt="Woofily" />
              <h5> Invoice Id: {orderData?.invoice?.invoice_number}</h5>
              <p>
                <b>Date : {formatDate(orderData?.invoice?.created_at)}</b> 
              </p>
              <p>
                <b>Checkin :&nbsp; {orderData?.invoice?.order_id?.start_date}&nbsp;&nbsp;{orderData?.invoice?.order_id?.check_in_time}</b>
              </p>
              <p>
                <b>Checkout:&nbsp; {orderData?.invoice?.order_id?.end_date}&nbsp;&nbsp;{orderData?.invoice?.order_id?.check_out_time}</b>
              </p>
            </div>
            <div className="heading_container_right">
              {/* <p>INVOICE</p> */}
            </div>
          </div>
          <br />
          <div className="info_container">
            {/* <div className="brcode">
                  <Barcode value={`4n%${props.InvoiceNumber}+ut%`} width={1} height={50} displayValue={false} />
                </div> */}
            <div className="info_container_left">
              <h5>Host Details</h5>
              <p>
                <strong>Name:</strong> {orderData?.invoice?.host.name}
              </p>
              <p>
                <strong>Email:</strong>{orderData?.invoice?.host.email}
              </p>
              <p>
                <strong>Phone:</strong> {orderData?.invoice?.host.mobile}
              </p>
              <p>
                <strong>Address:</strong> {orderData?.invoice?.host.address1}{orderData?.invoice?.host.address2}
                {orderData?.invoice?.host.city}{orderData?.invoice?.host.pincode}
              </p>
            </div>
            <div className="info_container_left">
              <h5>Parent Details</h5>
              <p>
                <strong>Name:</strong> {orderData?.invoice?.parent.name}
              </p>
              <p>
                <strong>Pet Name:</strong> {orderData?.PetDetails?.name}
              </p>
              <p>
                <strong>Email:</strong> {orderData?.invoice?.parent.email}
              </p>
              <p>
                <strong>Phone:</strong> {orderData?.invoice?.parent.mobile}
              </p>
              <p>
                <strong>Address:</strong> {orderData?.invoice?.host.address1}{orderData?.invoice?.host.address2}
                {orderData?.invoice?.host.city}{orderData?.invoice?.host.pincode}
              </p>
            </div>
          </div>
          <br />

          <table>
            <thead>
              <tr>
                <th>
                  <h5>Services</h5>
                </th>
                <th>
                  <h5>Charge</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              {List.length
                ? List.map((items, index) => {
                    return (
                      <tr key={index}>
                        <td>{items.product}</td>
                        <td>
                          <i
                            className="fas fa-rupee-sign"
                            area-hidden="true"
                          ></i>
                          ₹ {items.amount}
                        </td>
                      </tr>
                    );
                  })
                : null}
              <tr>
                <td>
                  <p>
                    <strong>Total Amount: {orderData?.invoice?.amount}</strong>
                  </p>
                  <p>
                    <strong>Payable Amount: {orderData?.invoice?.amount}</strong>
                  </p>
                  <p>
                    <strong>Discount: {orderData?.invoice?.order_id.discout_amount}</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>
                      <i className="fas fa-rupee-sign" area-hidden="true"></i> ₹{" "}
                      {orderData?.invoice?.amount}
                    </strong>
                  </p>
                
                  <p>
                    <strong>
                      <i className="fas fa-rupee-sign" area-hidden="true"></i> ₹{" "}
                      {orderData?.invoice?.amount}
                    </strong>
                  </p>
                </td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td>
                  <h5>
                    <strong>Total: {Number(orderData?.invoice?.amount || 0) - Number(orderData?.invoice?.order_id?.discout_amount || 0)}</strong>
                  </h5>
                </td>
                <td>
                  <h5>
                    <strong>
                      <i className="fas fa-rupee-sign" area-hidden="true"></i> ₹{" "}
                      {Number(orderData?.invoice?.amount || 0) - Number(orderData?.invoice?.order_id?.discout_amount || 0)}
                    </strong>
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="btn-wrapper">
         


          {/* <Popup openAirPopup={openAirPopup} setAirPopup={setAirPopup} products={Products} setProducts={setProducts} /> */}

          {/* POPUP OPEN */}
          <Dialog open={openAirPopup} style={{ height: "300px" }}>
            <DialogTitle>
              <div
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>New Service</div>
                <div className="icon-cross" onClick={() => setAirPopup(false)}>
                  <Close />
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div style={{ height: "130px" }}>
                <div>
                  <input
                    className="input_input"
                    type="text"
                    value={Item}
                    onChange={(e) => setItem(e.target.value)}
                    placeholder="Service"
                  />
                  <input
                    className="input_input"
                    type="text"
                    value={Amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Charge ₹"
                  />
                </div>
                <div>
                  <button className="button_btn" onClick={addData}>
                    Add
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
          {/* POPUP CLOSED */}
        </div>
      </div>
    </>
  );
}

export default Invoice;
