import React from 'react'
import './loading.scss'

const Loading = () => {
  return (
    <>
    <section className="loading-wrapper flex-center">
      <div className="loader">
        <img src="/assets/favicon.ico" alt="" />
      </div>
    </section>
    </>
  )
}

export default Loading