import React, { useEffect, useRef, useState } from "react";
import "./addPetSecondStep.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ApiBaseUrl } from "../../../../../BaseUrl";
import Spinner from "react-bootstrap/Spinner";

const UpdatePetSecondStep = ({ petformvalues, setPetformValues, adoptDate }) => {
  const { PetId } = useParams();
  const [isAdding, setIsAdding] = useState(false);
  // console.log("CheckingId",id)
  const navigate = useNavigate();
  const [vaccination_certificate, setVaccination] = useState("");
  const [adoption_certificate, setAdoption_certificate] = useState("");
  const [profile_pic, setProfile_pic] = useState("");
  const [cover_pic, setCover_pic] = useState("");
  // const [health_document, setHealth_document] = useState("");
  // const [pet_license, setPet_license] = useState("");

  const [validationErrors, setValidationErrors] = useState({});

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);

  // useEffect(() => {
  //   console.log("CheckingId",id)
  //   console.log("hello")
  // }, [])

  const [medicationAdded, setMedicationAdded] = useState(false);
  // Function to handle file input change
  const handleFileInputChange1 = (event) => {
    const file = event.target.files[0];
    setVaccination(file);
  };
  const handleFileInputChange2 = (event) => {
    const file = event.target.files[0];
    setAdoption_certificate(file);
  };
  // const handleFileInputChange3 = (event) => {
  //   const file = event.target.files[0];
  //   setHealth_document(file);
  // };
  // const handleFileInputChange4 = (event) => {
  //   const file = event.target.files[0];
  //   setPet_license(file);
  // };
  const handleFileInputChange5 = (event) => {
    const file = event.target.files[0];
    setProfile_pic(file);
  };
  const handleFileInputChange6 = (event) => {
    const file = event.target.files[0];
    setCover_pic(file);
  };
  const handleBrowseButtonClick = () => {
    // Trigger the click event on the hidden file input
    fileInputRef1.current.click();
  };
  const handleAdoptionButtonClick = () => {
    // Trigger the click event on the hidden file input
    fileInputRef2.current.click();
  };
  const handleHealthButtonClick = () => {
    // Trigger the click event on the hidden file input
    fileInputRef3.current.click();
  };
  const handleLicenseButtonClick = () => {
    // Trigger the click event on the hidden file input
    fileInputRef4.current.click();
  };
  const handlePetImageClick = () => {
    // Trigger the click event on the hidden file input
    fileInputRef5.current.click();
  };
  const handlePetImageClick2 = () => {
    // Trigger the click event on the hidden file input
    fileInputRef6.current.click();
  };
  const handlePetChange = (e) => {
    setPetformValues({ ...petformvalues, [e.target.name]: e.target.value });
  };

  const handlepetSubmit = async (e) => {
    e.preventDefault();
    setIsAdding(true);

    try {
      // Step 1: Validate the form fields
      const errors = {};

      if (!vaccination_certificate) {
        errors.vaccination_certificate = "Vaccination Certificate is required";
      }

      // if (!pet_license) {
      //   errors.pet_license = "Pet License is required";
      // }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      // Step 2: Prepare medication details
      const medicationDetails = petformvalues.any_medication
        ? medicineDetails.map((detail) => ({
            medicine: detail.medicine,
            pet_dose: detail.pet_dose,
            pet_time: detail.pet_time,
          }))
        : [];

      // Step 3: Upload pet images
      const formdata = new FormData();
      // formdata.append("health_document", health_document);
      // formdata.append("pet_license", pet_license);
      formdata.append("adoption_certificate", adoption_certificate);
      formdata.append("vaccination_certificate", vaccination_certificate);
      formdata.append("profile_pic", profile_pic);
      formdata.append("cover_pic", cover_pic);

      // Retrieve the access token from local storage
      const accessToken = localStorage.getItem("ACCESS_TOKEN");

      // Set the bearer token in the request headers
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const responseImages = await axios.post(
        `${ApiBaseUrl}/parent/pet-images`,
        formdata,
        config
      );
      const petImagesId = responseImages.data.id;

      setPetformValues(responseImages.data);
      sessionStorage.setItem("pet_id", petImagesId);

      // Step 4: Prepare pet data
      const data = {
        name: petformvalues.name,
        adoption_date: adoptDate.toISOString().split("T")[0],
        // adoption_date: adoptDate,
        gender: petformvalues.gender,
        pet_type: petformvalues.pet_type,
        pet_nature: petformvalues.pet_nature || "LOVING",
        pet_neutered: petformvalues.pet_neutered || "No",
        pet_weight: petformvalues.pet_weight,
        pet_meal_type: petformvalues.pet_meal_type,
        pet_mealtime_morning: petformvalues.pet_mealtime_morning,
        pet_mealtime_afternoon: petformvalues.pet_mealtime_afternoon,
        pet_mealtime_evening:
          petformvalues.pet_mealtime_evening || "Not Choose",
        pet_images: petImagesId,
        pet_walk_morning: petformvalues.pet_walk_morning || "7AM",
        pet_walk_evening: petformvalues.pet_walk_evening,
        pet_friendly: petformvalues.pet_friendly || "No",
        pet_bread: petformvalues.pet_bread,
        pet_friendly_Kids: petformvalues.pet_friendly_Kids || "No",
        pet_pottytrained: petformvalues.pet_pottytrained || "No",
        pet_healthissue: "0",
        any_medication: petformvalues.any_medication,
        pet_medications: medicationDetails,
        pet_healthissue: petformvalues.pet_healthissue,
        additional_info: petformvalues.additional_info || "No",
      };

      // Step 5: Submit pet data
      const PARENT_ID = localStorage.getItem("PARENT_ID");
      await axios.put(`${ApiBaseUrl}/parent/${PARENT_ID}/pet/${PetId}`, data, config);

      // Step 6: Show success message and navigate
      toast.success("Pet updated successfully!");
      setTimeout(() => {
        // if (id === Number(1001)) {
        //   navigate(`/profile`);
        // }else {
        //   navigate(`/book_your_pet`)
        // }

        navigate(-1);
      }, 900);
    } catch (error) {
      // Handle errors here
      toast.error(
        "Failed to proceed. Please check all the input fields and try again."
      );
      setIsAdding(false);
      console.error("Error submitting pet:", error);
      // setTimeout(() => {
      //   navigate(`/parent-profile`);
      // }, 1000);
      // You can display an error message to the user if needed.
    }
  };

  const [medicineDetails, setMedicineDetails] = useState([
    { medicine: "", pet_dose: "", pet_time: "" },
  ]);
  const addMedicineDetail = () => {
    setMedicineDetails([
      ...medicineDetails,
      { medicine: "", pet_dose: "", pet_time: "" },
    ]);
    setMedicationAdded(true);
  };
  const handleInputChange = (index, field, value) => {
    const updatedDetails = [...medicineDetails];
    updatedDetails[index][field] = value;
    setMedicineDetails(updatedDetails);
  };
  const removeMedicineDetail = (index) => {
    const updatedDetails = [...medicineDetails];
    updatedDetails.splice(index, 1);
    setMedicineDetails(updatedDetails);
  };
  const handleAnyMedicatonSelection = (choice) => {
    setPetformValues({ ...petformvalues, any_medication: choice });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1499}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="PetFormOne_root_nect_page" style={{ paddingTop: "2rem" }}>
        <div className="PetFormOne_root_wrapper">
          <div className="PetFormOne_root_flex1">
            <div className="PetFormOne_root_flex2inp_next_page">
              <div>
                <label htmlFor="">Upload Pet Profile Pic</label>
              </div>
              <div className="petfrom_button">
                <input
                  type="text"
                  name="profile_pic"
                  placeholder="Pet Profile Pic"
                  value={profile_pic.name || ""}
                  readOnly
                />
                <input
                  type="file"
                  id="fileUpload"
                  ref={fileInputRef5}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange5}
                />

                <button onClick={handlePetImageClick}>Browse</button>
              </div>
              <span
                style={{
                  color: "red",
                  marginLeft: "20px",
                  fontSize: "13px",
                  marginTop: "-5px",
                }}
              >
                *Required format .jpg
              </span>
            </div>
            <div className="PetFormOne_root_flex2inp_next_page">
              <div>
                <label htmlFor="">Upload Pet Cover Pic</label>
              </div>
              <div className="petfrom_button">
                <input
                  type="text"
                  name="cover_pic"
                  placeholder="Pet Cover Pic"
                  value={cover_pic.name || ""}
                  readOnly
                />
                <input
                  type="file"
                  id="fileUpload"
                  ref={fileInputRef6}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange6}
                />

                <button onClick={handlePetImageClick2}>Browse</button>
              </div>
              <span
                style={{
                  color: "red",
                  marginLeft: "20px",
                  fontSize: "13px",
                  marginTop: "-5px",
                }}
              >
                *Required format .jpg
              </span>
            </div>

            <div className="PetFormOne_root_flex1inp_next_page">
              <div>
                <label htmlFor="">Are they on any medication ?</label>
              </div>
              <div className="petfrom_button">
                <button
                  className={`bt ${
                    petformvalues.any_medication === true ? "selected-yes" : ""
                  }`}
                  onClick={() => handleAnyMedicatonSelection(true)}
                >
                  Yes
                </button>
                <button
                  className={`bt ${
                    petformvalues.any_medication === false ? "selected-no" : ""
                  }`}
                  onClick={() => handleAnyMedicatonSelection(false)}
                >
                  No
                </button>
              </div>
              {petformvalues.any_medication && (
                <>
                  <div>
                    <label htmlFor="">Fill medication details</label>
                  </div>
                  {/* <p style={{marginLeft: '20px'}}>Write your medication details</p> */}
                  {/* Your medication details form */}
                  {medicineDetails.map((detail, index) => (
                    <div key={index}>
                      <div className="mt-0">
                        <input
                          type="text"
                          placeholder="Medicine Name"
                          value={detail.medicine}
                          onChange={(e) =>
                            handleInputChange(index, "medicine", e.target.value)
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <input
                          type="text"
                          placeholder="Dosage"
                          value={detail.pet_dose}
                          onChange={(e) =>
                            handleInputChange(index, "pet_dose", e.target.value)
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <input
                          type="time"
                          placeholder="Frequency"
                          value={detail.pet_time}
                          onChange={(e) =>
                            handleInputChange(index, "pet_time", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <div className="text-center mt-3">
                    {medicationAdded && (
                      <button
                        className="btn btn-warning me-3"
                        onClick={(index) => removeMedicineDetail(index)}
                      >
                        Delete
                      </button>
                    )}
                    <button
                      style={{
                        borderRadius: "38px",
                        color: "#fff",
                        padding: "7px 20px",
                      }}
                      className="add_med btn btn-warning me-3"
                      onClick={addMedicineDetail}
                    >
                      Add Medicine Detail+
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* ----------flex2 ----------- */}
          <div className="PetFormOne_root_flex2_next_page">
            <div className="PetFormOne_root_flex2inp_next_page">
              <div>
                <label htmlFor="">Upload Vaccination Certificate*</label>
              </div>
              <div className="petfrom_button">
                <input
                  type="text"
                  name="vaccination_certificate"
                  placeholder="Upload Vaccaination Certificate"
                  value={vaccination_certificate.name || ""}
                  readOnly
                />
                <input
                  type="file"
                  id="fileUpload"
                  ref={fileInputRef1}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange1}
                />

                <button onClick={handleBrowseButtonClick}>Browse</button>
              </div>
              <span
                style={{
                  color: "red",
                  marginLeft: "20px",
                  fontSize: "13px",
                  marginTop: "-2px",
                }}
              >
                *Required format .jpg/.pdf
              </span>
              <div className="ps-3">
                {validationErrors.vaccination_certificate && (
                  <div className="error text-danger">
                    {validationErrors.vaccination_certificate}
                  </div>
                )}
              </div>
            </div>
            <div className="PetFormOne_root_flex2inp_next_page">
              <div>
                <label htmlFor="">Upload Adoption Certificate</label>
              </div>
              <div className="petfrom_button">
                <input
                  type="text"
                  name="adoption_certificate"
                  placeholder="Uplaod Adoption Certificate"
                  value={adoption_certificate.name || ""}
                  readOnly
                />
                <input
                  type="file"
                  id="fileUpload"
                  ref={fileInputRef2}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange2}
                />

                <button onClick={handleAdoptionButtonClick}>Browse</button>
              </div>
              <span
                style={{
                  color: "red",
                  marginLeft: "20px",
                  fontSize: "13px",
                  marginTop: "-2px",
                }}
              >
                *Required format .jpg/.pdf
              </span>
              {/* <div className='ps-3'>{validationErrors.adoption_certificate && <div className="error text-danger">{validationErrors.adoption_certificate}</div>}</div> */}
            </div>

            {/* <div className="PetFormOne_root_flex2inp_next_page">
              <div>
                <label htmlFor="">Upload your Pet Health Document</label>
              </div>
              <div className="petfrom_button">
                <input
                  type="text"
                  name="health_document"
                  placeholder="Upload Pet Health"
                  value={health_document.name || ""}
                  readOnly
                />
                <input
                  type="file"
                  id="fileUpload"
                  ref={fileInputRef3}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange3}
                />

                <button onClick={handleHealthButtonClick}>Browse</button>
              </div>
              <span
                style={{
                  color: "red",
                  marginLeft: "20px",
                  fontSize: "13px",
                  marginTop: "-2px",
                }}
              >
                *Required format .jpg/.pdf
              </span>
            </div> */}
            {/* <div className="PetFormOne_root_flex2inp_next_page">
              <div>
                <label htmlFor="">Pet License Document*</label>
              </div>
              <div className="petfrom_button">
                <input
                  type="text"
                  name="pet_license"
                  placeholder="Upload Pet License"
                  value={pet_license.name || ""}
                  readOnly
                />
                <input
                  type="file"
                  id="fileUpload"
                  ref={fileInputRef4}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange4}
                />

                <button onClick={handleLicenseButtonClick}>Browse</button>
              </div>
              <span
                style={{
                  color: "red",
                  marginLeft: "20px",
                  fontSize: "13px",
                  marginTop: "-2px",
                }}
              >
                *Required format .jpg/.pdf
              </span>
              <div className="ps-3">
                {validationErrors.pet_license && (
                  <div className="error text-danger">
                    {validationErrors.pet_license}
                  </div>
                )}
              </div>
            </div> */}
            <div className="PetFormOne_root_flex2inp_next_page">
              <div>
                <label htmlFor=""> About your Pet</label>
              </div>
              <input
                type="text"
                name="additional_info"
                onChange={handlePetChange}
                value={petformvalues.additional_info}
                placeholder="Write something about your pet"
                id=""
              />
            </div>
          </div>
          <div className="submit_pet_buttonS">
            {isAdding ? (
              <button type="submit">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </button>
            ) : (
              <button onClick={handlepetSubmit} type="submit">
                Save{" "}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePetSecondStep;
