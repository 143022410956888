import React,{useEffect,useState,useRef} from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import "./final-order-summary.scss"
import { useParams } from "react-router-dom";
import { ApiBaseUrl } from '../../../../BaseUrl';
import axios from 'axios';
import { MdDownload } from 'react-icons/md';
import html2pdf from 'html2pdf.js';
import Invoice from '../../../invoice-module/invoice';
import { Modal, Rate, Input } from 'antd';


function FinalOrderSummary() {
  const invoiceRef = useRef();
  const { order_id } = useParams();
  const [orderData, setOrderData] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const [rating, setRating] = useState(null); 
  const [hasRated, setHasRated] = useState(false); 
  const [postError, setPostError] = useState(null); 

  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [selectedRating, setSelectedRating] = useState(0); 

  const [ratings, setRatings] = useState([]); 
  const [parentRating, setParentRating] = useState(null); 

  const [comment, setComment] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  useEffect(() => {
    const fetchRatings = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      try {
        const response = await axios.get(`${ApiBaseUrl}/host/${orderData?.invoice?.host?.id}/rating`, config);
        setRatings(response.data);
        const matchedRating = response.data.find(
          (rating) => rating.parent.id === orderData?.invoice?.parent.id
        );
        if (matchedRating) {
          setParentRating(matchedRating); 
        }
      } catch (err) {
        setError("Failed to fetch ratings");
      }
    };
  
    if (orderData) {
      fetchRatings();
    }
  }, [orderData, accessToken]);
  
  const handleModalRatingSubmit = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      const parentId = orderData?.invoice?.parent?.id; // Assuming parent ID comes from order data
      const hostId = orderData?.invoice?.host?.id;
      await axios.post(`${ApiBaseUrl}/parent/${parentId}/host/${hostId}/rating`, {
        rating: selectedRating,
        comment:comment
      }, config);
  
      setRating(selectedRating);
      setHasRated(true);
      setIsModalVisible(false); 
      window.location.reload(); 
    } catch (err) {
      setPostError("Failed to submit rating");
    }
  };
  
  useEffect(() => {
    const fetchOrderData = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      try {
        const response = await axios.get(`${ApiBaseUrl}/Generate-Invoice/${order_id}`,config);
        setOrderData(response.data); 
        setLoading(false); 
      } catch (err) {
        setError("Failed to fetch order data"); 
        setLoading(false); 
      }
    };
    fetchOrderData(); 
  }, [order_id]);

  const downloadPdf = () => {
    const element = invoiceRef.current;
    const opt = {
      margin: 0,
      filename: `invoice-${order_id}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  };

  return (
    <>
    <Header />
    <section style={{ paddingTop: "100px",paddingBottom:"200px" }}>
    <div className='summary'>
    <div className="summary-heading">
    <h4>Order Summary</h4>
    </div>
    </div>
    <div className="download-invoice" >
          <button className="download-invoice-link" onClick={downloadPdf}>
            <MdDownload /> Download Invoice 
          </button>
    </div>
    
     {/* Hidden Invoice Component */}
     <div style={{ display: 'none' }}> {/* Hide the Invoice component in the UI */}
          <div ref={invoiceRef}> {/* Reference to the invoice content */}
            <Invoice orderData={orderData} />
          </div>
      </div>
    <div className="order-summary-details">
   

    {/* Rating Services */}
    <div className="section rating">
  
  <h2>How was the service?</h2>
  {parentRating ? (
    <div className="stars">
      {[...Array(5)].map((_, i) => (
        <span key={i} className={`star ${i < parentRating.rating ? "filled" : ""}`}>
          ★
        </span>
      ))}
      <p>Your Rating: {parentRating.rating} out of 5</p>
      <p>Comment: {parentRating.comment || "No Comment"}</p>
    </div>
  ) : (
    <div className="stars" onClick={showModal}>
      {[...Array(5)].map((_, i) => (
        <span key={i} className="star">
          ★
        </span>
      ))}
      <p>Click to rate</p>
    </div>
  )}





  <Modal
    title="Rate the service"
    visible={isModalVisible}
    onOk={handleModalRatingSubmit}
    onCancel={handleCancel}
  >
    <p>Select your rating:</p>
    <Rate
      value={selectedRating}
      onChange={(value) => setSelectedRating(value)} // Update selected rating on change
    />
    <p>Leave a comment:</p>
    <Input.TextArea 
      value={comment}
      onChange={(e) => setComment(e.target.value)} // Capture comment
      placeholder="Write your comment here..."
    />
    {postError && <p className="error">{postError}</p>}
  </Modal>
    </div>


      {/* Host Information */}
      <div className="section host-info">
      <div className="host-info-grid">
      <div className="info-header">
        <h2>Host Details</h2>
      </div>
      <div className="host-details-row">
        <img src={ApiBaseUrl+orderData?.invoice?.host?.profile_pic} alt="" className="host-image" />
        <p><strong>Name:  {orderData?.invoice?.host?.name}</strong> </p>
        <p><strong>Gender:  {orderData?.invoice?.host?.gender}</strong> </p>
        <p><strong>Email:  {orderData?.invoice?.host?.email}</strong> </p>
      
      </div>
      <p><strong>Phone No:  {orderData?.invoice?.host?.mobile}</strong> </p>
      <div>Address:  {orderData?.invoice?.host?.address1} {orderData?.invoice?.host?.address2} {orderData?.invoice?.host?.city}-
      {orderData?.invoice?.host?.state} {orderData?.invoice?.host?.pincode} 
      </div>
      </div>
      </div>

      {/* Order Details */}
      <div className="section order-details">
      <div className="order-detail-info-grid">
      <div className="info-header">
        <h2>Order Details</h2>
      </div>
      <div className="order-details-row">
        <p><strong>Order ID: </strong> {orderData?.invoice?.order_id?.id} </p>
        <p><strong>Created At: </strong> {orderData?.invoice?.order_id?.created_at} </p>
      </div>
      <div className="order-details-row">
        <p><strong>Checkin Time: </strong> {orderData?.invoice?.order_id?.check_in_time} </p>
        <p><strong>Checkout Time:</strong>  {orderData?.invoice?.order_id?.check_out_time} </p>
      </div>
      <div className="order-details-row">
        <p><strong>Amount:</strong>  {orderData?.invoice?.amount} </p>
        <p><strong> Additional Info: </strong> {orderData?.invoice?.order_id?.any_additional_information} </p>
      </div>
      </div>
      </div>

       {/* Contact Us and Chat with Us Section */}
       <div className="section contact-chat-info">
            <h2>Contact Us</h2>
            <p>If you have any queries or need assistance, feel free to <strong>Contact Us</strong> at <a href="tel:+919711598828">+919711598828</a>.</p>
            <h2>Chat with Us</h2>
            <p>You can also <strong>Chat with Us</strong> directly through email by sending your queries to <a href="mailto:care@woofily.com">care@woofily.com</a>.</p>
        </div>

    </div>
    </section>
     <Footer />
     </>
  )
}

export default FinalOrderSummary
