import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./slices/blogs/BlogsSlice";
import readBlogReducer from "./slices/blogs/ReadBlogSlice";
import loginReducer from "./slices/auth/LoginSlice";
import registerReducer from "./slices/auth/RegisterSlice";
import homeHostsReducer from "./slices/home/HomeHostsSlice";
import filteredHostsReducer from "./slices/home/FilteredHostsSlice";
import parentProfileReducer from "./slices/profile/ParentProfileSlice";
import petProfileReducer from "./slices/profile/PetProfileSlice";
import isHostReducer from "./slices/host/IsAllowedToHostSlice";
import blogCategoryReducer from "./slices/blogs/BlogCategorySlice";
import hostProfileReducer from "./slices/profile/HostProfileSlice";

export const store = configureStore({
  reducer: {
    blogs: blogReducer,
    readBlog: readBlogReducer,
    blogCategory: blogCategoryReducer,
    auth: loginReducer,
    signup: registerReducer,
    homeHosts: homeHostsReducer,
    filteredHosts: filteredHostsReducer,
    parentProfileData: parentProfileReducer,
    petProfileData: petProfileReducer,
    hostProfileData: hostProfileReducer,
    isHost: isHostReducer,
  },
  devTools: true,
});
