import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";

const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
const PARENT_ID = localStorage.getItem("PARENT_ID");
const CONFIG = {
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
};

// Action to Fetch Host Profile Data
export const fetchHostProfileData = createAsyncThunk(
  "hostProfile/fetchHostProfileData",
  async () => {
    const response = await axios.get(`${ApiBaseUrl}/host/${PARENT_ID}`, CONFIG);
    return response.data;
  }
);

// Action to Fetch Host Pets Data
export const fetchHostPetsData = createAsyncThunk(
  "hostProfile/fetchHostPetsData",
  async () => {
    const response = await axios.get(
      `${ApiBaseUrl}/host/${PARENT_ID}/pet`,
      CONFIG
    );
    return response.data;
  }
);

const hostProfileSlice = createSlice({
  name: "hostProfile",
  initialState: {
    isLoading: false,
    hostProfileData: null,
    hostPetsData: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // Handling Host Profile Data
      .addCase(fetchHostProfileData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHostProfileData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hostProfileData = action.payload;
      })
      .addCase(fetchHostProfileData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // Handling Host Pets Data
      .addCase(fetchHostPetsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHostPetsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hostPetsData = action.payload;
      })
      .addCase(fetchHostPetsData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default hostProfileSlice.reducer;