import React from "react";
import "./hostDedicatedSupport.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";

const HostDedicatedSupport = () => {
  return (
    <>
      <Header />
      <section className="host-dedicated-support-wrapper">
        <div className="first-section-wrapper flex-center">
          <div className="info-container">
            <h1>
              Host Dedicated Support
              <img
                src="/assets/images/footer-pages/pet-parent/care-tips-paws.png"
                alt=""
              />
            </h1>
            <h1>with Woofily</h1>
            <p>
              Providing the care, support, and guidance you need to succeed as a
              pet host. Woofily is here for you, every step of the way!
            </p>
          </div>
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-parent/nurse-with-dog.png"
              alt=""
            />
          </div>
        </div>
        <div className="second-section-wrapper flex-center">
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-host/cat-question.png"
              alt=""
            />
          </div>
          <div className="info-container flex-center">
            <h1>
              Why Choose Woofily’s
              <img
                src="/assets/images/footer-pages/pet-host/purple-paws.png"
                alt=""
              />
            </h1>
            <h1>Dedicated Host Support?</h1>
            <p>
              At Woofily, we know that caring for pets isn’t just a job—it’s a
              passion. And we’re here to make sure you have everything you need
              to succeed. Whether you’re a new host or a seasoned pet care pro,
              our dedicated support team is here to help you with everything
              from setting up your profile to managing bookings and ensuring
              every pet’s stay is a happy one!
            </p>
            <p>
              We’ve got your back with personalized, 24/7 support, expert
              resources, and a friendly community that’s always here to lend a
              helping paw.
            </p>
          </div>
        </div>
        <div className="third-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              What Does Host Dedicated Support Include?
              <img
                src="/assets/images/footer-pages/pet-host/red-paws.png"
                alt=""
              />
            </h1>
          </div>
          <div className="needs-info-cards-wrapper flex-center">
            <div className="left-cards-container">
              <div className="needs-card card-one">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-pet-friendly-space.png"
                    alt=""
                  />
                  <h3>24/7 Help Whenever You Need It</h3>
                </div>
                <p>
                  Need help at 2 a.m. with a booking issue? No problem! Our
                  support team is available 24/7 to assist you with any
                  questions, concerns, or technical difficulties you may
                  encounter. We’re just a click or call away, ensuring you
                  always have someone to turn to.
                </p>
              </div>
              <div className="needs-card card-two">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-availability.png"
                    alt=""
                  />
                  <h3>Personalized Guidance for Your Hosting Journey</h3>
                </div>
                <p>
                  New to hosting? No worries! Our team will guide you through
                  every step of the way, from creating your profile to
                  completing your first booking. We’ll share tips on how to set
                  up your hosting environment, build strong relationships with
                  pet parents, and provide exceptional care for pets.
                </p>
              </div>
            </div>
            <div className="middle-cards-container">
              <div className="needs-card card-four">
                <img
                  className="hanging-cat"
                  src="/assets/images/footer-pages/pet-host/hanging-cat.png"
                  alt=""
                />
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-love-pets.png"
                    alt=""
                  />
                  <h3>Fast and Friendly Solutions​</h3>
                </div>
                <p>
                  We understand that hosting pets can sometimes come with
                  unexpected challenges. Whether it’s a last-minute booking, a
                  special request from a pet parent, or a question about Woofily
                  policies, we’ll provide fast, friendly solutions to ensure
                  your hosting experience goes smoothly.
                </p>
              </div>
              <div className="needs-card card-five">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-verification.png"
                    alt=""
                  />
                  <h3>Hosting Best Practices and Resources</h3>
                </div>
                <p>
                  Stay informed and up-to-date with our collection of expert
                  resources. We provide articles, videos, and guides on
                  everything from pet care tips to hosting best practices.
                </p>
                <p>
                  Want to learn how to manage multiple pets or handle
                  special-needs animals? We’ve got you covered with helpful tips
                  to elevate your hosting game.
                </p>
              </div>
            </div>
            <div className="right-cards-container">
              <div className="needs-card card-six">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-commitment.png"
                    alt=""
                  />
                  <h3>Conflict Resolution</h3>
                </div>
                <p>
                  Hosting pets should be a joyful experience, but sometimes
                  things don’t go as planned. Our team is trained to help
                  mediate and resolve any issues between hosts and pet parents,
                  ensuring both sides are satisfied and that pets always receive
                  the best care possible.
                </p>
              </div>
              <div className="needs-card card-seven">
                <div className="card-heading flex-center">
                  <img
                    src="/assets/images/footer-pages/pet-host/card-clear-communication.png"
                    alt=""
                  />
                  <h3>Profile Optimization Tips</h3>
                </div>
                <p>
                  Want to attract more bookings? We’ll help you optimize your
                  host profile to stand out in search results. From improving
                  your profile description to adding eye-catching photos, our
                  support team will provide personalized tips to help you shine!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fourth-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              How to Access Dedicated Support?
              <img
                src="/assets/images/footer-pages/pet-host/orange-paws.png"
                alt=""
              />
            </h1>
            <p>
              Accessing Woofily’s dedicated host support is simple! Whether you
              need help with a specific issue or just want advice on how to
              improve your hosting services, here’s how you can reach us:
            </p>
          </div>
          <div className="services-wrapper flex-center">
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Live Chat</h3>
                <p>
                  Get real-time assistance with any questions or concerns via
                  our 24/7 live chat feature.
                </p>
              </div>
            </div>
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Email Support</h3>
                <p>
                  Prefer email? No problem. Send us your questions, and our team
                  will get back to you promptly.
                </p>
              </div>
            </div>
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Phone Support</h3>
                <p>
                  For more urgent matters, call our dedicated support line to
                  speak with a Woofily team member directly.
                </p>
              </div>
            </div>
            <div className="service-container">
              <div className="service-image">
                <img src="" alt="" />
              </div>
              <div className="service">
                <h3>Help Center</h3>
                <p>
                  Browse through our extensive Help Center for answers to common
                  questions, tips, and resources on how to be the best Woofily
                  host you can be.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fifth-section-wrapper flex-center">
          <div className="central-heading-container">
            <h1>
              Testimonials from Our Happy Hosts
              <img
                src="/assets/images/footer-pages/pet-host/testimonial-paws.png"
                alt=""
              />
            </h1>
            {/* <p>
      As a Woofily host, you can customize your services to fit your
      skills and interests
    </p> */}
          </div>
          <div className="testimonial-wrapper flex-center">
            <div className="testimonial">
              <div className="profile-wrapper flex-center">
                <div className="profile-img">
                  <img src="/assets/images/female-dummy-profile.jpeg" alt="" />
                </div>
                <div className="profile-name">
                  <h3>Sana Chowdhary</h3>
                  <p>Pet Parent</p>
                </div>
              </div>
              <div className="review">
                <p>
                  "For all sceptical/worried dog owners Woofily has got your
                  back! Impressed by their passion and professionalism towards
                  giving your dog the best care possible in your absence! I
                  would highly recommend them!"
                </p>
              </div>
            </div>
            <div className="testimonial">
              <div className="profile-wrapper flex-center">
                <div className="profile-img">
                  <img src="/assets/images/male-dummy-profile.png" alt="" />
                </div>
                <div className="profile-name">
                  <h3>Bharat Virmani</h3>
                  <p>Pet Parent</p>
                </div>
              </div>
              <div className="review">
                <p>
                  "Great Experience boarding my dog Ollie with Manmi from
                  Woofily. He was well taken care of and felt comfortable. Plus
                  their team was really helpful in finding the right boarder
                  quickly."
                </p>
              </div>
            </div>
            <div className="testimonial">
              <div className="profile-wrapper flex-center">
                <div className="profile-img">
                  <img src="/assets/images/female-dummy-profile.jpeg" alt="" />
                </div>
                <div className="profile-name">
                  <h3>Pooja Aggarwal</h3>
                  <p>Pet Parent</p>
                </div>
              </div>
              <p className="review">
                "We have left our pup in their care many times. Woofily has been
                the best doggie daycare for Pluto so far. He always comes back
                home well fed and disease free, unlike the other pet boarding
                facilities. Highly recommended"
              </p>
            </div>
          </div>
        </div>
        <div className="sixth-section-wrapper">
          <div className="seventh-section-container flex-center">
            <div className="info-container">
              <h1>Join Woofily’s Community of Supported Hosts</h1>
              <p>
                With Woofily’s dedicated host support, you’re never alone.
                Whether you’re looking for expert advice or need quick
                assistance, we’re always here to ensure your hosting experience
                is smooth and successful. Pet parents rely on you, and you can
                rely on us!
              </p>
              <p className="bold">
                Ready to take your hosting to the next level?​
              </p>
              <p>
                Join Woofily today and experience the difference with our
                host-dedicated support. Your success is our priority!
              </p>
            </div>
            <div className="media-container">
              <img
                src="/assets/images/footer-pages/pet-parent/woofily-support.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HostDedicatedSupport;
