import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";
import queryString from "query-string";

// Action to Fetch List of Filtered Hosts:
const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
const PARENT_ID = localStorage.getItem("PARENT_ID");
// export const fetchFilteredHosts = createAsyncThunk(
//   "fetchFilteredHosts",
//   async ({ selectedPetType, selectedServiceName, selectedDays }) => {
//     const queryParams = {
//       pet_type: selectedPetType,
//       servicename: selectedServiceName,
//       available_days: selectedDays,
//     };
//     const requestUrl = `${ApiBaseUrl}/host-filter?${queryString.stringify(
//       queryParams
//     )}`;
//     const response = await axios.get(requestUrl);
//     // Ensure that an array is always returned
//     return Array.isArray(response.data) ? response.data : [];
//   }
// );

export const fetchFilteredHosts = createAsyncThunk(
  "fetchFilteredHosts",
  async ({ selectedPetType, selectedServiceName, selectedDays }) => {
    // Build query parameters
    const queryParams = {
      pet_type: selectedPetType,
      servicename: selectedServiceName,
      available_days: selectedDays,
    };
    // Determine which API URL to use
    const requestUrl = isUserLoggedIn
      ? `${ApiBaseUrl}/host-filter-by/${PARENT_ID}?${queryString.stringify(queryParams)}`
      : `${ApiBaseUrl}/host-filter?${queryString.stringify(queryParams)}`;

    // Make the API call
    const response = await axios.get(requestUrl);

    // Ensure that an array is always returned
    return Array.isArray(response.data) ? response.data : [];
  }
);


const filteredHostsSlice = createSlice({
  name: "filteredHosts",
  initialState: {
    isLoading: false,
    filteredHosts: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilteredHosts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFilteredHosts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filteredHosts = action.payload; // Always an array
    });
    builder.addCase(fetchFilteredHosts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.filteredHosts = []; // Ensure filteredHosts is an empty array on error
    });
  },
});

export default filteredHostsSlice.reducer;
