import React from "react";
import "./petCareTipsAndAdvice.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";

const PetCareTipsAndAdvice = () => {
  return (
    <>
      <Header />
      <section className="pet-care-tips-and-advice-wrapper">
        <div className="first-section-wrapper flex-center">
          <div className="info-container">
            <h1>
              pet care tips and advice
              <img
                src="/assets/images/footer-pages/pet-parent/care-tips-paws.png"
                alt=""
              />
            </h1>
            <p>
              At Woofily, we know that your pets are part of the family, and
              keeping them happy, healthy, and safe is your top priority.
              Whether you’re a pet parent to a playful pup, a curious kitty, or
              a small and mighty furball, we’ve got pawsome tips to help you be
              the best pet parent ever!
            </p>
          </div>
          <div className="media-container flex-center">
            <img
              src="/assets/images/footer-pages/pet-parent/nurse-with-dog.png"
              alt=""
            />
          </div>
        </div>
        <div className="second-section-wrapper">
          <div className="top-section-wrapper flex-center">
            <h1>Keep Their Tummy Happy with the Right Food</h1>
            <p>
              A healthy diet is the foundation of a happy pet! Here’s how to
              make sure your fur baby is getting the right nutrition:
            </p>
            <div className="tummy-full-image-wrapper">
              <img
                src="/assets/images/footer-pages/pet-parent/tummy-full.png"
                alt=""
              />
            </div>
          </div>
          <div className="tummy-info-container">
            <div className="tummy-info">
              <img
                src="/assets/images/footer-pages/pet-parent/right-food-icon.png"
                alt=""
              />
              <h3 className="tummy-sub-heading">
                Choose the Right Food for Their Age and Breed
              </h3>
              <p>
                Puppies, kittens and senior pets all have different nutritional
                needs. Make sure you're feeding your pet a diet that matches
                their life stage.
              </p>
            </div>
            <div className="tummy-info">
              <img
                src="/assets/images/footer-pages/pet-parent/food-portion-icon.png"
                alt=""
              />
              <h3 className="tummy-sub-heading">Watch Portion Sizes</h3>
              <p>
                Overfeeding can lead to obesity, while underfeeding can cause
                malnutrition. Always follow the recommended portion sizes on the
                pet food label, and consult with your vet if you're unsure.
              </p>
            </div>
            <div className="tummy-info">
              <img
                src="/assets/images/footer-pages/pet-parent/avoid-human-food-icon.png"
                alt=""
              />
              <h3 className="tummy-sub-heading">Avoid Human Foods</h3>
              <p>
                Some human foods, like chocolate, onions, and grapes, can be
                harmful to pets. Stick to pet-friendly snacks or consult your
                vet for safe treats.
              </p>
            </div>
          </div>
        </div>
        <div className="third-section-wrapper">
          <div className="left-side-info-container">
            <h1>Exercise: A tired pet is A happy Pet!</h1>
            <p>
              Just like humans, pets need regular exercise to stay fit, healthy,
              and mentally stimulated. Here’s how to keep them active:
            </p>
            <div className="exercise-sub-info">
              <h3>Daily Walks for Dogs</h3>
              <p>
                Dogs love adventure! Whether it’s a walk around the neighborhood
                or a hike in the park, daily exercise helps prevent boredom and
                keeps your pup happy and healthy.
              </p>
              <h3>Interactive Play for Cats</h3>
              <p>
                Cats may not need walks, but they do need stimulation. Use
                feather toys, laser pointers, or even DIY puzzle toys to engage
                their curiosity and get them moving.
              </p>
              <h3>Playdates and Socialization</h3>
              <p>
                If your pet enjoys being around other animals, organizing
                playdates with other pets can be a great way to keep them active
                and well-socialized.
              </p>
            </div>
          </div>
          <div className="right-side-media-container">
            <img
              src="/assets/images/footer-pages/pet-parent/pet-exercise-media.png"
              alt=""
            />
          </div>
        </div>
        <div className="fourth-section-wrapper">
          <div className="top-regular-grooming-wrapper">
            <div className="left-media-wrapper">
              <img
                src="/assets/images/footer-pages/pet-parent/groomed-cat.png"
                alt=""
              />
            </div>
            <div className="right-info-wrapper">
              <h1 className="coffee-soda">
                Regular grooming for a healthy coat
              </h1>
              <p>
                Grooming isn't just about keeping your pet looking good—it's
                also important for their health! Here's how to make grooming a
                regular part of your routine
              </p>
            </div>
          </div>
          <div className="bottom-regular-grooming-info-wrapper flex-center">
            <div className="regular-grooming-info-card brushing">
              <div className="icon-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/brushing.png"
                  alt=""
                />
              </div>
              <div className="content-wrapper">
                <h3>Brushing</h3>
                <p>
                  Regular brushing helps reduce shedding, prevent matting, and
                  distribute natural oils that keep their coat shiny and
                  healthy.
                </p>
              </div>
            </div>
            <div className="regular-grooming-info-card bathing">
              <div className="icon-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/bathing.png"
                  alt=""
                />
              </div>
              <div className="content-wrapper">
                <h3>Bathing</h3>
                <p>
                  Depending on your pet's breed, they may need a bath every few
                  weeks. Use a pet-friendly shampoo and make bath time a fun,
                  positive experience!
                </p>
              </div>
            </div>
            <div className="regular-grooming-info-card nail-trimming">
              <div className="icon-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/nail-trimming.png"
                  alt=""
                />
              </div>
              <div className="content-wrapper">
                <h3>Nail Trimming</h3>
                <p>
                  Long nails can be uncomfortable for your pet and may lead to
                  injury. Regular nail trims keep them happy and healthy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="fifth-section-wrapper flex-center">
          <div className="vet-visits-info-wrapper">
            <h1 className="coffee-soda">Vet visits:</h1>
            <h2>PREVENTION IS THE BEST MEDICINE</h2>
            <p>
              Regular vet checkups are essential for catching any health issues
              early and keeping your pet in tip-top shape.
            </p>
            <h3>Annual Checkups</h3>
            <p>
              Most pets benefits from a yearly vet visit for a full health
              check. Your vet will check their weight, teeth, and overall
              healthy, and ensure they're up-to-date on vaccinations.
            </p>
            <h3>Vaccinations and Parasite Prevention</h3>
            <p>
              Protect your pet from harmful diseases and parasites with regular
              vaccinations, flea treatments, and heartworm preventatives.
            </p>
            <h3>Dental Care</h3>
            <p>
              Good dental hygiene is often overlooked in pets. Make sure to
              brush your pet's teeth or provide dental treats to keep their
              teeth and gums healthy.
            </p>
          </div>
          <div className="vet-visits-media-wrapper">
            <img
              src="/assets/images/footer-pages/pet-parent/vet-visit.png"
              alt=""
            />
          </div>
        </div>
        <div className="sixth-section-wrapper">
          <h1 className="coffee-soda">Keep Their Mind Sharp with</h1>
          <h1 className="coffee-soda">Mental Stimulation</h1>
          <p className="sub-head">
            A bored pet can quickly become a destructive pet! Keep their minds
            sharp and engaged <br /> with activities that challenge their
            problem-solving skills
          </p>
          <div className="skills-wrapper flex-center">
            <div className="skill-card">
              <div className="icon-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/puzzle-toys.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="skill-heading">Puzzle Toys</p>
                <p className="skill-content">
                  For dogs and cats, puzzle toys that hide treats are a great
                  way to engage their brain and reward their curiosity.
                </p>
              </div>
            </div>
            <div className="skill-card">
              <div className="icon-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/training-sessions.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="skill-heading">Training Sessions</p>
                <p className="skill-content">
                  Teaching your pet new tricks or reinforcing old commands not
                  only keeps them mentally stimulated but strengthens your bond
                  with them.
                </p>
              </div>
            </div>
            <div className="skill-card">
              <div className="icon-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/interactive-games.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="skill-heading">Interactive Games</p>
                <p className="skill-content">
                  Play hide-and-seek with your pet's favourite toy or treats,
                  and watch them sniff out the prize!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="seventh-section-wrapper">
          <h1 className="coffee-soda">Socialization and Playtime</h1>
          <p className="sub-head">
            For many pets, socialization is key to their happiness. Here's how
            to keep them socially active
          </p>
          <div className="activities-wrapper flex-center">
            <div className="activity-card flex-center">
              <div className="media-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/new-experience.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="info-head">Introduce New Experiences Slowly</p>
                <p className="skill-content">
                  Whether it's meeting new people, other pets, or exploring new
                  places, gradually introducing your pet to new experiences
                  helps prevent anxiety.
                </p>
              </div>
            </div>
            <div className="activity-card flex-center">
              <div className="media-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/playtime-fun.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="info-head">Make Playtime Fun</p>
                <p className="skill-content">
                  Regular playtime not only keeps your pet entertained but also
                  strengthens your bond. Use toys, games, or even a game of
                  fetch to keep them happy and active.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="eighth-section-wrapper">
          <h1 className="coffee-soda">Keep calm with comfort during changes</h1>
          <p className="sub-head">
            Pets can get stressed out by changes in their environment. Here's
            how to keep them
            <br /> calm during moves, new arrivals, or loud events:
          </p>
          <div className="activities-wrapper flex-center">
            <div className="activity-card flex-center">
              <div className="media-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/safe-space.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="info-head">Create a Safe Space</p>
                <p className="skill-content">
                  If your pet feels anxious, give them a quiet, safe space where
                  they can retreat when things get overwhelming.
                </p>
              </div>
            </div>
            <div className="activity-card flex-center">
              <div className="media-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/calming-aids.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="info-head">Use Calming Aids</p>
                <p className="skill-content">
                  Products like calming sprays or pet-safe CBD treats can help
                  reduce anxiety during stressful times.
                </p>
              </div>
            </div>
            <div className="activity-card flex-center">
              <div className="media-wrapper">
                <img
                  src="/assets/images/footer-pages/pet-parent/stay-patient.png"
                  alt=""
                />
              </div>
              <div className="info-wrapper">
                <p className="info-head">Stay Patient</p>
                <p className="skill-content">
                  Be patient and understanding when your pet is stressed. Your
                  calm demeanor can help reassure them during big changes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ninth-section-wrapper">
          <div className="more-advice-container flex-center">
            <div className="media-container">
              <img
                src="/assets/images/footer-pages/pet-parent/more-advice.png"
                alt=""
              />
            </div>
            <div className="info-wrapper">
              <h1 className="coffee-soda">Need More pet care advice?</h1>
              <p>
                At Woofily, we're here to help you keep your pet happy and
                healthy. Whether you're preparing for a new furry family member
                or just want to ensure your pet is living their best life, our
                community of pet lovers is full of advice and tips to help you
                along the way. Stay tuned for more pawsome pet care tips!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PetCareTipsAndAdvice;
