import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
function CommunityGuidelines() {
  return (
    <div>
      <Header/>
      <div style={{padding: "140px 110px"}}>
      <h1>Woofily Community Guidelines </h1>
      <br/>
    
      <p>Welcome to the Woofily Community! We’re excited to have you as part of our growing 
family of pet lovers. Woofily is more than just a platform for pet care—it’s a space 
where people can connect, share experiences, and celebrate their love for animals. To 
keep this community safe, inclusive, and respectful for everyone, we’ve put together a 
few guidelines. </p>
<h4>1. Be Kind and Respectful </h4>
<p>We’re a community of pet lovers, and kindness is at the heart of everything we do. 
Respect is key to maintaining a positive environment for all members. </p>
<strong>● Respect Different Opinions:</strong>
<p>Everyone has their own unique experience with pets. 
Please be respectful of differing opinions and engage in healthy, positive 
discussions. </p>
<strong>● No Bullying or Harassment:</strong>
<p>We do not tolerate any form of bullying, harassment, 
or hate speech. Offensive or abusive behaviour will result in removal from the 
community. 
</p>
<strong>● Be Supportive:</strong>
<p>Woofily is a space for encouragement and support. Whether 
someone’s sharing a proud pet moment or seeking advice, offer constructive and 
kind feedback. 
</p>
<h4>2. Keep it Safe and Secure </h4>
<p>The safety of our community members and their pets is our top priority. Help us 
maintain a secure environment by following these guidelines: </p>
<strong>● Do Not Share Personal Information:</strong>
<p> For your own privacy and security, avoid 
sharing personal information like home addresses, phone numbers, or financial 
details in public forums or messages. </p>
<strong>● Report Suspicious Behaviour:</strong>
<p>If you come across any suspicious activity, fake 
profiles, or inappropriate content, please report it immediately. Woofily takes 
security seriously, and we will investigate all reports.</p>
<strong>● Stay Within the Law:</strong>
<p>All content and behaviour in the Woofily community must 
comply with local laws and regulations. Illegal activities, including the promotion 
of unsafe pet practices, will not be tolerated.</p>
<h4>3. Appropriate Content Sharing </h4>
<p>Woofily is a fun, friendly space to share pet stories, photos, and advice. Let’s keep it that 
way! </p>
<strong>● No Explicit or Harmful Content:</strong>
<p> We do not allow the posting of graphic, harmful, 
or inappropriate content, including images or discussions that promote cruelty to 
animals. </p>
<strong>● Respectful Language:</strong>
<p> Please avoid using offensive language, explicit content, or 
any derogatory terms when communicating with others. </p>
<strong>● Pet Safety First:</strong>
<p>If you’re sharing advice or tips related to pet care, make sure 
they align with safe and ethical practices. Always prioritize the well-being of pets. </p>
<h4>4. Constructive Discussions and Feedback </h4>
<p>We encourage discussions around pet care, hosting experiences, and more. To keep the 
conversation productive: </p>
<strong>● Stay On Topic:</strong>
<p>Try to keep discussions relevant to pet care, hosting, or the topic 
at hand. Off-topic or spammy posts can disrupt the community experience.</p>
<strong>● Constructive Criticism:</strong>
<p>If you’re offering feedback, especially regarding pet care 
or services, keep it constructive and polite. Avoid personal attacks or negativity.</p>

<h4>5. No Self-Promotion or Spam </h4>
<p>We want to keep the Woofily community authentic and free from spam.</p>
<strong>● No Spamming:</strong>
<p> Refrain from posting repetitive content, irrelevant links, or 
unsolicited advertisements. </p>
<strong>● Limit Self-Promotion: </strong>
<p> If you’re a host or a pet-related business, feel free to share 
your services in the appropriate sections. However, avoid spamming or excessive 
self-promotion. </p>
<h4>6. Respect Host and Pet Parent Boundaries </h4>
<p>Woofily is built on trust, especially between hosts and pet parents. Please respect 
everyone’s boundaries to maintain that trust. </p>
<strong>● No Unsolicited Offers:</strong>
<p>Do not approach hosts or pet parents with unsolicited 
offers or services outside of the platform. </p>
<strong>● Respect Privacy:</strong>
<p>Do not share personal interactions or experiences with hosts or 
pet parents in the community without their consent. </p>
<h4>7. Reporting Violations </h4>
<p>If you see content or behavior that violates these guidelines, please report it to us. Our 
team is dedicated to ensuring a safe and positive environment for all members. </p>
<strong>● How to Report:</strong>
<p>You can report inappropriate behavior or content directly through 
the platform by clicking the report button on any post or profile. Alternatively, you 
can reach out to Woofily support via email at [support@woofily.com]. </p>
<strong>● What Happens Next:</strong>
<p>All reports are taken seriously and will be reviewed by our 
team. Depending on the severity of the violation, users may receive warnings or 
have their accounts suspended or removed.</p>
<h4>8. Consequences for Violations </h4>
<p>To maintain the integrity and safety of the Woofily community, any violations of these 
guidelines will result in appropriate action, including: </p>
<strong>● Warnings: </strong>
<p> Minor violations may result in a warning. We’ll remind you of the 
guidelines and ask you to adjust your behaviour. </p>
<strong>● Suspension or Removal:</strong>
<p> Serious or repeated violations will result in account 
suspension or permanent removal from the community. This includes any form 
of harassment, illegal activity, or abusive behaviour. </p>
<h4>9. Updates to Community Guidelines </h4>
<p>We’re always looking to improve and grow as a community. As Woofily evolves, these 
guidelines may be updated. We’ll notify you of any major changes, and by continuing to 
participate in the community, you agree to follow the updated guidelines. </p>
<h4>10. Contact Woofily Support </h4>
<p>If you have any questions about these guidelines or need help with any community-
related issues, feel free to reach out to our support team at [support@woofily.com]. 
We’re here to help</p>
<h4>Join the Woofily Community and Spread the Love!</h4>
<p>By following these guidelines, you help create a welcoming and respectful environment 
where everyone can share their love for pets. We’re so happy to have you as part of the 
Woofily family! </p>
  
    </div>  

     

    <Footer/>
    </div>
  )
}

export default CommunityGuidelines
