// import React, { useState } from 'react';
// import Header from '../../components/header/Header';
// import Footer from '../../components/footer/Footer';
// import './checkinForm.scss';
// import { useParams } from 'react-router-dom';
// import { ApiBaseUrl } from '../../../../BaseUrl';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// export default function CheckinForm() {
//   const accessToken = localStorage.getItem("ACCESS_TOKEN");
//   const { order_id,parent_id } = useParams();
//   const HOST_ID = localStorage.getItem("PARENT_ID");
//   const [formData, setFormData] = useState({
//     pet_check_in_person: '',
//     pet_check_in_date: '',
//     pet_check_in_time: '',
//     pet_check_in_file: null
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleImageChange = (e) => {
//     setFormData({
//       ...formData,
//       pet_check_in_file: e.target.files[0]
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const data = new FormData();
//     const formatTime12Hour = (time24) => {
//         let [hours, minutes] = time24.split(':');
//         const period = +hours >= 12 ? 'PM' : 'AM';
//         hours = +hours % 12 || 12; // Convert 24-hour to 12-hour format
//         return `${String(hours).padStart(2, '0')}:${minutes}:00 ${period}`;
//       };
//     const formattedTime = formatTime12Hour(formData.pet_check_in_time);
//     data.append('pet_check_in_person', formData.pet_check_in_person);
//     data.append('pet_check_in_date', formData.pet_check_in_date);
//     data.append('pet_check_in_time', formattedTime);
//     if (formData.pet_check_in_file) {
//       data.append('pet_check_in_file', formData.pet_check_in_file);
//     }
//     data.append('order_id', order_id);
//     const apiUrl = `${ApiBaseUrl}/parent/${parent_id}/${HOST_ID}/final_checkin_order`;
//     try {
//       const response = await axios.post(apiUrl, data, {
//         headers: {
//          'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       if (response.status === 200) {
//         toast.success(response.data.message); 
//       } else {
//         toast.error('Something went wrong!'); 
//       }
//     } catch (error) {
//       console.error('API error:', error);
      
//     }
//   };
  
//   return (
//     <>
//       <Header />
  
//       <section style={{ paddingTop: "100px", paddingBottom: "200px" }}>
//         <div className="checkin">
//           <div className="checkin-heading">
//             <h4>Booking Check-In</h4>
//           </div>
//           <form className="checkin-form" onSubmit={handleSubmit}>
//             <div className="form-group">
//               <label htmlFor="pet_check_in_person" >Check-in Person(optional)</label>
//               <input 
//                 type="text" 
//                 id="pet_check_in_person" 
//                 name="pet_check_in_person" 
//                 value={formData.pet_check_in_person}
//                 onChange={handleChange} 
//                 placeholder="Enter the person's name" 
//                 required 
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="pet_check_in_date">Check-in Date</label>
//               <input 
//                 type="date" 
//                 id="pet_check_in_date" 
//                 name="pet_check_in_date" 
//                 value={formData.pet_check_in_date}
//                 onChange={handleChange} 
//                 required 
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="pet_check_in_time">Check-in Time</label>
//               <input 
//                 type="time" 
//                 id="pet_check_in_time" 
//                 name="pet_check_in_time" 
//                 value={formData.pet_check_in_time}
//                 onChange={handleChange} 
//                 required 
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="pet_check_in_file">Upload Image</label>
//               <input 
//                 type="file" 
//                 id="pet_check_in_file" 
//                 name="pet_check_in_file" 
//                 accept="image/*" 
//                 onChange={handleImageChange}
//               />
//             </div>
//             <div className="form-group">
//               <button type="submit" className="save-btn">Save</button>
//             </div>
//           </form>
//         </div>
//       </section>
//       <Footer />
//     </>
//   );
// }


import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import './checkinForm.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiBaseUrl } from '../../../../BaseUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function CheckinForm() {
  const navigate=useNavigate();
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const { order_id, parent_id } = useParams();
  const HOST_ID = localStorage.getItem("PARENT_ID");

  const [formData, setFormData] = useState({
    pet_check_in_person: '',
    pet_check_in_date: '',
    pet_check_in_time: '',
    pet_check_in_file: null
  });

  // Function to get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Returns in 'YYYY-MM-DD' format
  };

  // Function to get the current time in 12-hour format
  const getCurrentTimeIn12HourFormat = () => {
    const today = new Date();
    let hours = today.getHours();
    const minutes = today.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes}:00 ${ampm}`;
  };

  // Set the current date and time in the form when the component loads
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      pet_check_in_date: getCurrentDate(),
      pet_check_in_time: getCurrentTimeIn12HourFormat(),
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      pet_check_in_file: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('pet_check_in_person', formData.pet_check_in_person);
    data.append('pet_check_in_date', formData.pet_check_in_date);
    data.append('pet_check_in_time', formData.pet_check_in_time);
    if (formData.pet_check_in_file) {
      data.append('pet_check_in_file', formData.pet_check_in_file);
    }
    data.append('order_id', order_id);

    const apiUrl = `${ApiBaseUrl}/parent/${parent_id}/${HOST_ID}/final_checkin_order`;
    try {
      const response = await axios.post(apiUrl, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/host/my-bookings");
        }, 3000);
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      console.error('API error:', error);
    }
  };

  return (
    <>
      <Header />
      <section style={{ paddingTop: "100px", paddingBottom: "200px" }}>
        <div className="checkin">
          <div className="checkin-heading">
            <h4>Booking Check-In</h4>
          </div>
          <form className="checkin-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="pet_check_in_person">Check-in Person(optional)</label>
              <input
                type="text"
                id="pet_check_in_person"
                name="pet_check_in_person"
                value={formData.pet_check_in_person}
                onChange={handleChange}
                placeholder="Enter the person's name"
               
              />
            </div>

            
            <div className="form-group">
              <label htmlFor="pet_check_in_date">Check-in Date</label>
              <input
                type="date"
                id="pet_check_in_date"
                name="pet_check_in_date"
                value={formData.pet_check_in_date}
                onChange={handleChange} 
                readOnly 
              />
            </div>

           
            <div className="form-group">
              <label htmlFor="pet_check_in_time">Check-in Time</label>
              <input
                type="text" 
                id="pet_check_in_time"
                name="pet_check_in_time"
                value={formData.pet_check_in_time}
                onChange={handleChange}
                readOnly 
              />
            </div>

            <div className="form-group">
              <label htmlFor="pet_check_in_file">Upload Image</label>
              <input
                type="file"
                id="pet_check_in_file"
                name="pet_check_in_file"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="save-btn">Save</button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}
