import React, { useEffect, useState } from "react";
import "./reviewList.scss";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
const ReviewList = () => {
  const [myBooking, setMybooking] = useState([]);
  const PARENT_ID = localStorage.getItem("PARENT_ID");
  useEffect(() => {
    mybookingapi();
  }, []);

  const mybookingapi = () => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios
      .get(`${ApiBaseUrl}/host/${PARENT_ID}/rating`, config)
      .then((response) => {
        setMybooking(response.data);
      })
      .catch((error) => {
       
        if (error.response && error.response.data && error.response.data.error) {
   
          alert(error.response.data.error);
        } else {
      
          alert("An error occurred while fetching your bookings. Please try again.");
        }
      });
  };
  // Function to format date
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-GB", options); 
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedDate} - ${formattedTime}`;
  };

   // Function to render star rating
   const renderStars = (rating) => {
    const totalStars = 5; // Total number of stars
    const stars = [];
    for (let i = 1; i <= totalStars; i++) {
      stars.push(
        <span key={i} className="star" style={{ color: i <= rating ? "gold" : "#ddd" }}>
          ★
        </span>
      );
    }
    return stars;
  };
  return (
    <>
      <section>
        <div className="mybooking_host">
          <div className="mybooking-heading">
            <h4>Reviews</h4>
          </div>
        </div>
        <div className="checkout_flex2_host">
          <div className="best-host-data">
            {myBooking.length === 0 ? (
              <p>No Cancelled Booking Found</p>
            ) : myBooking && Array.isArray(myBooking) ? (
              myBooking.map((item) => {
                return (
                  <div className="best-host-inner" key={item.id}>
                    <div className="best_host_flex1">
                      <img
                        src={`${ApiBaseUrl}${item.parent.profile_pic}`}
                        alt=""
                      />
                    </div>
                    <div className="best_host_flex2">
                      <h6>{item?.parent?.name}</h6>
                      <p>{item?.parent?.mobile}</p>
                      <p>
                        {item?.parent?.address1}
                        {item?.parent?.city}
                      </p>
                      <div className="timings">
                        <div className="start">
                          <p>
                            Review Date/Time: <span>{formatDate(item.parent.created_at)}</span>
                          </p>
                        </div>
                     
                      </div>
                      <div className="rating-comment">
                        {/* Render star rating */}
                        <div className="rating">
                          <p>Rating: {renderStars(item.rating)}</p>
                        </div>
                        {/* Display comment */}
                        <div className="comment">
                          <p>Comment: {item.comment || "No comment provided"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewList;
