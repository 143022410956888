import React from "react";
import './appDownload.scss'

const AppDownload = () => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent);
  return (
    <>
      <section className="Mob-sec">
        <div className="ovelay-up"></div>
        <div className="mob-sec-button-container">
          <div className="woofily-logo-app-sec">
            <img src="assets/images/app-download-page/woofily-logo.png" alt="" width="100px" />
          </div>
          <div className="app-download-info">
            <h1>Woofily</h1>
            <h2>Download</h2>
            <p>Our Mobile App</p>
          </div>
          <div className="app-download-button">
            {isIOS && (
              <a
                href="https://apps.apple.com/in/app/woofily/id6475004962"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="assets/images/app-download-page/app-store.png" alt="" />
              </a>
            )}

            {isAndroid && (
              <a
                href="https://play.google.com/store/apps/details?id=com.woofilyteamihallp.woofily&hl=en&gl=GB"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="assets/images/app-download-page/google-play.png" alt="" />
              </a>
            )}
          </div>
          <p className="description">
            You can download our Mobile App and enjoy our all services.
          </p>
        </div>
        <div className="dog-walker"></div>
      </section>
    </>
  );
};

export default AppDownload;
