import React, { useEffect } from "react";
import "./paymentSuccess.scss";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PaymentFailure from "./PaymentFailure";
import { ApiBaseUrl } from "../../../../BaseUrl";
import Loading from "../../components/loading/Loading";

const PaymentSuccess = () => {
  // Get the URL search parameters
  const searchParams = new URLSearchParams(window.location.search);
  // Extract payment status from the query parameters
  const paymentStatus = searchParams.get("razorpay_payment_link_status");
  const PAYMENT_ID = searchParams.get("razorpay_payment_link_id");
  const PARENT_ID = localStorage.getItem("PARENT_ID");
  const HOST_ID = localStorage.getItem("HOST_ID");
  const Amount = sessionStorage.getItem("paymentamount_aftertax");
  const Order_ID = sessionStorage.getItem("order_id");
  const navigate = useNavigate();
  // useEffect(() => {
  //   // Retrieve the access token from local storage
  //   const accessToken = localStorage.getItem("ACCESS_TOKEN");
  //   // Set the bearer token in the request headers
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   };
  //   if (paymentStatus) {
  //     axios
  //       .get(
  //         `${ApiBaseUrl}/parent/${PARENT_ID}/${HOST_ID}/orderstatus/${PAYMENT_ID}`,
  //          config
  //       )
  //       .then((res) => {
  //         const CompleteOrderData = {
  //           order_id: Order_ID,
  //           payment_id: PAYMENT_ID,
  //           razonpay_order_id: res.data.order_id,
  //           amount: Amount,
  //           status: 1,
  //         };
  //         axios.post(
  //           `${ApiBaseUrl}/parent/${PARENT_ID}/${HOST_ID}/complete_order`,
  //           CompleteOrderData,
  //           config
  //         );
  //       });
  //   }
  // }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const petCheckoutDate = sessionStorage.getItem("pet_check_out_date");
    const petCheckoutTime = sessionStorage.getItem("pet_check_out_time");
    const petCheckoutPerson = sessionStorage.getItem("pet_check_out_person");
    const orderId = sessionStorage.getItem("order_id");
    const petCheckoutFile = sessionStorage.getItem("pet_check_out_file");
  
    if (paymentStatus) {
      axios
        .get(
          `${ApiBaseUrl}/parent/${PARENT_ID}/${HOST_ID}/orderstatus/${PAYMENT_ID}`,
          config
        )
        .then((res) => {
          const CompleteOrderData = {
            order_id: Order_ID,
            payment_id: PAYMENT_ID,
            razonpay_order_id: res.data.order_id,
            amount: Amount,
            status: 1,
          };
  
          // Post complete order API
          axios
            .post(
              `${ApiBaseUrl}/parent/${PARENT_ID}/${HOST_ID}/complete_order`,
              CompleteOrderData,
              config
            )
            .then((response) => {
              // Check if final_check_out is true
              if (response.data.final_check_out === true) {
                const finalCheckoutData = new FormData();
                finalCheckoutData.append("pet_check_out_date", petCheckoutDate);
                finalCheckoutData.append("pet_check_out_time", petCheckoutTime);
                finalCheckoutData.append("pet_check_out_person", petCheckoutPerson);
                finalCheckoutData.append("order_id", orderId);
                if (petCheckoutFile) {
                  finalCheckoutData.append("pet_check_out_file", petCheckoutFile); 
                }
  
                // Post to final checkout API
                axios
                  .post(
                    `${ApiBaseUrl}/parent/${PARENT_ID}/${HOST_ID}/final_checkout`,
                    finalCheckoutData,
                    config
                  )
                  .then((finalRes) => {
                    console.log("Final checkout success:", finalRes.data);
                    navigate("/host/my-bookings")
                  })
                  .catch((error) => {
                    console.error("Final checkout error:", error);
                  });
              }
            })
            .catch((error) => {
              console.error("Complete order error:", error);
            });
        })
        .catch((error) => {
          console.error("Order status error:", error);
        });
    }
  }, []);
  
  
  return (
    <>
      <div>
        {paymentStatus === "paid" ? (
          <section className="payment_success">
            <div className="info_status">
              <img src="/assets/images/PaymentSuccessNoText.png" alt="" />
              <h1>Payment Successful!</h1>
            </div>
            <div className="navigation">
              <button
                onClick={() => {
                  navigate("/my-bookings");
                }}
              >
                My Bookings
              </button>
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                Go to Home
              </button>
            </div>
          </section>
        ) : paymentStatus === "Failed" ? (
          <PaymentFailure />
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default PaymentSuccess;
