import React from "react";
import { Link } from "react-router-dom";
import "./sideBar.scss";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2 className="sidebar-title">Simple CRM</h2>
      <ul className="menu">
        <li className="menu-item">
          <Link to="/">Dashboard</Link>
        </li>
        <li className="menu-item">
          <Link to="/tasks">Tasks</Link>
        </li>
        {/* Add other menu items here */}
      </ul>
    </div>
  );
};

export default Sidebar;

