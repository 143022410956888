import React, { useEffect, useState } from "react";
import "./filteredHosts.scss";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
// import BecomeHost from "./../componenet/Card/BecomeHost";
import axios from "axios";
import HostCard from "../../../../components/home-host-card/HostCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchFilteredHosts } from "../../../../../../redux/slices/home/FilteredHostsSlice";

const FilteredHosts = () => {
  const dispatch = useDispatch();
  const { isLoading, filteredHosts, error } = useSelector(
    (state) => state.filteredHosts
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const petType = queryParams.get("petType");
  const pet_weight = queryParams.get("pet_weight");
  const servicename = queryParams.get("servicename");
  const available_days = queryParams.get("available_days");

  useEffect(() => {
    dispatch(
      fetchFilteredHosts({
        selectedPetType: petType,
        selectedServiceName: servicename,
        selectedDays: available_days,
      })
    );
  }, [petType, servicename, available_days]);

  const [filterBarStyle, setFilterBarStyle] = useState({
    display: "none",
    bottom: "-100%",
  });

  if (isLoading) {
    return <h1 className="text-center filter_cover_image mb-5">Loading...</h1>;
  }

  if (error) {
    return <h1 className="text-center filter_cover_image mb-5">SOMETHING WENT WRONG</h1>;
  }

  return (
    <>
      {filteredHosts?.length === 0 ? (
        <div className="text-center filter_cover_image mb-5">
          {/* <img src="/image/2nd Page not found woofily.png" alt="" /> */}
          <h1>NO HOSTS FOUND</h1>
        </div>
      ) : (
        <section className="third_section">
          <div className="third_section_contianer">
            <div className="card-best-host">
              {filteredHosts?.map((card) => (
                <HostCard
                  key={card.client_id}
                  id={card.client_id}
                  name={card.name}
                  role={card.role}
                  profilePic={card.profile_pic}
                  city={card.city}
                  pricePerDay={card.amount_day}
                  pricePerHour={card.amount_hr}
                  rating={card.average_rating}
                  distance={card.distance}
                />
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default FilteredHosts;
