import React, { useEffect, useState } from "react";
import "./communityHome.scss";
import CommunitySidebar from "./Components/CommunitySidebar";
import Header from "./../hosting-module/components/header/Header";
import ComPosts from "./Components/ComPosts";
import axios from "axios";
import { ApiBaseUrl } from "../../BaseUrl";

const CommunityHome = () => {
  const [category, setCategory] = useState("");
  const loggedIn = localStorage.getItem("PARENT_ID");
  const [allPosts, setAllPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCarouselButtons, setShowCarouselButtons] = useState(false);

  useEffect(() => {
    // Update document title
    document.title = "Community - Woofily | Pet Community";

    // Update meta description tag
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute(
        "content",
        "Welcome to Woofily's pet community! Join us to connect with other pet lovers, share stories,create polls and get helpful tips for your furry friends."
      );
    }
  }, []);

  const GetAllPosts = async () => {
    if (loggedIn) {
      try {
        const response = await axios.get(
          `${ApiBaseUrl}/getallpost?user_id=${loggedIn}&category_id=${category}`
        );
        setAllPosts(response.data);
        allPosts.map((post) => {
          if (post.image_one) {
            if (post.image_one) {
              setShowCarouselButtons(false);
            } else if (
              (post.image_one && post.image_two) ||
              post.image_three ||
              post.image_four ||
              post.video_one ||
              post.video_two ||
              post.video_three ||
              post.video_four
            ) {
            }
          } else if (post.video_one) {
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axios.get(
          `${ApiBaseUrl}/getallpost?category_id=${category}`
        );
        setAllPosts(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    GetAllPosts();
  }, [category]);

  const ChangeCategory = (cat) => {
    setCategory(cat);
    setSelectedCategory(cat);
    // alert(cat)
  };
  return (
    <>
      <Header />
      <section className="community_home">
        <div className="community_home_sidebar">
          <CommunitySidebar
            ChangeCategory={ChangeCategory}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </div>
        <div className="community_home_body">
          <ComPosts GetAllPosts={GetAllPosts} allPosts={allPosts} />
        </div>
      </section>
    </>
  );
};

export default CommunityHome;
