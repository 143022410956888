import React, { useEffect } from "react";
import "./termsConditions.scss";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const TermsConditions = () => {
  useEffect(() => {
    // Update document title
    document.title = "Terms and Conditions - Woofily | Pet Boarding";

    // Update meta description tag
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute(
        "content",
        "Welcome to Woofily! When you use our services, you're agreeing to our terms and conditions. We provide loving pet boarding and hosting – visit our website for all the details!"
      );
    }
  }, []);
  return (
    <>
      <Header />
      <div className="terms_condition" style={{ paddingTop: "70px" }}>
        <div className="terms_and_policy">
          <h5>Terms and Conditions</h5>
          <p>Effective Date: 16.11.2022</p>
          <p>
            At Woofily, we value the privacy and security of our users. This
            Privacy Policy outlines the types of information we collect from our
            users and how we use, store, and protect that information. By using
            the Woofily platform, you agree to the terms and practices described
            in this policy.
          </p>
          <p className="point_of_terms">
            <b>1. Information We Collect:</b> a. Personal Information: When you
            sign up for an account with Woofily, we may collect personal
            information such as your name, email address, phone number, and
            billing information. b. Pet Information: In order to provide our
            services, we may collect information about your pet(s) including
            their names, breeds, ages, medical history, dietary requirements,
            and any other relevant details you provide. c. Communication Data:
            We may collect information related to your communication with us,
            including emails, messages, and phone call records.
          </p>
          <p className="point_of_terms">
            <b>2. Use of Information:</b> a. Service Provision: We use the
            collected information to facilitate pet boarding services, match pet
            owners with hosts, communicate with users, and ensure the safety and
            well-being of pets. b. Payment Processing: We may use your billing
            information to process payments for the services provided through
            our platform. c. Improving User Experience: We may analyze user data
            to improve our services, personalize user experiences, and develop
            new features. d. Communication: We may use your contact information
            to send you important updates, service-related notifications,
            promotional offers, and other relevant information.
          </p>
          <p className="point_of_terms">
            <b>3. Information Sharing:</b> a. Hosts: In order to connect pet
            owners with suitable hosts, we may share the necessary information
            about the pet and owner with the selected host. b. Third-Party
            Service Providers: We may engage third-party service providers to
            assist us in delivering our services. These providers are required
            to maintain the confidentiality of the information and are
            restricted from using it for any other purpose. c. Legal
            Requirements: We may disclose personal information when required to
            comply with applicable laws, regulations, legal processes, or
            enforceable governmental requests.
          </p>
          <p className="point_of_terms">
            <b>4. Data Security:</b> a. We implement reasonable security
            measures to protect the information we collect. However, please note
            that no data transmission or storage can be guaranteed to be 100%
            secure. b. We restrict access to personal information to authorized
            personnel only and ensure that our employees and service providers
            are aware of and comply with our privacy and security measures.
          </p>
          <p className="point_of_terms">
            <b>5. User Rights:</b> a. Access and Correction: You may access,
            review, and update your personal information by logging into your
            account or contacting us. b. Opt-Out: You have the right to opt-out
            of receiving promotional emails from us. However, you may still
            receive transactional or service-related communications.
          </p>
          <p className="point_of_terms">
            <b>6. Changes to the Privacy Policy:</b> a. We reserve the right to
            modify or update this Privacy Policy at any time. The revised policy
            will be effective upon posting, and we will notify users of any
            material changes. b. We encourage you to review this Privacy Policy
            periodically for the most up-to-date information.
          </p>
          <p className="point_of_terms">
            <b>7. Contact Us:</b> If you have any questions, concerns, or
            requests regarding this Privacy Policy, please contact us at{" "}
            <b> +91 7426970622 / +919873052128</b> or write a mail on{" "}
            <b>care@woofily.com</b>. By using the Woofily platform, you
            acknowledge that you have read and understood this Privacy Policy
            and consent to the collection, use, and disclosure of your
            information as described herein.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
