import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";
const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
const PARENT_ID = localStorage.getItem("PARENT_ID");
const CONFIG = {
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
};

// Action to Check if Client is Host or Parent::
export const isAllowedToHost = createAsyncThunk(
  "fetchBestHosts",
  async (CLIENT_ID) => {
    const response = await axios.get(
      `${ApiBaseUrl}/is-allowed-to-host/${CLIENT_ID}`,
      CONFIG
    );
    return response.data;
  }
);

const isAllowedToHostSlice = createSlice({
  name: "isHost",
  initialState: {
    isLoading: false,
    isHost: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(isAllowedToHost.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(isAllowedToHost.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isHost = action.payload[0].is_allowed_to_host;
    });
    builder.addCase(isAllowedToHost.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default isAllowedToHostSlice.reducer;
