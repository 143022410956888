import React, { useEffect, useState } from "react";
import "./orderDetails.scss";
import { useNavigate } from "react-router-dom";
import { DatePicker, message } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/header/Header";
import { ApiBaseUrl } from "../../../../BaseUrl";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchHostById } from "../../../../redux/slices/home/HomeHostsSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { TextField } from "@mui/material";

const OrderDetails = () => {
  const navigate = useNavigate();
  const [baseDate, setBaseDate] = useState(null);
  const [petName, setPetName] = useState("pet");
  const [bookingType, setBookingType] = useState("daily");
  const [today, setToday] = useState("");
  const [startTime, setStartTime] = useState("2024-04-17T12:00:00.000+05:30");
  const [endTime, setEndTime] = useState("2024-04-17T12:00:00.000+05:30");
  const [oneWeekLater, setOneWeekLater] = useState("");
  const handleChange = (event) => {
    setBookingType(event.target.id);
  };
  const dispatch = useDispatch();
  const { hostById, isLoading } = useSelector((state) => state.homeHosts);
  const hostId = localStorage.getItem("HOST_ID");
  const defaultStartDate = sessionStorage.getItem("CHECK_IN_DATE")
    ? dayjs(sessionStorage.getItem("CHECK_IN_DATE"), "YYYY-MM-DD")
    : today;
  const defaultEndDate = sessionStorage.getItem("CHECK_OUT_DATE")
    ? dayjs(sessionStorage.getItem("CHECK_OUT_DATE"), "YYYY-MM-DD")
    : oneWeekLater;

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const convertToIST = (timestamp) => {
    return dayjs(timestamp).tz("Asia/Kolkata").format("hh:mm:ss A");
  };

  useEffect(() => {
    dispatch(fetchHostById(hostId));
  }, []);
  const [startDate, setStartDate] = useState(
    sessionStorage.getItem("CHECK_IN_DATE")
      ? defaultStartDate.format("YYYY-MM-DD")
      : null
  );
  const [endDate, setEndDate] = useState(
    sessionStorage.getItem("CHECK_OUT_DATE")
      ? defaultEndDate.format("YYYY-MM-DD")
      : null
  );

  function formatTime(time) {
    const [hours, minutes, seconds] = time.split(":");
    let hour = parseInt(hours);
    const suffix = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    const formattedHour = hour.toString().padStart(2, "0");
    const formattedMinute = minutes.padStart(2, "0");
    const formattedSecond = seconds.padStart(2, "0");
    return `${formattedHour}:${formattedMinute}:${formattedSecond} ${suffix}`;
  }
  const [parentEditProfile, setParentEditProfile] = useState({
    name: "",
    gender: "",
    dob: "",
    mobile: "",
    email: "",
    state: "",
    city: "",
    pincode: "",
    profession: "",
    about: "",
    role: "",
    address1: "",
    address2: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const [hostprofile, setHostProfile] = useState({
    any_additional_information: "",
    end_date: "",
    end_time: "",
    email: "",
    name: "",
    phone: "",
  });

  const updateDates = () => {
    const currentDate = new Date();
    const formattedToday = currentDate.toISOString().split("T")[0];
    setToday(formattedToday);
    const nextWeekDate = new Date(currentDate);
    nextWeekDate.setDate(currentDate.getDate() + 7);
    const formattedOneWeekLater = nextWeekDate.toISOString().split("T")[0];
    setOneWeekLater(formattedOneWeekLater);
  };

  useEffect(() => {
    updateDates();
    loadUsers();
    GetPetName();
  }, []);
  const { name, email, state, city, pincode, address1, address2 } =
    parentEditProfile;

  const handlehostchange = (e) => {
    const { name, value } = e.target;

    if (name === "address1" || name === "address2") {
      setParentEditProfile((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setHostProfile((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      sessionStorage.setItem(name, value);
    }

    if (submitted) {
      if (!value) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
        }));
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));
      }
    }

    if (name === "start_time" || name === "end_time") {
      const formattedTime = `${value}:00`;

      setHostProfile((prevState) => ({
        ...prevState,
        [name]: formattedTime,
      }));
      sessionStorage.setItem(name, formattedTime);
    } else {
      setHostProfile((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      sessionStorage.setItem(name, value);
    }
  };

  const loadUsers = async () => {
    var PARENT_ID = localStorage.getItem("PARENT_ID");
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(`${ApiBaseUrl}/parent/${PARENT_ID}`, config);
    setParentEditProfile({
      name: result.data.name,
      gender: result.data.gender,
      dob: result.data.dob,
      mobile: result.data.mobile,
      email: result.data.email,
      state: result.data.state,
      city: result.data.city,
      pincode: result.data.pincode,
      profession: result.data.profession,
      about: result.data.about,
      role: result.data.role,
      address1: result.data.address1,
      address2: result.data.address2,
    });
    sessionStorage.setItem("city", result.data.city);
    sessionStorage.setItem("email", result.data.email);
    sessionStorage.setItem("name", result.data.name);
    sessionStorage.setItem("state", result.data.state);
  };

  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const GetPetName = async () => {
    var pet = sessionStorage.getItem("pet");
    var PARENT_ID = localStorage.getItem("PARENT_ID");
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(
      `${ApiBaseUrl}/parent/${PARENT_ID}/pet/${pet}`,
      config
    );
    setPetName(capitalizeWords(result.data.name));
  };

  const handleprofilecomplete = (e) => {
    const { name, value } = e.target;
    if (name === "pincode" && value.length > 6) {
      return;
    }
    sessionStorage.setItem(name, value);
    setParentEditProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlehostCompleteSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    const errors = {};
    if (!parentEditProfile.name) {
      errors.name = "Name is required";
    }
    if (!parentEditProfile.email) {
      errors.email = "Email is required";
    }
    if (!startDate) {
      errors.start_date = "Start Date is required";
    }
    if (!endDate) {
      errors.end_date = "End Date is required";
    }
    // if (!hostprofile.start_time) {
    //   errors.start_time = "Start Time is required";
    // }
    // if (!hostprofile.end_time) {
    //   errors.end_time = "End Time is required";
    // }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    const PARENT_ID = localStorage.getItem("PARENT_ID");
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const data1 = {
      name: parentEditProfile.name,
      email: parentEditProfile.email,
      state: parentEditProfile.state,
      city: parentEditProfile.city,
      address1: parentEditProfile.address1,
      pincode: parentEditProfile.pincode,
    };

    axios
      .put(`${ApiBaseUrl}/parent/${PARENT_ID}`, data1, config)
      .then((result) => {
        const Host_ID = localStorage.getItem("HOST_ID");
        const name = sessionStorage.getItem("name");
        const email = sessionStorage.getItem("email");
        const pet = sessionStorage.getItem("pet");

        const data = {
          start_date: startDate,
          end_date: endDate,
          // check_in_time: formatTime(hostprofile.start_time),
          check_in_time: convertToIST(startTime),
          check_out_time: convertToIST(endTime),
          // check_out_time: formatTime(hostprofile.end_time),
          start_time: hostprofile.start_time,
          end_time: hostprofile.end_time,
          any_additional_information:
            hostprofile.any_additional_information || "Not available.",
          pet: pet,
        };

        axios
          .post(
            bookingType === "daily"
              ? `${ApiBaseUrl}/parent/${PARENT_ID}/${Host_ID}/order_days_base`
              : `${ApiBaseUrl}/parent/${PARENT_ID}/${Host_ID}/order_hourly_base`,
            data,
            config
          )
          .then((response) => {
            if (response.data.error) {
              message.error(response.data.error);
            } else {
              sessionStorage.setItem(
                "invoice_sevice",
                response.data.Invoice.service
              );
              sessionStorage.setItem(
                "invoice_total_amount",
                response.data.Invoice.total_amount
              );
              sessionStorage.setItem("IGST", response.data.Invoice.IGST);
              sessionStorage.setItem("SGST", response.data.Invoice.SGST);
              sessionStorage.setItem("CGST", response.data.Invoice.CGST);
              sessionStorage.setItem(
                "Woofily_tax",
                response.data.Invoice.woofily_tax
              );
              sessionStorage.setItem(
                "paymentamount_aftertax",
                response.data.Invoice.total_amount
              );
              sessionStorage.setItem("payment_buyer_name", name);
              sessionStorage.setItem("payment_email", email);
              sessionStorage.setItem("order_id", response.data.data.id);
              sessionStorage.setItem(
                "start_date",
                response.data.data.start_date
              );
              sessionStorage.setItem(
                "start_time",
                response.data.data.start_time
              );
              sessionStorage.setItem("end_date", response.data.data.end_date);
              sessionStorage.setItem("end_time", response.data.data.end_time);
              sessionStorage.setItem(
                "short_url",
                response.data.payment_detail.short_url
              );
              sessionStorage.setItem("Parent_ID", response.data.data.parent);
              sessionStorage.setItem("Host_ID", response.data.data.host);
              sessionStorage.setItem("Pet_ID", response.data.data.pet);
              navigate("/order-summary");
            }
          })
          .catch((error) => {
            console.error("API request error:", error);
            if (bookingType === "daily") {
              message.error(
                "The Difference Between Drop-off and Pickup Time Should Min 24 Hour and max as per user."
              );
            } else if (bookingType === "hourly") {
              message.error(
                "The Difference Between Drop-off and Pickup Time Should Min 1 Hour and max 23 Hour."
              );
            } else {
              message.error(
                "An error occurred while submitting the form. Please try again."
              );
            }
          });
      })
      .catch((error) => {
        console.error("PUT request error:", error);
        message.error("An error occurred while updating parent information.");
      });
  };
  const availableDays = hostById?.available_days;
  const availableDates = availableDays
    ?.split(",")
    .map((date) => moment(date, "YYYY-MM-DD"));

  const disabledDates = (current) => {
    const today = moment().startOf("day");
    const isAvailable = availableDates.some((date) =>
      current.isSame(date, "day")
    );
    return current && (!isAvailable || current.isBefore(today));
  };

  const disabledEndDate = (current) => {
    const today = moment().startOf("day");
    if (baseDate) {
      return current && (current < today || current < baseDate.startOf("day"));
    }
    return current && current < today;
  };
  return (
    <>
      <Header />
      <div className="profile_edit_complete">
        <div className="profile_edit_root_complete">
          <form onSubmit={handlehostCompleteSubmit} action="">
            <div className="edit_form_data">
              <div className="booking-type-inputs">
                <div className="profile_edit_label">
                  <label htmlFor="">Booking Type:</label>
                </div>
                <div className="radio-wrappers flex-center">
                  <input
                    type="radio"
                    name="booking-type"
                    id="daily"
                    checked={bookingType === "daily"}
                    onChange={handleChange}
                  />
                  <label htmlFor="daily">Ovenight Booking</label>

                  <input
                    type="radio"
                    name="booking-type"
                    id="hourly"
                    checked={bookingType === "hourly"}
                    onChange={handleChange}
                  />
                  <label htmlFor="hourly">Hourly Booking</label>
                </div>
              </div>
            </div>

            <div className="edit_form_data">
              <div className="lab_inp">
                <div className="profile_edit_label">
                  <label htmlFor="">Name*</label>
                </div>
                <input
                  type="text"
                  placeholder=" Name"
                  name="name"
                  value={parentEditProfile.name}
                  onChange={handleprofilecomplete}
                  id=""
                />
                <div className="ps-3">
                  {validationErrors.name && (
                    <div className="error text-danger">
                      {validationErrors.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="lab_inp">
                <div className="profile_edit_label">
                  <label htmlFor="">Email*</label>
                </div>
                <input
                  type="text"
                  placeholder="Email"
                  onChange={handleprofilecomplete}
                  value={email}
                  name="email"
                  id=""
                />
                <div className="ps-3">
                  {validationErrors.email && (
                    <div className="error text-danger">
                      {validationErrors.email}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="edit_form_data">
              <div className="lab_inp">
                <div className="profile_edit_label">
                  <label htmlFor="">
                    Start Date for hosting your {petName}*
                  </label>
                </div>

                <DatePicker
                  className="datepicker"
                  defaultValue={defaultStartDate}
                  placeholder="select start date"
                  disabledDate={disabledDates}
                  onChange={(date, dateString) => {
                    setStartDate(dateString);
                    setBaseDate(dateString ? moment(dateString) : null);
                  }}
                />

                <div className="ps-3">
                  {validationErrors.start_date && (
                    <div className="error text-danger">
                      {validationErrors.start_date}
                    </div>
                  )}
                </div>
              </div>

              <div className="lab_inp">
                <div className="profile_edit_label">
                  <label htmlFor="">
                    Start Time for hosting your {petName}*
                  </label>
                </div>

                <div className="time-picker-wrapper" style={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      onChange={(newVal) => {
                        setStartTime(newVal);
                      }}
                      defaultValue={dayjs("2024-04-17T12:00:00.000+05:30")}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "transparent",
                            borderRadius: "27px",
                            width: "90%",
                            // display: "none" // Remove default border
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent",
                            borderRadius: "27px",
                            width: "90%", // Remove border on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent",
                            borderRadius: "27px",
                            width: "90%", // Remove border on focus
                          },
                        },
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>

                {/* <input
                  type="time"
                  name="start_time"
                  onChange={handlehostchange}
                  value={parentEditProfile.start_time}
                  id=""
                /> */}
                <div className="ps-3">
                  {validationErrors.start_time && (
                    <div className="error text-danger">
                      {validationErrors.start_time}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="edit_form_data">
              <div className="lab_inp">
                <div className="profile_edit_label">
                  <label htmlFor="">End Date for hosting your {petName}*</label>
                </div>

                <DatePicker
                  className="datepicker"
                  defaultValue={defaultEndDate}
                  placeholder="select end date"
                  disabledDate={disabledDates}
                  onChange={(date, dateString) => {
                    setEndDate(dateString);
                  }}
                />

                <div className="ps-3">
                  {validationErrors.end_date && (
                    <div className="error text-danger">
                      {validationErrors.end_date}
                    </div>
                  )}
                </div>
              </div>

              <div className="lab_inp">
                <div className="profile_edit_label">
                  <label htmlFor="">End Time for hosting your {petName}*</label>
                </div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    onChange={(newVal) => {
                      setEndTime(newVal);
                    }}
                    defaultValue={dayjs("2024-04-17T12:00:00.000+05:30")}
                    sx={{
                      width: '100%',
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                          borderRadius: "27px",
                          width: "90%",
                          // display: "none" // Remove default border
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                          borderRadius: "27px",
                          width: "90%", // Remove border on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                          borderRadius: "27px",
                          width: "90%", // Remove border on focus
                        },
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                {/* <input
                  type="time"
                  name="end_time"
                  onChange={handlehostchange}
                  value={parentEditProfile.end_time}
                  id=""
                /> */}
                <div className="ps-3">
                  {validationErrors.end_time && (
                    <div className="error text-danger">
                      {validationErrors.end_time}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="edit_form_data">
              <div className="lab_inp">
                <div className="profile_edit_label">
                  <label htmlFor="">Address 1 *</label>
                </div>

                <input
                  type="text"
                  placeholder="Enter Address 1"
                  onChange={handlehostchange}
                  value={address1}
                  name="address1"
                  id=""
                />
              </div>
              <div className="lab_inp">
                <div className="profile_edit_label">
                  <label htmlFor="">Address 2</label>
                </div>

                <input
                  type="text"
                  placeholder="Enter Address 2"
                  name="address2"
                  onChange={handlehostchange}
                  value={address2}
                  id=""
                />
              </div>
            </div>

            <div className="edit_form_data">
              <div className="lab_inp threewidth">
                <div className="profile_edit_label">
                  <label htmlFor="">Pincode*</label>
                </div>

                <input
                  type="number"
                  placeholder="Pincode"
                  onChange={handleprofilecomplete}
                  value={pincode}
                  name="pincode"
                  id=""
                  maxLength={6}
                />

                <div className="ps-3">
                  {pincode.length > 0 && pincode.length !== 6 && (
                    <div className="error text-danger">
                      Enter a valid pincode.
                    </div>
                  )}
                  {validationErrors.pincode && (
                    <div className="error text-danger">
                      {validationErrors.pincode}
                    </div>
                  )}
                </div>
              </div>

              <div className="lab_inp threewidth">
                <div className="profile_edit_label">
                  <label htmlFor="">City*</label>
                </div>

                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  onChange={handleprofilecomplete}
                  value={city}
                  id=""
                />
                <div className="ps-3">
                  {validationErrors.city && (
                    <div className="error text-danger">
                      {validationErrors.city}
                    </div>
                  )}
                </div>
              </div>
              <div className="lab_inp threewidth">
                <div className="profile_edit_label">
                  <label htmlFor="">State*</label>
                </div>

                <input
                  type="text"
                  placeholder="State"
                  name="state"
                  onChange={handleprofilecomplete}
                  value={state}
                  id=""
                />
                <div className="ps-3">
                  {validationErrors.state && (
                    <div className="error text-danger">
                      {validationErrors.state}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="edit_form_data">
              <div className="lab_inp fullwidth">
                <div className="profile_edit_label">
                  <label htmlFor="">
                    Any additional information you would like to share with us
                    that will help find the most suitable host ?{" "}
                  </label>
                </div>
                <div className="order_summary_para">
                  {" "}
                  <h6>
                    {" "}
                    We would love to know if your {petName} loves drives ,
                    massages, smelling flowers, etc.
                  </h6>
                </div>

                <textarea
                  type="text"
                  rows={4}
                  placeholder={`Write something about your ${petName}`}
                  className="text_area"
                  onChange={handlehostchange}
                  value={parentEditProfile.any_additional_information}
                  name="any_additional_information"
                  id=""
                />
              </div>
            </div>

            <div className="edit_submit_profile_button text-center">
              <button type="submit" className=" text-center">
                {" "}
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;