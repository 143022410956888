import React, { useState } from "react";
import "./updatePet.scss";
import Header from "../../components/header/Header";
import UpdatePetFirstStep from "./steps/UpdatePetFirstStep";
import UpdatePetSecondStep from "./steps/UpdatePetSecondStep";

const UpdatePet = () => {
  const [prevBtnShow, setPrevBtnShow] = useState({
    display: "none",
  });
  const [adoptDate, setAdoptionDate] = useState(null);
  const [petformvalues, setPetformValues] = useState({
    name: "",
    adoption_date: "",
    gender: "",
    pet_type: "",
    pet_nature: "",
    pet_neutered: "",
    pet_weight: "",
    pet_meal_type: "",
    pet_mealtime_morning: "",
    pet_mealtime_afternoon: "",
    pet_mealtime_evening: "",
    pet_walk_morning: "",
    pet_walk_evening: "",
    pet_friendly: "",
    pet_friendly_Kids: "",
    pet_pottytrained: "",
    pet_healthissue: "0",
    pet_medications: "",
    any_medication: "",
    pet_bread: "",
    additional_info: "",
  });
  const formTitles = ["", ""];

  const [petTypeError, setPetTypeError] = useState("");
  const [nameError, setNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [adoptionDataError, setAdoptionDateError] = useState("");
  const [breedError, setBreedError] = useState("");
  const [petMealTypeError, setMealTypeError] = useState("");
  const [petweightError, setPetWeightError] = useState("");
  const [petNatureError, setPetNatureError] = useState("");
  const [petwalkError, setPetwalkError] = useState("");
  const [mealTimeError, setMealTimeError] = useState("");
  const [petNeuteredError, setPetNeuteredError] = useState("");
  const [petFriendlyError, setPetFriendlyError] = useState("");
  const [kidsFriendlyError, setKidsFriendlyError] = useState("");
  const [pottyTrainedError, setPottyTrainedError] = useState("");

  const [pages, setPages] = useState(0);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    if (pages === 0) {
      if (!petformvalues.pet_type.trim()) {
        setPetTypeError("Pet Type is required");
        isValid = false;
      } else {
        setPetTypeError("");
      }

      if (!petformvalues.name.trim()) {
        setNameError("Name is required");
        isValid = false;
      } else {
        setNameError("");
      }

      if (!petformvalues.gender.trim()) {
        setGenderError("Gender is required");
        isValid = false;
      } else {
        setGenderError("");
      }

      if (petformvalues.adoption_date === "") {
        setAdoptionDateError("Birth Date is required");
        isValid = false;
      } else {
        setAdoptionDateError("");
      }

      if (!petformvalues.pet_bread.trim()) {
        setBreedError("Pet Breed is required");
        isValid = false;
      } else {
        setBreedError("");
      }

      if (!petformvalues.pet_meal_type.trim()) {
        setMealTypeError("Meal Type is required");
        isValid = false;
      } else {
        setMealTypeError("");
      }

      if (!petformvalues.pet_weight.trim()) {
        setPetWeightError("Pet Stage is required");
        isValid = false;
      } else {
        setPetWeightError("");
      }

      if (!petformvalues.pet_nature.trim()) {
        setPetNatureError("Pet Nature is required");
        isValid = false;
      } else {
        setPetNatureError("");
      }

      if (!petformvalues.pet_walk_evening.trim()) {
        setPetwalkError("Pet Walk is required");
        isValid = false;
      } else {
        setPetwalkError("");
      }

      if (!petformvalues.pet_mealtime_afternoon.trim()) {
        setMealTimeError("Meal Time is required");
        isValid = false;
      } else {
        setMealTimeError("");
      }

      if (petformvalues.pet_neutered === "") {
        setPetNeuteredError("This field is required");
        isValid = false;
      } else {
        setPetNeuteredError("");
      }

      if (petformvalues.pet_friendly === "") {
        setPetFriendlyError("This field is required");
        isValid = false;
      } else {
        setPetFriendlyError("");
      }

      if (petformvalues.pet_friendly_Kids === "") {
        setKidsFriendlyError("This field is required");
        isValid = false;
      } else {
        setKidsFriendlyError("");
      }

      if (petformvalues.pet_pottytrained === "") {
        setPottyTrainedError("This field is required");
        isValid = false;
      } else {
        setPottyTrainedError("");
      }
    }

    if (isValid) {
      setPages((prevPage) => prevPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const pageDisplay = () => {
    if (pages === 0) {
      return (
        <UpdatePetFirstStep
          nameError={nameError}
          adoptionDataError={adoptionDataError}
          genderError={genderError}
          petTypeError={petTypeError}
          petNatureError={petNatureError}
          breedError={breedError}
          petweightError={petweightError}
          petMealTypeError={petMealTypeError}
          mealTimeError={mealTimeError}
          petwalkError={petwalkError}
          petNeuteredError={petNeuteredError}
          setPetNeuteredError={setPetNeuteredError}
          petformvalues={petformvalues}
          setPetformValues={setPetformValues}
          adoptDate={adoptDate}
          setAdoptionDate={setAdoptionDate}
          setAdoptionDateError={setAdoptionDateError}
          petFriendlyError={petFriendlyError}
          setPetFriendlyError={setPetFriendlyError}
          kidsFriendlyError={kidsFriendlyError}
          setKidsFriendlyError={setKidsFriendlyError}
          pottyTrainedError={pottyTrainedError}
          setPottyTrainedError={setPottyTrainedError}
        />
      );
    } else if (pages === 1) {
      return (
        <UpdatePetSecondStep
          petformvalues={petformvalues}
          setPetformValues={setPetformValues}
          adoptDate={adoptDate}
          setAdoptionDate={setAdoptionDate}
        />
      );
    }
  };

  return (
    <>
      <Header />

      {/* <div className="page">
        <heading>
          <h1>Add Your pet</h1>
          <hr />
        </heading>
      </div> */}

      <div className="petform ">
        <div className="petform_root">
          <div> {pageDisplay()}</div>

          <div className="petform_root_btn_rev">
            {pages === 0 ? (
              ""
            ) : (
              <button
                onClick={() => {
                  setPages((currPagr) => currPagr - 1);
                }}
              >
                Previous Page
              </button>
            )}

            {pages !== 1 && (
              <button
                disabled={pages === formTitles.length - 1}
                onClick={handleFormSubmit}
              >
                Next Page
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePet;
