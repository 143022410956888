import React, { useState } from "react";
import "./comments.scss";
import { RxCross2 } from "react-icons/rx";
import { FaRegComment } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiBaseUrl } from "../../../BaseUrl";
import axios from "axios";

const Comments = ({ PostData }) => {
  const loggedIn = localStorage.getItem("PARENT_ID");
  const navigate = useNavigate();
  const [commentText, setCommentText] = useState("");
  const [commentReplyText, setCommentReplyText] = useState("");
  const [commentStyle, setCommentStyle] = useState({
    bottom: "-90%",
  });
  const [allComments, setAllComments] = useState([]);
  const [openComments, setOpenComments] = useState([]);
  const [openReplies, setOpenReplies] = useState([]);
  const [allReplies, setAllReplies] = useState([]);
  const [replies, setReplies] = useState([]);
  const handleCommentToggle = (postId) => {
    if (openComments.includes(postId)) {
      // If comments are already open, close them
      setOpenComments(openComments.filter((id) => id !== postId));
    } else {
      // If comments are not open yet, open them
      setOpenComments([...openComments, postId]);
    }
  };
  const handleAllReplyToggle = (commentId) => {
    if (allReplies.includes(commentId)) {
      // If comments are already open, close them
      setAllReplies(allReplies.filter((id) => id !== commentId));
    } else {
      // If comments are not open yet, open them
      setAllReplies([...allReplies, commentId]);
    }
  };

  const handleReplyToggle = (postId) => {
    if (openReplies.includes(postId)) {
      // If comments are already open, close them
      setOpenReplies(openReplies.filter((id) => id !== postId));
    } else {
      // If comments are not open yet, open them
      setOpenReplies([...openReplies, postId]);
    }
  };

  const GetComments = async (Post_Id) => {
    if (loggedIn) {
      // handleCommentToggle(Post_Id);
      try {
        const response = await axios.get(`${ApiBaseUrl}/comment/${Post_Id}`);
        setAllComments(response.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };

  const GetAllReplies = async (Comment_Id) => {
    if (loggedIn) {
      try {
        const response = await axios.get(`${ApiBaseUrl}/comment/${Comment_Id}/reply`);
        setReplies(response.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };

  const OnComment = (postId) => {
    if (loggedIn) {
      const commentData = {
        client: loggedIn,
        post: postId,
        text: commentText,
      };
      axios
        .post(`${ApiBaseUrl}/comment`, commentData)
        .then((response) => {
          // Handle successful comment submission if needed
          if (response.data.error) {
            toast.error(response.data.error, {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            setCommentText(""); // Clear the comment input field
            GetComments(postId);
          }
        })
        .catch((error) => {
          // Handle errors if any
          alert("Error submitting comment:", error);
        });
    } else {
      navigate("/login");
    }
  };

  const OnCommentReply = (commentId) => {
    if (loggedIn) {
      const commentReplyData = {
        client: loggedIn,
        comment: commentId,
        text: commentReplyText,
      };
      axios
        .post(`${ApiBaseUrl}/reply`, commentReplyData)
        .then((response) => {
            // alert(response.status)
            setCommentReplyText(""); // Clear the comment input field
            handleReplyToggle(commentId);
          if (response.status === 400) {
            toast.error(response.data.error, {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            setCommentReplyText(""); // Clear the comment input field
            handleReplyToggle(commentId);
          }
        })
        .catch((error) => {

            if (error.response && error.response.status === 400) {
                // Handle 400 error
                toast.error(error.response.data, {
                    position: "top-center",
                    autoClose: 2000,
                  });
              } else {
                // Handle other errors
                console.error('Error:', error);
              }


          // Handle errors if any
          console.error("Error submitting comment:", error);
          toast.error(error, {
            position: "top-center",
            autoClose: 2000,
          });
        });
    } else {
      navigate("/login");
    }
  };

  const handleCommentSubmit = (postId, event) => {
    event.preventDefault(); // Prevent form submission

    // Call the OnComment function with the postId
    OnComment(postId);
  };

  const handleCommentReplySubmit = (commentId, event) => {
    event.preventDefault(); // Prevent form submission

    // Call the OnComment function with the postId
    OnCommentReply(commentId);
  };
  return (
    <>
      <div className="comment">
        <FaRegComment
          className="action_icon"
          onClick={() => {
            GetComments(PostData.id);
            handleCommentToggle(PostData.id);
          }}
        />
        <div
          className="commenter_container"
          style={
            openComments.includes(PostData.id)
              ? { bottom: "0%" }
              : { bottom: "-90%" }
          }
        >
          <div className="header_cls_btn">
            <p>Comments:</p>
            <RxCross2
              className="icon"
              onClick={() => handleCommentToggle(PostData.id)}
            />
          </div>
          <div className="comments_wrapper">
            {allComments.map((item, index) => (
              <div key={index} className="single_comment">
                <div className="single_comment_contents">
                  <img
                    src={`${ApiBaseUrl}${item.client_profile_pic}`}
                    alt=""
                  />
                  <div className="name_nd_comment">
                    <p className="commenter_name">{item.client_name}</p>
                    <p className="text_cmt">{item.text}</p>
                  </div>
                </div>
                <div className="single_comment_actions">
                  {/* <p className="replied_on">4d</p> */}
                  {/* <p className="all_likes">5Likes</p> */}
                  <p
                    className="reply_comment"
                    onClick={() => handleReplyToggle(item.id)}
                  >
                    Reply
                  </p>
                  {/* <p className="report_comment">Report</p> */}
                </div>
                <form
                  className="post_reply"
                  onSubmit={(event) => handleCommentReplySubmit(item.id, event)}
                  style={
                    openReplies.includes(item.id)
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                >
                  <input
                    type="text"
                    placeholder="Type your reply"
                    value={commentReplyText}
                    onChange={(event) =>
                      setCommentReplyText(event.target.value)
                    }
                  />
                  <input type="submit" value="Reply" />
                </form>
                {/* Comment Reply Container */}
                <div className="all_reply_container">
                  {/* {replies.length !== 0 && ( */}
                  <p
                    className="view_all_replies_btn"
                    onClick={() => {
                      handleAllReplyToggle(item.id);
                      GetAllReplies(item.id);
                    }}
                  >
                    {allReplies.includes(item.id) ? "Hide" : "View"} all replies
                  </p>
                  {/* )} */}
                  <div
                    className="single_reply"
                    style={
                      allReplies.includes(item.id)
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  >
                    {replies.map((reply, replyIndex) => (
                      <div key={replyIndex} className="single_comment_contents">
                        <img
                          src={`${ApiBaseUrl}${reply.client_profile_pic}`}
                          alt=""
                        />
                        <div className="name_nd_comment">
                          <p className="commenter_name">{reply.client_name}</p>
                          <p className="text_cmt">{reply.text}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <form
            className="post_comment"
            onSubmit={(event) => handleCommentSubmit(PostData.id, event)}
          >
            <input
              type="text"
              placeholder="Add a comment"
              value={commentText}
              onChange={(event) => setCommentText(event.target.value)}
            />
            <input type="submit" value="Post" />
          </form>
        </div>
      </div>
    </>
  );
};

export default Comments;
