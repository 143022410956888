import React from "react";
import SummaryCard from "./summaryCard";
import "./crmDashboard.scss";


import Sidebar from "./sideBar";


const CrmDashboard = () => {
  const summaryData = [
    { title: "Leads", count: 120 },
    { title: "Tasks", count: 45 },
    { title: "Users", count: 32 },
  ];

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="dashboard">
        <h1 className="dashboard-title">Dashboard</h1>
        <div className="summary-container">
          {summaryData.map((item, index) => (
            <SummaryCard key={index} title={item.title} count={item.count} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CrmDashboard;
