import React, { useState } from "react";
import "./like.scss";
import { RxCross2 } from "react-icons/rx";
import { IoMdHeartEmpty } from "react-icons/io";
import { FcLike } from "react-icons/fc";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ApiBaseUrl } from "../../../BaseUrl";
import axios from "axios";

const Like = ({ PostData, GetLikeCount }) => {
  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("PARENT_ID");
  const [Likers, setLikers] = useState([]);
  const [Likes, setLikes] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [likeStyle, setLikeStyle] = useState({
    bottom: "-90%",
  });
  const [openLikes, setOpenLikes] = useState([]);
  const [like, setLike] = useState([]);

  const handleLikesToggle = (postId) => {
    if (openLikes.includes(postId)) {
      setOpenLikes(openLikes.filter((id) => id !== postId));
    } else {
      setOpenLikes([...openLikes, postId]);
    }
  };

  const GetLikes = async (Post_Id) => {
    if (loggedIn) {
      handleLikesToggle(Post_Id);
      try {
        const response = await axios.get(`${ApiBaseUrl}/like/${Post_Id}`);
        setLikes(response.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };

  const OnLike = async (postId) => {
    if (loggedIn) {
      const likeData = {
        client: loggedIn,
        post: postId,
      };
      try {
        const response = await axios.post(`${ApiBaseUrl}/like`, likeData);
        if (isLiked === false) {
          setIsLiked(true);
        } else {
          setIsLiked(false);
        }
        GetLikeCount();
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <div className="like">
        {PostData.liked_by_user ? (
          <FcLike className="action_icon" onClick={() => OnLike(PostData.id)} />
        ) : !isLiked ? (
          <IoMdHeartEmpty
            className="action_icon"
            onClick={() => OnLike(PostData.id)}
          />
        ) : (
          <FcLike className="action_icon" onClick={() => OnLike(PostData.id)} />
        )}

        {/* {!isLiked ? (
          <IoMdHeartEmpty
            className="action_icon"
            onClick={() => OnLike(PostData.id)}
          />
        ) : (
          <FcLike className="action_icon" onClick={() => OnLike(PostData.id)} />
        )} */}

        <span
          onClick={() => GetLikes(PostData.id)}
        >{`${PostData.likes} Likes`}</span>
        <div
          className="liker_container"
          style={
            openLikes.includes(PostData.id)
              ? { bottom: "0%" }
              : { bottom: "-90%" }
          }
        >
          <div className="header_cls_btn">
            <p>Liked by:</p>
            <RxCross2
              className="icon"
              onClick={() => handleLikesToggle(PostData.id)}
            />
          </div>
          <ul>
            {Likes.length === 0 ? (
              <li>No one liked the post yet.</li>
            ) : (
              <ul>
                {Likes.map((item, index) => (
                  <li key={index}>
                    <img
                      src={`${ApiBaseUrl}${item.client_profile_pic}`}
                      alt=""
                    />
                    <span>{item.client_name}</span>
                  </li>
                ))}
              </ul>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Like;
