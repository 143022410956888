import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function PetParentPrivacyPolicy() {
  return (
    <>
    <Header/>
    <div  style={{padding: "140px 110px"}} >
    <h1>Pet Parent’s Privacy Policy</h1><br/>
    <p>At Woofily, your privacy and the safety of your personal information are very important to us. This Privacy Policy outlines how we collect, use, and protect your data as a pet parent when you use our platform. By signing up and using Woofily’s services, you agree to the practices outlined below.</p>

    <h3>1. Information We Collect</h3>
    <p>To provide the best experience for you and your pet, Woofily collects certain personal information when you sign up and use our services. This includes:</p>
    <ul>
        <li><strong>Account Information:</strong> When you create a Woofily account, we collect details like your name, email address, phone number, and other contact information.</li>
        <li><strong>Pet Information:</strong> We collect information about your pet, including their name, breed, age, medical conditions, and any special care instructions you provide.</li>
        <li><strong>Payment Information:</strong> When you make a booking, we collect payment details, such as credit card information, through secure third-party payment processors.</li>
        <li><strong>Communications:</strong> Any messages or communications exchanged between you and the host through Woofily’s platform are stored to ensure the safety and reliability of our services.</li>
        <li><strong>Location Information:</strong> We may collect information about your location to help match you with hosts near you.</li>
        <li><strong>Usage Information:</strong> We track how you use the platform, including pages visited, services booked, and other interactions with Woofily.</li>
    </ul>

    <h3>2. How We Use Your Information</h3>
    <p>Woofily uses your information to provide, improve, and personalize our services, including:</p>
    <ul>
        <li><strong>Booking Management:</strong> We use your personal and pet information to facilitate bookings, ensure proper care, and provide hosts with the necessary details about your pet.</li>
        <li><strong>Communication:</strong> Your contact information is used to send you booking confirmations, updates, and other important notifications related to your pet’s stay.</li>
        <li><strong>Payment Processing:</strong> We securely process your payment information to handle transactions, manage refunds, and ensure payment security.</li>
        <li><strong>Improving Our Services:</strong> We use your feedback and usage data to improve the platform and enhance the services we offer to you and other pet parents.</li>
        <li><strong>Safety and Security:</strong> Information collected through communications or during booking is used to maintain a safe and trustworthy environment for both pet parents and hosts.</li>
    </ul>

    <h3>3. How We Protect Your Information</h3>
    <p>Woofily takes the security of your personal information very seriously. We use industry-standard measures to protect your data from unauthorized access, misuse, or loss. These include:</p>
    <ul>
        <li><strong>Encryption:</strong> Sensitive information, like payment details, is encrypted during transmission using SSL (Secure Socket Layer) technology.</li>
        <li><strong>Access Control:</strong> Access to your personal data is restricted to authorized personnel only, and we regularly review security practices to protect your information.</li>
        <li><strong>Secure Payment Processing:</strong> Woofily does not store your payment information directly. We use secure third-party payment processors to handle all transactions.</li>
    </ul>

    <h3>4. Sharing Your Information</h3>
    <p>Woofily respects your privacy and will not sell or share your personal information with third parties except under the following circumstances:</p>
    <ul>
        <li><strong>With Hosts:</strong> Your information, including details about your pet, will be shared with the host to ensure they can provide proper care during your pet’s stay.</li>
        <li><strong>Payment Processors:</strong> We share payment information with secure third-party payment processors to facilitate transactions.</li>
        <li><strong>Legal Requirements:</strong> Woofily may disclose your information if required by law, such as in response to a court order, government request, or legal investigation.</li>
        <li><strong>Platform Providers:</strong> We may share anonymized data with service providers that help us operate our platform, such as website hosting services or analytics providers, but this data cannot identify you personally.</li>
    </ul>

    <h3>5. Your Rights</h3>
    <p>As a pet parent using Woofily, you have the following rights regarding your personal information:</p>
    <ul>
        <li><strong>Access Your Data:</strong> You can request a copy of the personal data we hold about you at any time.</li>
        <li><strong>Update Your Information:</strong> You can update or correct your personal information in your Woofily account settings.</li>
        <li><strong>Delete Your Account:</strong> You can request to delete your account and associated personal information at any time, though certain data may be retained for legal or security reasons.</li>
        <li><strong>Opt-Out of Communications:</strong> You can choose to opt out of promotional emails or notifications by updating your communication preferences in your account settings.</li>
    </ul>

    <h3>6. Cookies and Tracking</h3>
    <p>Woofily uses cookies and similar technologies to enhance your experience on our platform. Cookies help us understand how you interact with our website, personalize content, and remember your preferences. You can manage your cookie settings in your browser.</p>
    <ul>
        <li><strong>Essential Cookies:</strong> These are necessary for the platform to function properly, such as for login and security purposes.</li>
        <li><strong>Analytics Cookies:</strong> These help us understand user behavior and improve the website’s functionality.</li>
        <li><strong>Advertising Cookies:</strong> These are used to show you relevant Woofily ads across other websites.</li>
    </ul>
    <p>You can choose to block or delete cookies through your browser settings, though some features of the platform may be impacted.</p>

    <h3>7. Changes to This Privacy Policy</h3>
    <p>Woofily may update this Privacy Policy from time to time to reflect changes in our services or legal requirements. You will be notified of any significant changes via email or through a notification on the platform. Continued use of Woofily after updates to the policy indicates your acceptance of the changes.</p>

    <h3>8. Contact Us</h3>
    <p>If you have any questions or concerns about this Privacy Policy or how your information is handled, please reach out to our support team at <a href="mailto:support@woofily.com">support@woofily.com</a>.</p>

    <p><strong>Woofily: Protecting Your Privacy</strong></p>
    <p>At Woofily, we are committed to protecting your personal information and ensuring that you have a safe and enjoyable experience on our platform. Your trust is our priority, and we’re here to safeguard your privacy at every step of your journey with us.</p>
</div>

    <Footer/>
      
    </>
  )
}

export default PetParentPrivacyPolicy
