import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <section className="footer-section-wrapper">
        <div className="footer-top-section flex-center">
          <ul>
            <li>Woofily</li>
            <li>
              <Link to="/our-story">Our Story</Link>
            </li>
            <li>
              <Link to="/woofily-commitments">Woofily Commitments</Link>
            </li>
            <li>
              <Link to="/">Trust & Safety at Woofily</Link>
            </li>
            <li>
              <Link to="/">Whats's New at Woofily</Link>
            </li>
            <li>
              <Link to="/blogs">Blog & Resources</Link>
            </li>
            <li>
              <Link to="/">Join Woofily !!</Link>
            </li>
            <li>
              <Link to="/">Press and Media</Link>
            </li>
          </ul>

          <ul>
            <li>Pet Host</li>
            <li>
              <Link to="/become-a-pet-host">Become a Pet Host</Link>
            </li>
            <li>
              <Link to="/become-a-verified-pet-host">
                How to Become a Verified Pet Host
              </Link>
            </li>
            <li>
              <Link to="/host-dedicated-support">
                Host Dedicated Support
              </Link>
            </li>
            <li>
              <Link to="/pet-hosting-guidelines">Pet Hosting Guidelines</Link>
            </li>
            <li>
              <Link to="/safety-and-care-tips-for-pet-hosts">
                Safety and Care Tips for Pet Hosts
              </Link>
            </li>
            <li>
              <Link to="/host-terms-and-conditions">Host Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/Woofily-host-FAQs">
                Frequently Asked Questions for Hosts
              </Link>
            </li>
          </ul>
          <ul>
            <li>Pet Parent</li>
            <li>
              <Link to="/">Find a Trusted Pet Host</Link>
            </li>
            <li>
              <Link to="/how-it-works-for-pet-parent">
                How It Works for Pet Parents
              </Link>
            </li>
            <li>
              <Link to="/pet-parent-support">Pet Parent Support</Link>
            </li>
            <li>
              <Link to="/preparing-your-pet-for-boarding">
                Preparing Your Pet for Boarding
              </Link>
            </li>
            <li>
              <Link to="/pet-care-tips-and-advice">Pet Care Tips and Advice</Link>
            </li>
            <li>
              <Link to="/Woofily-parent-FAQs">
                Frequently Asked Questions for Pet Parent
              </Link>
            </li>
            <li>
              <Link to="/pet-parent-privacy-policy">Pet Parent Privacy Policy</Link>
            </li>
          </ul>
          <ul>
            <li>Community</li>
            <li>
              <Link to="/join-the-Woofily-Host-Community">
                Join the Woofily Host Community
              </Link>
            </li>
            <li>
              <Link to="/">Pet Parent Stories</Link>
            </li>
            <li>
              <Link to="/">Pet Host Testimonials</Link>
            </li>
            <li>
              <Link to="/">Follow Us on Instagram</Link>
            </li>
            <li>
              <Link to="/">Pet Events & Partnerships</Link>
            </li>
            <li>
              <Link to="/">Referral Program</Link>
            </li>
            <li>
              <Link to="/">Woofily Newsletter</Link>
            </li>
          </ul>
          <ul>
            <li> Woofily Support</li>
            <li>
              <Link to="/">Help Center</Link>
            </li>
            <li>
              <Link to="/">Contact Support</Link>
            </li>
            <li>
              <Link to="/">Live Chat Support</Link>
            </li>
            <li>
              <Link to="/">Pet Parent Support Resources</Link>
            </li>
            <li>
              <Link to="/">Host Support Resources</Link>
            </li>
            <li>
              <Link to="/">Safety & Trust Policies</Link>
            </li>
            <li>
              <Link to="/">Report an Issue</Link>
            </li>
          </ul>
        </div>
        <div className="mobile-extended-footer flex-center">
          <p className="privacy flex-center">
            <BsDot className="dot-icon" />
            <Link to="/privacy-and-policy">Privacy & Policy</Link>
          </p>
          <p className="terms flex-center">
            <BsDot className="dot-icon" />
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </p>
        </div>
        <div className="footer-bottom-section flex-center">
          <div className="bottom-footer-left-section flex-center">
            <p className="copywright">© 2024 Woofily Private Limited</p>
            <p className="privacy flex-center">
              <BsDot className="dot-icon" />
              <Link to="/privacy-and-policy">Privacy & Policy</Link>
            </p>
            <p className="terms flex-center">
              <BsDot className="dot-icon" />
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </p>
          </div>
          <div className="bottom-footer-right-section flex-center">
            <div className="social-icon instagram flex-center">
              <Link
                to="https://www.instagram.com/woof.ily/?igsh=ZG10NTJmazBhem1l"
                target="blank"
              >
                <FaInstagram className="icon" />
              </Link>
            </div>
            <div className="social-icon facebook flex-center">
              <Link
                to="https://www.facebook.com/Woofily?mibextid=LQQJ4d"
                target="blank"
              >
                <FaFacebookF className="icon" />
              </Link>
            </div>
            <div className="social-icon linkedin flex-center">
              <Link
                to="https://www.linkedin.com/company/woof-ily/"
                target="blank"
              >
                <FaLinkedinIn className="icon" />
              </Link>
            </div>
            {/* <div className="social-icon twitter flex-center">
              <Link to="/">
                <FaXTwitter className="icon" />
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
