
import React, { useEffect, useRef, useState } from "react";
import "./hostProfile.scss";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchHostById } from "../../../../../redux/slices/home/HomeHostsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../../../../BaseUrl";
import Loading from "../../../components/loading/Loading";
import { DatePicker, Image } from "antd";
import moment from "moment";

const ProfileHost = () => {
  const [isthisLoading, setIsLoading] = useState(false);
  const [baseDate, setBaseDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [today, setToday] = useState("");
  const [oneWeekLater, setOneWeekLater] = useState("");
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const handleMonthChange = (date) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };
  const dispatch = useDispatch();
  const { hostById, isLoading } = useSelector((state) => state.homeHosts);
  const { hostId } = useParams();
  const host = hostById;
  const navigate = useNavigate();
  const HOST_PROFILE_FALLBACK_IMAGE = "";
  const HOST_COVER_FALLBACK_IMAGE = "";
  const defaultStartDate = sessionStorage.getItem("CHECK_IN_DATE")
    ? dayjs(sessionStorage.getItem("CHECK_IN_DATE"), "YYYY-MM-DD")
    : today;
  const defaultEndDate = sessionStorage.getItem("CHECK_OUT_DATE")
    ? dayjs(sessionStorage.getItem("CHECK_OUT_DATE"), "YYYY-MM-DD")
    : oneWeekLater;

  const BookNowFunc = () => {
    localStorage.setItem("HOST_ID", hostId);
    navigate("/select-pet-to-host");
  };

  const calculateAge = (dobString) => {
    const dob = new Date(dobString);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  const updateDates = () => {
    // Get today's date
    const currentDate = new Date();

    // Format today's date as YYYY-MM-DD
    const formattedToday = currentDate.toISOString().split("T")[0];
    setToday(formattedToday);

    // Get the date one week later
    const nextWeekDate = new Date(currentDate);
    nextWeekDate.setDate(currentDate.getDate() + 7); // Add 7 days to today's date

    // Format the date one week later as YYYY-MM-DD
    const formattedOneWeekLater = nextWeekDate.toISOString().split("T")[0];
    setOneWeekLater(formattedOneWeekLater);
  };

  useEffect(() => {
    updateDates();
    dispatch(fetchHostById(hostId));
  }, []);

  // H A N D L I N G - H O S T - A V A I L A B I L I T Y - S T A R T S - H E R E
  // const availableDaysString =
  //   "2024-08-07,2024-08-06,2024-08-08,2024-08-09,2024-08-10,2024-08-11,2024-08-18,2024-08-17,2024-08-14,2024-08-13,2024-09-17,2024-09-14,2024-09-13";
  const availableDaysString = host?.available_days ? host?.available_days : ",";
  const datesToHighlight = availableDaysString
    .split(",")
    .map((date) => dayjs(date));
  const HighlightedDay = styled(PickersDay)(({ theme, highlighted }) => ({
    ...(highlighted && {
      backgroundColor: "green",
      color: "white",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: "green",
      },
    }),
  }));
  function ServerDay(props) {
    const { day, outsideCurrentMonth, ...other } = props;
    const isHighlighted =
      !outsideCurrentMonth &&
      datesToHighlight.some((date) => date.isSame(day, "day"));
    const isDisabled = !isHighlighted;
    return (
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        highlighted={isHighlighted}
        disabled={isDisabled}
      />
    );
  }
  // H A N D L I N G - H O S T - A V A I L A B I L I T Y - E N D S - H E R E

  // Only available dates in datepicker functionality
  const availableDays = hostById?.available_days;
  const availableDates = availableDays
    ?.split(",")
    .map((date) => moment(date, "YYYY-MM-DD"));

  const disabledDates = (current) => {
    // Get today's date at the start of the day
    const today = moment().startOf("day");

    // Ensure availableDates is defined and has valid dates
    const isAvailable =
      availableDates &&
      availableDates.length > 0 &&
      availableDates.some((date) => current.isSame(date, "day"));

    // Disable if the date is not available or is before today
    return current && (!isAvailable || current.isBefore(today));

    const currentYear = moment().year();
    return current && current.year() !== currentYear;
  };

  const disabledEndDate = (current) => {
    // Disable all past dates
    const today = moment().startOf("day");

  
    if (baseDate) {
      return current && (current < today || current < baseDate.startOf("day"));
    }

 
    return current && current < today;
  };

  const handleStartDateWrapperClick = () => {
    if (startDateRef.current) {
   
      startDateRef.current.focus();
    }
  };

  const handleEndDateWrapperClick = () => {
    if (endDateRef.current) {
   
      endDateRef.current.focus();
    }
  };

  if (isLoading) {
    <Loading />;
  }

  return (
    <>
      <Header />
      <section className="host-profile-sections-wrapper">
        <section className="first-section-cover-pic-wrapper">
          <div className="host-cover-pic-container flex-center">
            
            <Image
         
              src={`${ApiBaseUrl}${host?.cover_pic}`}
              preview={{
                toolbarRender: () => null, 
              }}
            />
          </div>
          <div className="host-profile-pic-container flex-center">
           
            <Image
           
              src={`${ApiBaseUrl}${host?.profile_pic}`}
              preview={{
                toolbarRender: () => null, 
              }}
            />
          </div>
        </section>
        <section className="second-section-content-sections-wrapper flex-center">
          <div className="host-details-wrapper">
            <div className="host-name-location-container">
              <h1 className="host-name">{host?.name}</h1>
              <p className="host-id">{host?.role}</p>
              <p className="host-location">Lives in {host?.city}</p>
            </div>
            <div className="info-review-photo-count-container flex-center">
              <div className="info-container flex-center">
                <h1 className="info-heading">Total Bookings</h1>
                <p className="info-count">0</p>
              </div>
              <div className="review-container flex-center">
                <h1 className="info-heading">Reviews</h1>
                <p className="info-count">{host?.average_rating}</p>
              </div>
              <div className="photo-container flex-center">
                <h1 className="info-heading">Photos</h1>
                <p className="info-count">12</p>
              </div>
            </div>
            <div className="about-host-container">
              <h1 className="about-heading">About</h1>
              <p className="about-text">{host?.about}</p>
              <p className="host-gender">
                <span>Gender: </span> {host?.gender}
              </p>
              <p className="host-age">
                <span>Age: </span>
                {calculateAge(host?.dob)}
              </p>
              <p className="host-profession">
                <span>Profession: </span>
                {host?.profession}
              </p>
            </div>
            <div className="host-photos-wrapper">
              <h1 className="host-post-pics-heading">Staying Area</h1>
              <div className="host-photos-container flex-center">
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_stay_1}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_stay_2}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_stay_3}`}
                    preview={{
                      toolbarRender: () => null,
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_stay_4}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="host-photos-wrapper">
              <h1 className="host-post-pics-heading">Walking Area</h1>
              <div className="host-photos-container flex-center">
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_walk_1}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_walk_2}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_walk_3}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_walk_4}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
              </div>
           
            </div>
            <div className="host-photos-wrapper">
              <h1 className="host-post-pics-heading">Sleeping Area</h1>
              <div className="host-photos-container flex-center">
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_sleep_1}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_sleep_2}`}
                    preview={{
                      toolbarRender: () => null,
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_sleep_3}`}
                    preview={{
                      toolbarRender: () => null,
                    }}
                  />
                </div>
                <div className="host-pic">
                  <Image
                    // width={200}
                    src={`${ApiBaseUrl}${host?.where_pet_will_sleep_4}`}
                    preview={{
                      toolbarRender: () => null, 
                    }}
                  />
                </div>
              </div>
           
            </div>
          </div>
          <div className="host-sevice-details-wrapper">
         
        
            <div className="services-and-rates">
              <div className="services-heading">
                <h1>Services & Rates</h1>
              </div>
              <ul className="services-sub-heading">
                <li>
                  Can host upto {host?.host_pet_count}{" "}
                  {host?.host_pet_count > 1 ? "pets." : "pet."}
                </li>
                <li>
                  Preferred type of pet:{" "}
                  <span style={{ fontWeight: "lighter" }}>
                    {host?.pet_type}
                  </span>{" "}
                </li>
              </ul>

              <div className="housing-details">
                <h1>Housing Details</h1>
                <div className="housing-item">
                  <img
                    src="/assets/images/host-services/home-type.png"
                    alt=""
                  />
                  <ul>
                    <li>Home Type</li>
                    <li>{host?.house}</li>
                  </ul>
                </div>

                <div className="housing-item">
                  <img src="/assets/images/host-services/space.png" alt="" />
                  <ul>
                    <li>Space</li>
                    <li>Area -{host?.house_area}sq ft</li>
                  </ul>
                </div>
                <div className="housing-item">
                  <img
                    src="/assets/images/host-services/furniture-allow.png"
                    alt=""
                  />
                  <ul>
                    <li>Are pets allowed on furniture?</li>
                    <li>{host?.furniture_allow === 1 ? "Yes" : "No"}</li>
                  </ul>
                </div>
                <div className="housing-item">
                  <img
                    src="/assets/images/host-services/bed-allow.png"
                    alt=""
                  />
                  <ul>
                    <li>Are pets allowed on the bed?</li>
                    <li>{host?.Bed_allow === 1 ? "Yes" : "No"}</li>
                  </ul>
                </div>
              </div>

              <div className="services">
                <h1>Facilities Offered</h1>
                <ul>
                  {host?.servicename &&
                    host?.servicename
                      .split(",")
                      .map((service) => <li>{service}</li>)}
                  {/* <li>
                    <img
                      src="/assets/images/host-services/pick-drop.png"
                      alt=""
                    />
                    Pick and Drop
                  </li>
                   <li>
                    <img
                      src="/assets/images/host-services/non-spayed.png"
                      alt=""
                    />
                    Accept non spayed pets
                  </li>
                  <li>
                    <img
                      src="/assets/images/host-services/untrained.png"
                      alt=""
                    />
                    Accept untrained pets
                  </li>
                  <li>
                    <img
                      src="/assets/images/host-services/accept-puppies.png"
                      alt=""
                    />
                    Accept puppies
                  </li>
                  <li>
                    <img
                      src="/assets/images/host-services/bathing-grooming.png"
                      alt=""
                    />
                    Bathing/Grooming
                  </li> */}
                </ul>
              </div>

              <div className="pets-at-home">
                <h1>Pets at Home</h1>
                <ul>
                  <li>
                    <img
                      src="/assets/images/host-services/not-own-dog.png"
                      alt=""
                    />
                    {host?.pet_at_home === 1
                      ? "Yes, I have a dog/cat!"
                      : "No, I don't have a dog/cat!"}
                  </li>
                  {/* <li>
                    <img src="/image/Does not own a cat.png" alt="" />
                    Does not own a cat
                  </li> */}
                  {/* <li>
                    <img src="/image/Accepts only one client at a time.png" alt="" />
                    Accepts only one client at a time
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </section>

  
      <Footer />
    </>
  );
};

export default ProfileHost;
