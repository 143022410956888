import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function HostDedicatedSupport() {
  return (
    <>
      <Header/>
      <div  style={{padding: "140px 110px"}}>
  <h2>Host Dedicated Support with Woofily</h2>
  <p>Providing the care, support, and guidance you need to succeed as a pet host. Woofily is here for you, every step of the way!</p>
  <h3>Why Choose Woofily’s Dedicated Host Support?</h3>
  <p>At Woofily, we know that caring for pets isn’t just a job—it’s a passion. And we’re here to make sure you have everything you need to succeed. Whether you’re a new host or a seasoned pet care pro, our dedicated support team is here to help you with everything from setting up your profile to managing bookings and ensuring every pet’s stay is a happy one!</p>
  <p>We’ve got your back with personalized, 24/7 support, expert resources, and a friendly community that’s always here to lend a helping paw.</p>
  <h3>What Does Host Dedicated Support Include?</h3>
  <ol>
    <li>
      <strong>24/7 Help Whenever You Need It​</strong>
      <p>Need help at 2 a.m. with a booking issue? No problem! Our support team is available 24/7 to assist you with any questions, concerns, or technical difficulties you may encounter. We’re just a click or call away, ensuring you always have someone to turn to.</p>
    </li>
    <li>
      <strong>Personalized Guidance for Your Hosting Journey​</strong>
      <p>New to hosting? No worries! Our team will guide you through every step of the way, from creating your profile to completing your first booking. We’ll share tips on how to set up your hosting environment, build strong relationships with pet parents, and provide exceptional care for pets.</p>
    </li>
    <li>
      <strong>Fast and Friendly Solutions​</strong>
      <p>We understand that hosting pets can sometimes come with unexpected challenges. Whether it’s a last-minute booking, a special request from a pet parent, or a question about Woofily policies, we’ll provide fast, friendly solutions to ensure your hosting experience goes smoothly.</p>
    </li>
    <li>
      <strong>Hosting Best Practices and Resources​</strong>
      <p>Stay informed and up-to-date with our collection of expert resources. We provide articles, videos, and guides on everything from pet care tips to hosting best practices.</p>
      <p>Want to learn how to manage multiple pets or handle special-needs animals? We’ve got you covered with helpful tips to elevate your hosting game.</p>
    </li>
    <li>
      <strong>Conflict Resolution​</strong>
      <p>Hosting pets should be a joyful experience, but sometimes things don’t go as planned. Our team is trained to help mediate and resolve any issues between hosts and pet parents, ensuring both sides are satisfied and that pets always receive the best care possible.</p>
    </li>
    <li>
      <strong>Profile Optimization Tips​</strong>
      <p>Want to attract more bookings? We’ll help you optimize your host profile to stand out in search results. From improving your profile description to adding eye-catching photos, our support team will provide personalized tips to help you shine!</p>
    </li>
  </ol>
  <h3>How to Access Dedicated Support</h3>
  <p>Accessing Woofily’s dedicated host support is simple! Whether you need help with a specific issue or just want advice on how to improve your hosting services, here’s how you can reach us:</p>
  <ul>
    <li><strong>Live Chat:</strong> Get real-time assistance with any questions or concerns via our 24/7 live chat feature.</li>
    <li><strong>Email Support:</strong> Prefer email? No problem. Send us your questions, and our team will get back to you promptly.</li>
    <li><strong>Phone Support:</strong> For more urgent matters, call our dedicated support line to speak with a Woofily team member directly.</li>
    <li><strong>Help Center:</strong> Browse through our extensive Help Center for answers to common questions, tips, and resources on how to be the best Woofily host you can be.</li>
  </ul>
  <h3>Testimonials from Our Happy Hosts</h3>
  <blockquote>
    <p>"The Woofily support team has been amazing! Whenever I’ve had a question or needed help with a booking, they were there for me every time. I feel confident knowing I have their support." – Rachel, Woofily Host</p>
  </blockquote>
  <blockquote>
    <p>"As a first-time pet host, I was nervous about getting started, but Woofily’s dedicated support made the whole process so easy. They helped me set up my profile and gave me great advice on how to care for the pets I host." – James, Woofily Host</p>
  </blockquote>
  <h3>Join Woofily’s Community of Supported Hosts</h3>
  <p>With Woofily’s dedicated host support, you’re never alone. Whether you’re looking for expert advice or need quick assistance, we’re always here to ensure your hosting experience is smooth and successful. Pet parents rely on you, and you can rely on us!</p>
  <p><strong>Ready to take your hosting to the next level?​</strong></p>
  <p>Join Woofily today and experience the difference with our host-dedicated support. Your success is our priority!</p>
</div>

      <Footer/>
    </>
  )
}

export default HostDedicatedSupport
