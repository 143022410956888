import React, { useEffect } from "react";
import './allHosts.scss'
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchMostPopularHosts } from "../../../../redux/slices/home/HomeHostsSlice";
import HostCard from "../../components/home-host-card/HostCard";

const AllStarHosts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMostPopularHosts());
  }, [dispatch]);
  const { isLoading, popularHosts, error } = useSelector(
    (state) => state.homeHosts
  );
  return (
    <>
      <Header />
      <section className="all-hosts-wrapper">
        <div className="all-hosts-heading">Star Hosts</div>
        <div className="all-hosts">
          {popularHosts &&
            popularHosts.map((host) => (
              <HostCard
                key={host.client_id}
                id={host.client_id}
                name={host.name}
                role={host.role}
                profilePic={host.profile_pic}
                city={host.city}
                pricePerDay={host.amount_day}
                pricePerHour={host.amount_hr}
                rating={host.average_rating}
                distance={host.distance}
              />
            ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AllStarHosts;
